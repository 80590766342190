import React from 'react';

const spinner = (props) => {
    return (
        <div className={`spinner-grow ${props.className}`} role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )
};

export default spinner;
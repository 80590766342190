import axios from 'axios';
import Store from '../reducers';
const expired = 'TokenExpiredError';

const CancelToken = axios.CancelToken;
export let cancel;

export const getDashboard = async () => {
    const url = `${process.env.REACT_APP_API_URL}/admin/dashboard`;

    try {
        let request = await axios.get(url,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
    
        return request.data;
    } catch (err) {
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            return err.response.data;
        }
        return err;
    }
}
import axios from 'axios';
import Store from '../reducers';
const expired = 'TokenExpiredError';

const CancelToken = axios.CancelToken;
export let cancelUnread;
export let cancelMessages;

export const getUnread = async () => {
    const url = `${process.env.REACT_APP_API_URL}/admin/messages/unread`;

    try {
        let request = await axios.get(url,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancelUnread = c;
                })
            }
        );
    
        return request.data;
    } catch (err) {
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            throw err.response.data;
        }
        throw err;
    }
};

export const getDisputeMessages = async (dispute) => {
    const url = `${process.env.REACT_APP_API_URL}/admin/messages/get/${dispute}`;

    try {
        let request = await axios.get(url,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancelMessages = c;
                })
            }
        );
    
        return request.data;
    } catch (err) {
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            throw err.response.data;
        }
        throw err;
    }
};

export const getUnreadDisputes = async () => {
    const url = `${process.env.REACT_APP_API_URL}/admin/messages/getUnread`;

    try {
        const request = await axios.get(url,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancelUnread = c;
                })
            },
        );
    
        return request.data;
    } catch (err) {
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            throw err.response.data;
        }
        throw err;
    }
};

export const sendMessage = async (dispute, message) => {
    const url = `${process.env.REACT_APP_API_URL}/admin/messages/send/${dispute}`;

    try {
        let request = await axios.post(url,
            message,
        );
    
        return request.data;
    } catch (err) {
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            throw err.response.data;
        }
        throw err;
    }
};

export const markAsUnread = async (dispute) => {
    const url = `${process.env.REACT_APP_API_URL}/admin/messages/markAsUnread`;

    try {
        let request = await axios.post(url,
            {
                dispute,
            },
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancelMessages = c;
                })
            }
        );
    
        return request.data;
    } catch (err) {
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            throw err.response.data;
        }
        throw err;
    }
};
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ReactTable from 'react-table';
import "react-table/react-table.css";
import swal from 'sweetalert';
import { getAdminList, changePassword, cancel } from '../services/admin';

const Personnel = (props) => {
    const [loading, setLoading] = useState(false);
    const [personnel, setPersonnel] = useState([]);
    const [pages, setPages] = useState(1);
    const [show, setShow] = useState(10);
    const [columns, setColumns] = useState([]);
    const table = useRef();

    const ChangePassword = async (id, email) => {
        swal(`Por favor, agrega una contraseña para el administrador ${email}:`, {
            content: "input",
          }).then(async (password) => {
            if (!password || password.length < 4) {
                return swal('Please, add a valid password');
            }

            try {
                await changePassword(id, password);
                swal({   
                    title: "Credenciales actualizadas",   
                    text: "Por favor, compartí las nuevas credenciales con el usuario.",   
                    icon: "success",
                }).then(value => props.history.push('/personnel'));
            } catch (err) {
                swal({   
                    title: "Hubo un problema.",   
                    text: "Por favor, intentelo nuevamente más tarde.",   
                    icon: "error",
                    confirmButtonColor: "#DD6B55",
                    className: 'logout-alert'
                });
            }
          });
    };

    useEffect(() => { 
        function generateColumns() {
            let columns = [{
                Header: 'Nombre',
                accessor: 'name',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Rol',
                accessor: 'role',
            },
            {
                id: 'branch',
                Header: 'Sucursal',
                accessor: (d) => d.branch.map(b => <span className="badge badge-primary">{b}</span>),
                filterable: false,
            },
            {
                id: 'active',
                Header: 'Activo',
                accessor: (d) => d.active ? <i className="fas fa-check text-success" /> : <i className="fas fa-x text-danger" />,
                filterable: false,
            },
            {
                Header: '',
                accessor: '_id',
                filterable: false,
                className: 'actions-cell',
                Cell: property => (
                    <div className="dropdown">
                        <button className="btn btn-xs btn-circle btn-default" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="ico fas fa-ellipsis-v text-dark" />
                        </button>
                        <div className="dropdown-menu sub-menu" aria-labelledby="dropdownMenuButton">
                            <NavLink to={"/personnel/edit/" + property.value } className="dropdown-item">
                                Editar
                            </NavLink>
                            <button className="dropdown-item" onClick={() => ChangePassword(property.original._id, property.original.email)}>
                                Modificar Contraseña
                            </button>
                        </div>
                    </div>
                ),
                width: 50
            }
            ];
        
            setColumns(columns);
        };
        generateColumns();

        return function cleanup() {
            cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = async (state, instance) => {
        setLoading(true);
        
        let options = {
            sort: state.sorted.length > 0 ? state.sorted[0].id : 'createdAt',
            direction: state.sorted.length > 0 ? state.sorted[0].desc ? 'desc' : 'asc' : 'desc',
            filters: state.filtered.length > 0 ? state.filtered : [],
            results: state.pageSize,
            offset: (state.page +1) * state.pageSize - state.pageSize
        };

        try {
            let data = await getAdminList(options);
            if (data.admins.length > -1) {
                setPersonnel(data.admins);
                setLoading(false);
                setPages(data.pages);
                setShow(data.results);
            } else {
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
        }
    };
    
    return (
        <section>
            <div className='row'>
                <div className='col-md-6 col-xs-12 align-self-center'>
                    <h3>Personal</h3>
                </div>
                <div className='col-md-6 col-xs-12 text-right'>
                    <NavLink to='/personnel/new' className="btn btn-icon btn-icon-left btn-success">
                        <i className="ico fas fa-plus" /> Agregar Administrador
                    </NavLink>
                </div>
            </div>
            <hr />
            <ReactTable
                ref={table}
                data={personnel}
                columns={columns}
                pages={pages}
                defaultPageSize={show}
                loading={loading}
                showPagination={true}
                showPaginationTop={false}
                showPaginationBottom={true}
                showPageJump={true}
                resizable={true}
                sortable={true}
                filterable={true}
                manual
                onFetchData={fetchData}
                onSortedChange={cancel}
                onFilteredChange={cancel}
                className="-striped -highlight"
            />
        </section>
    );
};

const mapStateToProps = (state) => {
    return ({user: state.auth.user, timeZone: state.auth.user.timezone, locale: state.auth.user.locale});
};

export default connect(mapStateToProps)(Personnel);

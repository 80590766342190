/* DEPENDENCIES */
import React, { useEffect, useState } from 'react';
import { 
  BrowserRouter as Router,
  Route,
  Redirect
} from 'react-router-dom';
import { Provider } from 'react-redux'
/* COMPONENTS */
import AppContainer from './components/AppContainer';
import Loader from './components/assets/Loader';
/* SCREENS */
import Login from './components/Login';
import Branches from './components/Branches';
import BranchesEdit from './components/Branches.edit';
import Dashboard from './components/Dashboard';
import Addons from './components/Addons';
import AddonsEdit from './components/Addons.edit';
import Contents from './components/Contents';
import ContentsEdit from './components/Contents.edit';
import Departures from './components/Departures';
import DepartureDetail from './components/DepartureDetail';
import Disputes from './components/Disputes';
import DisputeDetail from './components/DisputeDetail';
import Fleet from './components/Fleet';
import FleetEdit from './components/Fleet.edit';
import FleetLog from './components/Fleet.log';
import MessagesUnread from './components/MessagesUnread';
import Notifications from './components/Notifications';
import NotificationsEdit from './components/Notifications.edit';
import Personnel from './components/Personnel';
import PersonnelEdit from './components/Personnel.edit';
import Profile from './components/Profile';
import Users from './components/Users';
/* STORE */
import Store from './reducers';

const App = () => {
  const [auth, setAuth] = useState(Store.getState().auth.isAuthenticated);
  const [redirect] = useState(window.location.pathname + window.location.search);

  useEffect(() => {
    const login = Store.subscribe(() => {
      setAuth(Store.getState().auth.isAuthenticated);
    });

    return function cleanup() {
      login();
    }
  }, []);

  return (
    <Provider store={Store}>
      <Loader />
        <Router>
          { 
            auth
            ?
            (
              <AppContainer>
                <Route path='/' exact component={Dashboard} />
                <Route path='/addons' exact component={Addons} />
                <Route path='/addons/new' exact component={AddonsEdit} />
                <Route path='/addons/edit/:id' exact component={AddonsEdit} />
                <Route path='/branches' exact component={Branches} />
                <Route path='/branches/:id' exact component={BranchesEdit} />
                <Route path='/contents' exact component={Contents} />
                <Route path='/contents/new' exact component={ContentsEdit} />
                <Route path='/contents/edit/:id' exact component={ContentsEdit} />
                <Route path='/departures' exact component={Departures} />
                <Route path='/departures/:id' exact component={DepartureDetail} />
                <Route path='/tickets' exact component={Disputes} />
                <Route path='/tickets/:id' exact component={DisputeDetail} />
                <Route path='/fleet' exact component={Fleet} />
                <Route path='/fleet/new' exact component={FleetEdit} />
                <Route path='/fleet/edit/:id' exact component={FleetEdit} />
                <Route path='/fleet/log/:id' exact component={FleetLog} />
                <Route path='/messages' exact component={MessagesUnread} />
                <Route path='/notifications' exact component={Notifications} />
                <Route path='/notifications/new' exact component={NotificationsEdit} />
                <Route path='/personnel' exact component={Personnel} />
                <Route path='/personnel/new' exact component={PersonnelEdit} />
                <Route path='/personnel/edit/:id' exact component={PersonnelEdit} />
                <Route path='/profile' component={Profile} />
                <Route path='/users' exact component={Users} />
              </AppContainer>
            )
            :
            <Redirect to={`/login${(redirect && redirect.indexOf('login') === -1 && redirect !== '/') ? `?path=${redirect}` : ''}`} />
          }
        <Route path='/login' component={Login} />
      </Router>
    </Provider>
  );
};

export default App;

import axios from 'axios';
import Store from '../reducers';
const expired = 'TokenExpiredError';

const CancelToken = axios.CancelToken;
export let cancel;

export const getBranchByName = async (name) => {

    const url = `${process.env.REACT_APP_API_URL}/admin/branches/getByName?name=${name}`;

    try {
        let request = await axios.get(url,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
    
        return request.data;
    } catch (err) {
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            throw err.response.data;
        }
        throw err;
    }
};

export const getBranchById = async (id) => {

    const url = `${process.env.REACT_APP_API_URL}/admin/branches/get/${id}`;

    try {
        let request = await axios.get(url,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
    
        return request.data;
    } catch (err) {
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            throw err.response.data;
        }
        throw err;
    }
};

export const updateBranch = async (id, data) => {
    Store.dispatch({ type: 'LOADING' });
    const url = `${process.env.REACT_APP_API_URL}/admin/branches/update/${id}`;

    try {
        let request = await axios.post(url, data);

        Store.dispatch({ type: 'LOADED' });
        return request.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            throw err.response.data;
        }
        throw err;
    }
};
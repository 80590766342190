import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import swal from 'sweetalert';
import Select from 'react-select/creatable';
import { getBranchById, updateBranch, cancel } from '../services/branches';

const BranchEdit = (props) => {
    const [data, setData] = useState({
        name: '',
        oilPrice: 0,
        fuelSurcharge: 0,
        phoneNumber: '',
        whatsappNumber: '',
        reservationUrl: '',
        emergencyPhone: [],
    });
    const [error, setError] = useState('');
    const { id } = props.match.params;

    useEffect(() => {
        if (!id) {
            props.history.push('/branches')
        } else {
            getData();
        }

        return () => {
            if (id) {
                cancel();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const getData = async () => {
        props.show();
        try {
            const branch = await getBranchById(id);
            setData(branch);
            props.hide();
        } catch (err) {
            setError('Por favor, intentelo nuevamente más tarde.');
            swal({   
                title: "Hubo un problema.",   
                text: "Por favor, intentelo nuevamente más tarde.",   
                icon: "error",
                confirmButtonColor: "#DD6B55",
                className: 'logout-alert'
            });
            props.hide();
        }
    };

    const submit = async () => {
        UpdateAddon();
    };

    const onPhoneChange = (value, { action, removedValue }) => {
        let values = data.emergencyPhone || [];
        switch (action) {
            case 'create-option':
                for (let i = 0; i < value.length; i++) {
                    if (value[i].__isNew__) {
                        values.push(value[i].value);
                    }
                }
                break;
            case 'select-option': 
                values = value;
            break; 
            case 'remove-value':
                values = values.filter(val => removedValue.value !== val);
                break;
            case 'pop-value':
                break;
            case 'clear':
                values = [];
                break;
            default:
                break;
        }
        setData(v => ({ ...v, emergencyPhone: values }));
	};

    const discard = () => {
        swal({   
            title: "Descartar cambios?",   
            text: "Esta acción es irreversible.",   
            icon: "warning",  
            buttons: ['Cancel', 'Descartar'], 
            confirmButtonColor: "#DD6B55",
            className: 'logout-alert'
        }).then(
            async (value) => {
                if (value) {
                    props.history.push('/branches')
                }
            }
        );
    };

    const UpdateAddon = async () => {
        try {
            const addon = await updateBranch(id, data);
            console.log('updated', addon);
            swal({   
                title: "Cambios guardados",   
                text: "Los cambios se guardaron correctamente.",   
                icon: "success",
            }).then(value => props.history.push('/branches'));
        } catch (err) {
            swal({   
                title: "Hubo un problema.",   
                text: "Por favor, intentelo nuevamente más tarde.",   
                icon: "error",
                confirmButtonColor: "#DD6B55",
                className: 'logout-alert'
            });
        }
    };

    return (
        <section className="row small-spacing">
            <div className="col-lg-12 col-xs-12">
                <div className="box-content card white">
                    <h4 className="box-title">Sucursal</h4>
                    <div className="card-content">
                            <div className="row">
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="name">Nombre *</label>
                                        <input type="text" className="form-control" id="name" placeholder="Name" value={data.name} readOnly />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="reservation">URL Sistema de Reservas *</label>
                                        <input type="text" className="form-control" id="reservation" placeholder="http://your-url.com" value={data.reservationUrl}
                                            onChange={(event) => {
                                                const v = event.target.value;
                                                setData(value => ({...value, reservationUrl: v }))
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="oil">Aceite - Precio por litro *</label>
                                        <input type="number" className="form-control" id="oil" placeholder="Cost per litre ($)" value={data.oilPrice}
                                            onChange={(event) => {
                                                const v = event.target.value;
                                                setData(value => ({...value, oilPrice: parseInt(v, 10) }))
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="fuel">Recarga de Combustible (%) *</label>
                                        <input type="number" className="form-control" id="fuel" placeholder="Factor (Ej. 0.2)" value={data.fuelSurcharge}
                                            onChange={(event) => {
                                                const v = event.target.value;
                                                setData(value => ({...value, fuelSurcharge: parseFloat(v, 10) }))
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="emergencyPhone">Teléfonos de Emergencia *</label>
                                        <Select
                                            value={data.emergencyPhone ? data.emergencyPhone.map(a => ({ label: a, value: a, key: a})) : null}
                                            isMulti
                                            name="emergencyPhone"
                                            onChange={onPhoneChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="phone">WhatsApp *</label>
                                        <input type="text" className="form-control" id="phone" placeholder="Phone Number" value={data.whatsappNumber}
                                            onChange={(event) => {
                                                const v = event.target.value;
                                                setData(value => ({...value, whatsappNumber: v }))
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-xs-12 margin-top-20">
                                    <p className="danger">{error}</p>
                                </div>
                                <div className="col-lg-6 col-xs-12 text-right margin-top-20">
                                    <button type="button" className="btn btn-secondary btn-md" style={ {marginRight: 20} } onClick={discard}>Descartar</button>
                                    <button onClick={submit} className="btn btn-primary btn-md">Guardar Cambios</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    )
};

const mapStateToProps = (state) => {
    return ({timeZone: state.auth.user.timezone, locale: state.auth.user.locale, user: state.auth.user});
};

const mapDispatchToProps = (dispatch) => ({
    show: () => dispatch({ type: 'LOADING' }),
    hide: () => dispatch({ type: 'LOADED' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(BranchEdit);
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ReactTable from 'react-table';
import "react-table/react-table.css";
import swal from 'sweetalert';
import { getNotifications, cancelNotification, cancel } from '../services/notifications';

const Notifications = (props) => {
    const [notifications, setNotifications] = useState([]);
    const [tableConf, setTableConf] = useState({page: 1, pages: 1, show: 20, loading: true});
    // const [modalIsOpen, setModalIsOpen] = useState(false);

    const columns = [
        {
            id: 'name',
            Header: <span>Nombre <i className="fas fa-sort" /></span>,
            accessor: (d) => <span dangerouslySetInnerHTML={{ __html: d.name }}></span>,
            minWidth: 140,
            filterable: true
        },
        {
            id: 'title',
            Header: <span>Tītulo <i className="fas fa-sort" /></span>,
            accessor: (d) => <span dangerouslySetInnerHTML={{ __html: d.title }}></span>,
            minWidth: 140,
            filterable: true
        },
        {
            id: 'message',
            Header: <span>Mensaje <i className="fas fa-sort" /></span>,
            accessor: (d) => <span dangerouslySetInnerHTML={{ __html: d.message }}></span>,
            minWidth: 200,
            filterable: true
        },
        {
            id: 'send-date',
            Header: <span>Fecha de Envío <i className="fas fa-sort" /></span>,
            accessor: (d) => new Date(d.start_date).toLocaleString(props.locale, { timeZone: props.timeZone })
        },
        {
            id: 'OS',
            Header: "OS",
            accessor: (d) => <span className="text-center">{ d.sendToIos ? <i className='fab fa-android android-color' /> : null } { d.sendToIos ? <i className='fab fa-apple apple-color' /> : null }</span>,
            width: 60,
            sortable: false
        },
        /*{
            id: 'converted_percentage',
            Header: <span>Opened <i className="fas fa-sort" /></span>,
            accessor: (d) => <span>{parseFloat(Math.round(d.converted_percentage * 100) / 100).toFixed(2)}%</span>,
            width: 80,
            sortable: true
        },*/
        {
            id: 'status',
            Header: 'Estado',
            accessor: (a) => a.status === 'Sent' ? 'Enviada' : a.status === 'Canceled' ? 'Cancelada' : 'Pendiente',
            sortable: false
        },
        {
            id: 'actions',
            Header: 'Actions',
            accessor: d => ((!d.canceled && !d.cancelling && !d.creating) && d.status !== 'Sent') ? <button id={d._id} onClick={_cancel} className="btn-sm btn-icon btn-icon-left btn-danger no-border"><i className="ico fas fa-ban" /> Cancelar</button> : null,
            resizable: false,
            width: 90,
            sortable: false
        }
    ];

    const fetchData = async (state, instance) => {
        let options = {};
    
        if (state) {
            options = {
                sort: state.sorted.length > 0 ? state.sorted[0].id : 'start_date',
                direction: state.sorted.length > 0 ? state.sorted[0].desc ? 'desc' : 'asc' : 'desc',
                filters: state.filtered.length > 0 ? state.filtered : [],
                results: state.pageSize,
                offset: (state.page +1) * state.pageSize - state.pageSize
            };
        } else {
            options = {
                sort: 'start_date',
                direction: 'desc',
                filters: [],
                results: 10,
                offset: 0
            }
        }
        
    
        try {
            const data = await getNotifications(options);

            setNotifications(data.notifications);
            setTableConf(value => ({
                ...value,
                loading: false,
                pages: data.pages,
                show: data.results,
                lastUpdate: data.last_update,
            }));
        } catch (e) {
    
        }
    };

    const _cancel = async (event) => {
        event.preventDefault();
        const {id} = event.target;

        try {
            await cancelNotification(id);

            swal({   
                title: "Success",   
                text: "The notification has been cancelled.",   
                icon: "success"
            }).then(
                (value) => {
                    fetchData();
                }
            );
        } catch (err) {
            swal({   
                title: "Ups, we can't cancel your notification.",   
                text: "There is a problem with your request. Please try again later or contact us.",   
                icon: "error",
                buttons: { confirm: { value: 'OK', className: 'btn btn-danger' } },
                className: 'logout-alert'
            });
        }
       
    };
    
    useEffect(() => {
        window.scrollTo(0, 0);
        return function cleanup() {
            cancel();
        }
    }, []);

    return (
        <section>
            <div className='row'>
                <div className='col-md-6 col-xs-12 align-self-center'>
                    <h3>Notificaciones</h3>
                </div>
                <div className='col-md-6 col-xs-12 text-right'>
                    <div className='row justify-content-md-end'>
                        <div className='col-md-6 col-xs-12'>
                            <NavLink to='/notifications/new' className="btn btn-icon btn-icon-left btn-success"><i className="ico fas fa-plus" />Crear Notificación</NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <ReactTable
                data={notifications}
                columns={columns}
                pages={tableConf.pages}
                defaultPageSize={tableConf.show}
                loading={tableConf.loading}
                showPagination={true}
                showPaginationTop={false}
                showPaginationBottom={true}
                showPageJump={true}
                resizable={true}
                sortable={true}
                manual
                onFetchData={fetchData}
                onSortedChange={cancel}
                onFilteredChange={cancel}
                className="-striped -highlight"
            />
            {/*tableConf.lastUpdate ? <small>Last Update: {new Date(tableConf.lastUpdate).toLocaleString(this.props.locale, { timeZone: this.props.timeZone })}</small> : null*/}
        </section>
    );
};

const mapStateToProps = (state) => ({timeZone: state.auth.user.timezone, locale: state.auth.user.locale});

export default connect(mapStateToProps)(Notifications);

/* eslint-disable react/jsx-no-target-blank */

import React, { useState, useEffect } from 'react';
import {connect} from 'react-redux';
import Gallery from 'react-grid-gallery';
import Modal from 'react-modal';
import swal from 'sweetalert';
import { getFleetById, getLogsById, updateLogsById, cancel } from '../services/fleet';
import { dotSeparateNumber } from '../utils/tools';
import BackgroundAnimation from './assets/background-animation';

const FleetLog = props => {
    const { id } = props.match.params;
    const [boat, setBoat] = useState(false);
    const [logs, setLogs] = useState({});
    const [modal, setModal] = useState(false);
    const [hours, setHours] = useState(0);

    useEffect(() => {
        getData();

        return () => {
            cancel();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const getData = async () => {
        try {
            const data = await getFleetById(id);
            console.log(data);
            setBoat(data);

            const l = await getLogsById(id);
            console.log('logs', l);
            setLogs(l);
        } catch (err) {
            swal({   
                title: "Hubo un problema.",   
                text: "Por favor, intentelo nuevamente más tarde.",   
                icon: "error",
                confirmButtonColor: "#DD6B55",
                className: 'logout-alert'
            });
        }
    };

    const saveMotorHours = async () => {
        try {
            await updateLogsById(id, hours);
            setModal(false);
            setTimeout(() => getData(), 500);
        } catch (err) {
            swal({   
                title: "Hubo un problema.",   
                text: "Por favor, intentelo nuevamente más tarde.",   
                icon: "error",
                confirmButtonColor: "#DD6B55",
                className: 'logout-alert'
            });
        }
    };

    return (
        boat
        ? (
        <section>
            <div className='row'>
                <div className='col-md-6 col-xs-12 align-self-center'>
                    <h3>Bitácora</h3>
                </div>
            </div>
            <hr />
            <div className="row small-spacing">
                <div className="col-lg-4 col-md-12 col-xs-12">
                    <div className="box-content bordered primary margin-bottom-20">
                        <div className="profile-avatar">
                            {
                                boat.image
                                ?
                                <img src={boat.image} alt={boat.name} />
                                :
                                <div className="user-pic-placeholder bg-danger d-flex">
                                    <i className="fas fa-ship justify-content-center align-self-center" />
                                    <BackgroundAnimation />
                                </div>
                            }
                            <h3 className="mt-3"><strong className="capitalize">{boat.name}</strong></h3>
                            <h4 className="mb-3">{boat.name}</h4>
                        </div>
                        <table className="table table-hover no-margin">
                            <tbody>
                                <tr>
                                    <td>Estado</td>
                                    <td>
                                        {
                                            boat.active
                                            ?
                                            <span className="notice notice-blue">Activa</span>
                                            :
                                            <span className="notice notice-danger">Inactiva</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Última Salida</td>
                                    <td>{logs.lastDeparture ? new Date(logs.lastDeparture).toLocaleDateString(props.locale) : 'Nunca'}</td>
                                </tr>
                                <tr>
                                    <td>Sucursal</td>
                                    <td>{boat.branch}</td>
                                </tr>
                                <tr>
                                    <td>Motor fuera de borda</td>
                                    <td>
                                        {
                                            boat.outboardMotor
                                            ?
                                            <span className="notice notice-blue">Si</span>
                                            :
                                            <span className="notice notice-danger">No</span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Tipo de Motor</td>
                                    <td>{boat.stroke}T</td>
                                </tr>
                                <tr>
                                    <td>Horas de Motor</td>
                                    <td>{logs.hours || 0}</td>
                                </tr>
                                <tr>
                                    <td>Salidas totales</td>
                                    <td>{dotSeparateNumber(logs.departures || 0)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-lg-8 col-md-12 col-xs-12">
                    <div className="col-md-12 col-xs-12">
                        <div className="box-content card">
                            <h4 className="box-title"><i className="fas fa-image ico"></i> Último set de imágenes</h4>
                            <div className="card-content container">
                                {
                                    logs.imageSets && logs.imageSets[0]
                                    ?
                                    <Gallery
                                        images={logs.imageSets[0].images.map(img => (
                                            {
                                                src: img.url,
                                                caption: img.type,
                                                tags: [{value: img.type, title: img.type}],
                                                thumbnail: img.url,
                                                thumbnailWidth: 150,
                                                thumbnailHeight: 100,
                                            }
                                        ))}
                                        enableImageSelection={false}
                                    />
                                    :
                                    <ul className="profile-list">
                                        <li>
                                            <b>No disponible</b>
                                        </li>
                                    </ul>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 col-xs-12">
                    <div className="box-content">
                        <h4 className="box-title">Historial de Horas de Motor</h4>
                        <div className="dropdown js__drop_down">
                            <i className="dropdown-icon ico fas fa-plus-circle pointer" role="button" onClick={() => setModal(true)} />
                        </div>
                        <div className="table-responsive table-purchases">
                            <table className="table table-striped margin-bottom-10">
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Horas de Motor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        logs.logs && logs.logs.length > 0
                                        ?
                                        logs.logs.map((log) => (
                                            <tr key={log._id}>
                                                <td>{new Date(log.timestamp).toLocaleString(props.locale, {timeZone: props.timeZone})}</td>
                                                <td>{log.hours}</td>
                                            </tr>
                                        ))
                                        :
                                        <tr>
                                            <td>No hay datos.</td>
                                            <td>-</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-xs-12">
                    <div className="box-content">
                        <h4 className="box-title">Sets de Imágenes</h4>
                        <div className="table-responsive table-purchases">
                            <table className="table table-striped margin-bottom-10">
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Cantidad de Imágenes</th>
                                        <th>Fotos</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        logs.imageSets && logs.imageSets.length > 0
                                        ?
                                        logs.imageSets.map((img) => (
                                            <tr key={img._id}>
                                                <td>{new Date(img.createdAt).toLocaleString(props.locale, {timeZone: props.timeZone})}</td>
                                                <td>{img.images.length}</td>
                                                <td>{img.images.map((i, index) => (
                                                    <a key={index} href={i.url} target="_blank" className="notice notice-blue text-white mr-1">{i.type}</a>
                                                ))}</td>
                                            </tr>
                                        ))
                                        :
                                        <tr>
                                            <td>No hay datos.</td>
                                            <td>-</td>
                                            <td>-</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            { /* MODAL */ }
            <Modal
                isOpen={modal}
                onRequestClose={() => {
                    setHours(0);
                    return setModal(false)
                }}
                contentLabel="Addon Modal"
                style={{content : {
                    top                   : '50%',
                    left                  : '50%',
                    right                 : 'auto',
                    bottom                : 'auto',
                    marginRight           : '-50%',
                    padding               : '0',
                    transform             : 'translate(-50%, -50%)'
                  }}}
                >
                <div className="modal-header">
                    <h5 className="modal-title">Rectificar Horas de Motor</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setModal(false)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className='form-group'>
                        <label htmlFor="amount">Horas de Motor *</label>
                        <input type="number" className="form-control" id="amount" placeholder="$" value={hours} onChange={(event) => {
                            const { value } = event.target;
                            return setHours(value)
                        }} />
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={() => saveMotorHours()} disabled={hours <  0}>Rectificar Horas</button>
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setModal(false)}>Descartar</button>
                </div>
            </Modal>
        </section>
        ) : null
    );
};

const mapStateToProps = (state) => {
    return ({timeZone: state.auth.user.timezone, locale: state.auth.user.locale});
};

const mapDispatchToProps = (dispatch) => ({
    show: () => dispatch({ type: 'LOADING' }),
    hide: () => dispatch({ type: 'LOADED' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(FleetLog);

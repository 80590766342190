import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';

const Sidebar = (props) => {
    useEffect(() => {
        $(".js__accordion").each(function(){
            var selector = $(this);
            selector.find(".js__control").on("click",function(event){
                event.preventDefault();
                if ($(this).parent().hasClass("active")){
                    $(this).parent().removeClass("active");
                    $(this).next().stop().slideUp(400);
                }else{
                    selector.find(".active").children(".js__content").stop().slideUp(400);
                    selector.find(".active").removeClass("active");
                    $(this).parent().addClass("active");
                    $(this).next(".js__content").slideDown(400);
                }
            });
        });

        $(".js__menu_mobile").on("click",function(){
            $("html").toggleClass("menu-active");
            $(window).trigger("resize");
        });
        
        $(".js__menu_close").on("click",function(){
            $("html").removeClass("menu-active");
        });

        $("body").on("click",function(event){
            if ($("html.menu-active").length && $(window).width() < 800){
                var selector = $(event.target);
                if (!(selector.hasClass("main-menu") || selector.hasClass("js__menu_mobile") || selector.parents(".main-menu").length || selector.parents(".js__menu_mobile").length)){
                    $("html").removeClass("menu-active");
                }
            }
        });
    }, []);

    return (
        <section>
            <div className="main-menu">
                <header className="header">
                    <NavLink to='/' className="logo">BoatShare</NavLink>
                    <button type="button" className="button-close fa fa-times js__menu_close"></button>
                    <div className="user">
                        <NavLink to="/profile" className={`avatar sidebar-profile ${(!props.user.profile_pic || !props.user.profile_pic[0]) && 'bg-info d-flex' }`} style={{ backgroundImage: (props.user.profile_pic && props.user.profile_pic[0]) && `url(${props.user.profile_pic[0]})` }}>
                            {
                                (!props.user.profile_pic || !props.user.profile_pic[0])
                                &&
                                <div className="admin-pic-placeholder">
                                    <i className="fas fa-user-astronaut justify-content-center align-self-center" />
                                </div>
                            }
                            <span className="status online"></span>
                        </NavLink>
                        <h5 className="name"><NavLink to="/profile">{props.user.name}</NavLink></h5>
                        <h5 className="position">{props.user.brand}</h5>
                    </div>
                </header>
                <div className="content">
                    <div className="navigation">
                        <h5 className="title">Application</h5>
                        <ul className="menu js__accordion">
                            <li>
                                <NavLink to="/" exact activeClassName="current"><i className="menu-icon fas fa-chart-line"></i><span>Inicio</span></NavLink>
                            </li>
                            <li>
                                <NavLink to="/departures" exact activeClassName="current"><i className="menu-icon fas fa-route"></i><span>Salidas</span></NavLink>
                            </li>
                            <li>
                                <NavLink to="/tickets" exact activeClassName="current"><i className="menu-icon fas fa-bullhorn"></i><span>Tickets</span></NavLink>
                            </li>
                            <li>
                                <NavLink to="/messages" exact activeClassName="current"><i className="menu-icon fas fa-comments"></i><span>Mensajes no leídos</span></NavLink>
                            </li>
                        </ul>
                        <h5 className="title">Assets</h5>
                        <ul className="menu js__accordion">
                            <li>
                                <NavLink to="/fleet" exact activeClassName="current"><i className="menu-icon fas fa-ship"></i><span>Flota</span></NavLink>
                            </li>
                            <li>
                                <NavLink to="/addons" exact activeClassName="current"><i className="menu-icon fas fa-plus"></i><span>Adicionales</span></NavLink>
                            </li>
                            <li>
                                <NavLink to="/branches" exact activeClassName="current"><i className="menu-icon fas fa-globe-americas"></i><span>Sucursales</span></NavLink>
                            </li>
                            <li>
                                <NavLink to="/users" exact activeClassName="current"><i className="menu-icon fas fa-users"></i><span>Usuarios</span></NavLink>
                            </li>
                        </ul>
                        {
                            props.user.role === 'superadmin'
                            &&
                            <>
                            <h5 className="title">Manage</h5>
                            <ul className="menu js__accordion">
                                <li>
                                    <NavLink to="/personnel" exact activeClassName="current"><i className="menu-icon fas fa-users-cog"></i><span>Personal</span></NavLink>
                                </li>
                                <li>
                                    <NavLink to="/contents" exact activeClassName="current"><i className="menu-icon fas fa-file"></i><span>Contenidos</span></NavLink>
                                </li>
                                <li>
                                    <NavLink to="/notifications" exact activeClassName="current"><i className="menu-icon fas fa-comment-alt"></i><span>Notificaciones</span></NavLink>
                                </li>
                            </ul>
                            </>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
};

const mapStateToProps = state => ({ user: state.auth.user });

export default connect(mapStateToProps)(Sidebar);

import React from 'react';
import { connect } from 'react-redux';
import { getBranchByName } from '../services/branches';

const Branches = (props) => {

    const handleClick = async (name) => {
        props.show();
        try {
            const branch = await getBranchByName(name);

            props.history.push('/branches/' + branch._id)
            props.hide();
        } catch (err) {
            props.hide();
        }
    };
    
    return (
        <section>
            <div className='row'>
                <div className='col-md-6 col-xs-12 align-self-center'>
                    <h3>Sucursales</h3>
                </div>
            </div>
            <hr />
            {
                props.user.branch.map((b, i) => (
                    <div className="col-md-6 col-sm-12 mb-3" key={i}>
                        <button className="btn btn-block btn-primary" onClick={() => handleClick(b)}>{b}</button>
                    </div>
                ))
            }
        </section>
    );
};

const mapStateToProps = (state) => {
    return ({user: state.auth.user});
};

const mapDispatchToProps = (dispatch) => ({
    show: () => dispatch({ type: 'LOADING' }),
    hide: () => dispatch({ type: 'LOADED' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Branches);

import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import swal from 'sweetalert';
import { getAddonById, createAddon, updateAddon, cancel } from '../services/addons';

const AddonsEdit = (props) => {
    const [data, setData] = useState({
        name: '',
        description: '',
        branch: '',
        available: true,
        cost: 0,
    });
    const [error, setError] = useState('');
    const [isNew, setIsNew] = useState(false);
    const { id } = props.match.params;

    useEffect(() => {
        if (!id) {
            setIsNew(true);
        } else {
            getData();
        }

        return () => {
            if (id) {
                cancel();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const getData = async () => {
        props.show();
        try {
            const boat = await getAddonById(id);
            setData(boat);
            props.hide();
        } catch (err) {
            setError('Por favor, intentelo nuevamente más tarde.');
            swal({   
                title: "Hubo un problema.",   
                text: "Por favor, intentelo nuevamente más tarde.",   
                icon: "error",
                confirmButtonColor: "#DD6B55",
                className: 'logout-alert'
            });
            props.hide();
        }
    };

    const submit = async () => {
        if (isNew) {
            CreateAddon();
        } else {
            UpdateAddon();
        }
    };

    const discard = () => {
        swal({   
            title: "Descartar cambios?",   
            text: "Esta acción es irreversible.",   
            icon: "warning",  
            buttons: ['Cancel', 'Descartar'], 
            confirmButtonColor: "#DD6B55",
            className: 'logout-alert'
        }).then(
            async (value) => {
                if (value) {
                    props.history.push('/addons')
                }
            }
        );
    };

    const CreateAddon = async () => {
        try {
            const addon = await createAddon(data);
            console.log('created', addon);
            swal({   
                title: "Adicional creado",   
                text: "El adicional ha sido agregado al listado.",   
                icon: "success",
            }).then(value => props.history.push('/addons'));
        } catch (err) {
            swal({   
                title: "Hubo un problema.",   
                text: "Por favor, intentelo nuevamente más tarde.",   
                icon: "error",
                confirmButtonColor: "#DD6B55",
                className: 'logout-alert'
            });
        }
    };

    const UpdateAddon = async () => {
        try {
            const addon = await updateAddon(id, data);
            console.log('updated', addon);
            swal({   
                title: "Cambios guardados",   
                text: "Los cambios se guardaron correctamente.",   
                icon: "success",
            }).then(value => props.history.push('/addons'));
        } catch (err) {
            swal({   
                title: "Hubo un problema.",   
                text: "Por favor, intentelo nuevamente más tarde.",   
                icon: "error",
                confirmButtonColor: "#DD6B55",
                className: 'logout-alert'
            });
        }
    };

    return (
        <section className="row small-spacing">
            <div className="col-lg-12 col-xs-12">
                <div className="box-content card white">
                    <h4 className="box-title">{isNew ? 'Crear' : 'Editar'} Adicional</h4>
                    <div className="card-content">
                            <div className="row">
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="name">Nombre *</label>
                                        <input type="text" className="form-control" id="name" placeholder="Nombre" value={data.name}
                                            onChange={(event) => {
                                                const v = event.target.value;
                                                setData(value => ({...value, name: v }))
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="branch">Sucursal *</label>
                                        <select id="branch" className="form-control" value={data.branch}
                                            onChange={(event) => {
                                                const v = event.target.value;
                                                setData(value => ({...value, branch: v }))
                                            }}
                                        >

                                            <option value='' disabled>Seleccionar...</option>
                                            {
                                                props.user.branch.map(branch => <option key={branch} value={branch}>{branch}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="cost">Costo *</label>
                                        <input type="number" className="form-control" id="cost" placeholder="Costo ($)" value={data.cost}
                                            onChange={(event) => {
                                                const v = event.target.value;
                                                setData(value => ({...value, cost: parseInt(v, 10) }))
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="rented">Disponible *</label>
                                        <select id="rented" className="form-control" value={data.rented}
                                            onChange={(event) => {
                                                const v = event.target.value;
                                                setData(value => ({...value, rented: v }))
                                            }
                                        }>
                                            <option value='' disabled>Seleccionar...</option>
                                            <option value={true}>Si</option>
                                            <option value={false}>No</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className='form-group'>
                                        <label htmlFor="description">Descripción *</label>
                                        <input type="text" className="form-control" id="description" placeholder="Descripción" value={data.description}
                                            onChange={(event) => {
                                                const v = event.target.value;
                                                setData(value => ({...value, description: v }))
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-xs-12 margin-top-20">
                                    <p className="danger">{error}</p>
                                </div>
                                <div className="col-lg-6 col-xs-12 text-right margin-top-20">
                                    <button type="button" className="btn btn-secondary btn-md" style={ {marginRight: 20} } onClick={discard}>Descartar</button>
                                    <button onClick={submit} className="btn btn-primary btn-md">{ isNew ? 'Crear Adicional' : 'Guardar Cambios'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    )
};

const mapStateToProps = (state) => {
    return ({timeZone: state.auth.user.timezone, locale: state.auth.user.locale, user: state.auth.user});
};

const mapDispatchToProps = (dispatch) => ({
    show: () => dispatch({ type: 'LOADING' }),
    hide: () => dispatch({ type: 'LOADED' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddonsEdit);
import React from 'react';
import { connect } from 'react-redux';

const Footer = (props) => {
  return (
    <footer className="footer">
        <ul className="list-inline">
          <li className="list-inline-item">2020 © BoatShare</li>
          <li className="list-inline-item"><a href="mailto:fn.nelli@gmail.com">Soporte.</a></li>
        </ul>
        <small>Zona horaria: {props.timeZone}. &mdash; Version {process.env.REACT_APP_RELEASE}</small>
    </footer>
  );
};

const mapStateToProps = (state) => ({ timeZone: state.auth.user.timezone })

export default connect(mapStateToProps)(Footer);

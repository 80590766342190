import axios from 'axios';
import setAuthorizationToken from '../utils/setAuthorizationToken';
import Store from '../reducers';

export const login = async (email, password) => {

    let data = { email, password };

    try {
        let res = await axios.post(`${process.env.REACT_APP_API_URL}/admin/login`, data);

        const token = res.data.token;
        const payload = res.data.admin;
        const action = {
            type: "LOGIN",
            token,
            payload
        };

        Store.dispatch(action);
        setAuthorizationToken(token);

        return res.data;
    } catch (err) {
        throw err.response;
    }
}

export function logout() {
    return dispatch => {
      localStorage.removeItem('AUTH');
      setAuthorizationToken(false);
      dispatch();
    }
  }
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ReactTable from 'react-table';
import "react-table/react-table.css";
import { getAddonsList, cancel } from '../services/addons';

const Addons = (props) => {
    const [loading, setLoading] = useState(false);
    const [addons, setAddons] = useState([]);
    const [pages, setPages] = useState(1);
    const [show, setShow] = useState(10);
    const [columns, setColumns] = useState([]);
    const table = useRef();

    useEffect(() => { 
        function generateColumns() {
            let columns = [{
                Header: "Nombre",
                accessor: 'name',
            },
            {
                Header: 'Descripción',
                accessor: 'description',
            },
            {
                id: 'available',
                Header: 'Disponible',
                accessor: (d) => d.available ? <i className="fas fa-check text-success" /> : <i className="fas fa-times text-danger" />,
                Filter: ({ filter, onChange }) =>
                    <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : ""}
                    >
                        <option value="all">Mostrar Todo</option>
                        <option value="yes">Si</option>
                        <option value="no">No</option>
                    </select>
            },
            {
                Header: 'Sucursal',
                accessor: 'branch',
            },
            {
                id: 'cost',
                Header: 'Costo',
                accessor: d => `$ ${d.cost}`,
                filterable: false,
            },
            {
                Header: '',
                accessor: '_id',
                filterable: false,
                className: 'actions-cell',
                Cell: property => (
                    <NavLink to={"/addons/edit/" + property.value } className="btn btn-xs btn-circle btn-default">
                        <i className="ico fas fa-pen text-dark" />
                    </NavLink>
                ),
                width: 50
            },
            ];
        
            setColumns(columns);
        };
        generateColumns();

        return function cleanup() {
            cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = async (state, instance) => {
        setLoading(true);
        
        let options = {
            sort: state.sorted.length > 0 ? state.sorted[0].id : 'createdAt',
            direction: state.sorted.length > 0 ? state.sorted[0].desc ? 'desc' : 'asc' : 'desc',
            filters: state.filtered.length > 0 ? state.filtered : [],
            results: state.pageSize,
            offset: (state.page +1) * state.pageSize - state.pageSize
        };

        try {
            let data = await getAddonsList(options);
            if (data.addons.length > -1) {
                setAddons(data.addons);
                setLoading(false);
                setPages(data.pages);
                setShow(data.results);
            } else {
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
        }
    };
    
    return (
        <section>
            <div className='row'>
                <div className='col-md-6 col-xs-12 align-self-center'>
                    <h3>Adicionales</h3>
                </div>
                <div className='col-md-6 col-xs-12 text-right'>
                    <NavLink to='/addons/new' className="btn btn-icon btn-icon-left btn-success">
                        <i className="ico fas fa-plus" /> Nuevo Adicional
                    </NavLink>
                </div>
            </div>
            <hr />
            <ReactTable
                ref={table}
                data={addons}
                columns={columns}
                pages={pages}
                defaultPageSize={show}
                loading={loading}
                showPagination={true}
                showPaginationTop={false}
                showPaginationBottom={true}
                showPageJump={true}
                resizable={true}
                sortable={true}
                filterable={true}
                manual
                onFetchData={fetchData}
                onSortedChange={cancel}
                onFilteredChange={cancel}
                className="-striped -highlight"
            />
        </section>
    );
};

const mapStateToProps = (state) => {
    return ({timeZone: state.auth.user.timezone, locale: state.auth.user.locale});
};

export default connect(mapStateToProps)(Addons);

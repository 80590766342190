import axios from 'axios';
import Store from '../reducers';
const expired = 'TokenExpiredError';

const CancelToken = axios.CancelToken;
export let cancel;

export const getDeparturesList = async (options) => {
    const { sort, direction, filters, results, offset } = options;

    const body = {
        sort,
        direction,
        filters,
    };

    const url = `${process.env.REACT_APP_API_URL}/admin/departures/getAll?results=${results}&offset=${offset}`;

    try {
        const request = await axios.post(url, body,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
    
        return request.data;
    } catch (err) {
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            return err.response.data;
        }
        return err;
    }
};

export const getDepartureById = async (id) => {

    const url = `${process.env.REACT_APP_API_URL}/admin/departures/get/${id}`;

    try {
        const request = await axios.get(url,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
    
        return request.data;
    } catch (err) {
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            throw err.response.data;
        }
        throw err;
    }
};

export const addFuelDataToDeparture = async (id, params) => {
    const url = `${process.env.REACT_APP_API_URL}/admin/departures/fuel/${id}`;

    try {
        const request = await axios.post(url, params,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
    
        return request.data;
    } catch (err) {
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            throw err.response.data;
        }
        throw err;
    }
};

export const addCustomAddonToDeparture = async (id, addon) => {
    const url = `${process.env.REACT_APP_API_URL}/admin/departures/addon/${id}`;

    try {
        const request = await axios.post(url, addon,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
    
        return request.data;
    } catch (err) {
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            throw err.response.data;
        }
        throw err;
    }
};
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
/* SELECT */
import Select from 'react-select';
import swal from 'sweetalert';
import Timezones from '../utils/timezones';
import Locales from '../utils/locales';
import { update } from '../services/admin';

const groupStyles = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
};

const groupBadgeStyles = {
	backgroundColor: '#EBECF0',
	borderRadius: '2em',
	color: '#172B4D',
	display: 'inline-block',
	fontSize: 12,
	fontWeight: 'normal',
	lineHeight: '1',
	minWidth: 1,
	padding: '0.16666666666667em 0.5em',
	textAlign: 'center',
};

const formatGroupLabel = data => (
	<div style={groupStyles}>
		<span>{data.label}</span>
		<span style={groupBadgeStyles}>{data.options.length}</span>
	</div>
);

const Profile = (props) => {
	const [user, setUser] = useState(props.user);
	const [selectedLocale, setSelectedLocale] = useState(null);
	const [selectedTimezone, setSelectedTimezone] = useState(null);
	const [error, setError] = useState(false);

	useEffect(() => {
		setUser(props.user);
	}, [props.user]);

	useEffect(() => {
        console.log(user.locale);
		for (let i = 0; i < Locales.length; i += 1) {
			if (Locales[i].value === user.locale) {
                console.log(Locales[i]);
				return setSelectedLocale(Locales[i]);
			}
		}
	}, [user.locale]);

	useEffect(() => {
		for (let i = 0; i < Timezones.length; i += 1) {
			for (let j = 0; j < Timezones[i].options.length; j += 1) {
				if (Timezones[i].options[j].value === user.timezone) {
                    console.log('set', Timezones[i].options[j]);
					return setSelectedTimezone(Timezones[i].options[j]);
				}
			}
		}
	}, [user.timezone]);

	const onLocaleChange = (value, { action, removedValue }) => {
        switch (action) {
            case 'select-option': 
            break; 
            case 'remove-value':
				value = {};
                break;
            case 'pop-value':
                break;
            case 'clear':
                value = {};
                break;
            default:
                break;
        }
        setSelectedLocale(value);
	};
	
	const onTimezoneChange = (value, { action, removedValue }) => {
        switch (action) {
            case 'select-option': 
            break; 
            case 'remove-value':
				value = {};
                break;
            case 'pop-value':
                break;
            case 'clear':
                value = {};
                break;
            default:
                break;
        }
        setSelectedTimezone(value);
	};

	const saveChanges = async () => {
		const data = {
			locale: selectedLocale.value,
			timezone: selectedTimezone.value,
		};

		try {
			const req = await update(data);

			if (req) {
				swal({   
                    title: "Listo",   
                    text: "Tus preferencias se guardaron correctamente.",   
                    icon: "success"
                })
			} else {
				return setError("Lo sentimos, no podemos actualizar tus datos en este momento.");
			}
		} catch (error) {
			return setError("Lo sentimos, no podemos actualizar tus datos en este momento.");
		}
	}

	return (
        <div className="row small-spacing">
			<div className="col-lg-3 col-md-12">
				<div className="box-content bordered primary margin-bottom-20">
					<div className="profile-avatar">
						<div className="avatar position-relative mb-3">
							<div className="bg-info d-flex profile-placeholder justify-content-center align-items-center text-white">
                                <i className="ico fas fa-user-astronaut" />
                            </div>
						</div>
						<h3><strong>{user.name}</strong></h3>
						<h4>{user.brand}</h4>
					</div>
					<table className="table table-hover no-margin">
						<tbody>
							<tr>
								<td>Estado</td>
								<td>{user.active ? <span className="notice bg-success text-white">Activo</span> : <span className="notice bg-danger text-white">Deshabilitado</span>}</td>
							</tr>
							<tr>
								<td>Email</td>
								<td>{user.email}</td>
							</tr>
                            <tr>
								<td>Rol</td>
								<td><span className="notice bg-primary text-white">{user.role}</span></td>
							</tr>
							{
								user.createdAt
								&&
								<tr>
									<td>Administrador desde</td>
									<td>{ new Date(user.createdAt).toLocaleDateString(user.locale, {timeZone: user.timezone}) }</td>
								</tr>
							}
						</tbody>
					</table>
				</div>
			</div>
			<div className="col-lg-9 col-md-12">
				<div className="box-content card white">
					<h4 className="box-title">Preferencias</h4>
					<div className="card-content">
						<div className="row">
							<div className="col-12 col-sm-6">
								<div className='form-group'>
									<label htmlFor="timezone">Zona Horaria</label>
									<Select
										value={selectedTimezone}
										options={Timezones}
										formatGroupLabel={formatGroupLabel}
										onChange={onTimezoneChange}
									/>
								</div>
							</div>
							<div className="col-12 col-sm-6">
								<div className='form-group'>
									<label htmlFor="timezone">Formato de Fecha y Moneda</label>
									<Select
										value={selectedLocale}
										options={Locales}
										onChange={onLocaleChange}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-6 col-xs-12 margin-top-20">
						<p className="danger">{error}</p>
					</div>
					<div className="col-lg-6 col-xs-12 text-right margin-top-20">
						<button type="button" className="btn btn-primary btn-md" onClick={saveChanges}>Guardar Cambios</button>
					</div>
				</div>
			</div>
		</div>
      );
}

const mapStateToProps = state => ({ user: state.auth.user })

export default connect(mapStateToProps)(Profile);

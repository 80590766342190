/* eslint-disable react/jsx-no-target-blank */

import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import {connect} from 'react-redux';
import swal from 'sweetalert';
import { getDisputeById, cancel, closeDispute } from '../services/disputes';
import { uploadImage } from '../services/images';
/* MESSAGES */
import 'react-chat-elements/dist/main.css';
import { MessageBox, SystemMessage } from 'react-chat-elements';
import { getDisputeMessages, cancelMessages, sendMessage, markAsUnread } from '../services/messages';
/* NOTES */
import { getDisputeNotes, cancelGetNotes, createNote } from '../services/notes';

const DisputeDetails = props => {
    const { id } = props.match.params;
    const [dispute, setDispute] = useState(false);
    const [section, setSection] = useState('info');
    /* MESSAGES */
    const [messages, setMessages] = useState([]);
    const [msg, setMsg] = useState('');
    const [file, setFile] = useState(null);
    const [inbound, setInbound] = useState(false);
    const fileInput = useRef(null);
    /* NOTES */
    const [notes, setNotes] = useState([]);
    const [noteText, setNoteText] = useState('');

    useEffect(() => {
        getData();

        return () => {
            cancel();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        let intervalId = null;
        if (section === 'messages') {
            getMessages();
            intervalId = setInterval(getMessages, 10000);
        }

        if (section === 'notes') {
            getNotes();
        }

        return () => {
            if (cancelMessages) {
                cancelMessages();
            }
            if (cancelGetNotes) {
                cancelGetNotes();
            }
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [section]);

    const getData = async () => {
        const data = await getDisputeById(id);
        console.log(data);
        setDispute(data);
    };

    useEffect(() => {
        console.log('fileInput', fileInput);
    }, [fileInput]);

    const getMessages = async () => {
        try {
            const data = await getDisputeMessages(id);
            console.log('messages', data);
            setMessages(data);
            data.forEach(m => {
                if (m.direction === 'inbound') {
                    return setInbound(true);
                }
            })
        } catch (err) {
            if (err.message) {
                swal({   
                    title: "Hubo un problema.",   
                    text: "Por favor, intentelo nuevamente más tarde.",   
                    icon: "error",
                    confirmButtonColor: "#DD6B55",
                    className: 'logout-alert'
                });
            }
        }
    };

    const getNotes = async () => {
        try {
            const data = await getDisputeNotes(id);
            console.log('notes', data);
            setNotes(data);
        } catch (err) {
            if (err.message) {
                swal({   
                    title: "Hubo un problema.",   
                    text: "Por favor, intentelo nuevamente más tarde.",   
                    icon: "error",
                    confirmButtonColor: "#DD6B55",
                    className: 'logout-alert'
                });
            }
        }
    };

    const resolveDispute = async () => {
        swal({   
            title: "Marcar como resuelto?",   
            text: "Esta acción es irreversible.",   
            icon: "warning",  
            buttons: ['No', 'Resolve'], 
            confirmButtonColor: "#DD6B55",
            className: 'logout-alert'
        }).then(
            async (value) => {
                if (value) {
                    props.show();
                    try {
                        const d = await closeDispute(id);
                        setDispute(value => ({
                            ...value,
                            resolved: d.resolved,
                            resolvedAt: d.resolvedAt,
                        }));
                        props.hide();
                    } catch (err) {
                        swal({   
                            title: "Hubo un problema.",   
                            text: "Por favor, intentelo nuevamente más tarde.",   
                            icon: "error",
                            confirmButtonColor: "#DD6B55",
                            className: 'logout-alert'
                        });
                        props.hide();
                    }
                }
            }
        );
    };

    const sendNewMessage = async () => {
        if (!msg || msg === '') {
            return false;
        }
        props.show();
        try {
            const message = {
                content: 'text',
                text: msg,
            };

            if (file) {
                message.file = file;
                message.content = 'image';
            }

            const data = await sendMessage(id, message);
            const append = data;
            append.admin = {
                name: props.user.name,
            };

            setMessages(value => ([
                ...value,
                append,
            ]));            

            setMsg('');
            props.hide();
        } catch (err) {
            props.hide();
            swal({   
                title: "Hubo un problema.",   
                text: "Por favor, intentelo nuevamente más tarde.",   
                icon: "error",
                confirmButtonColor: "#DD6B55",
                className: 'logout-alert'
            });
        }
    };

    const createNewNote = async () => {
        if (!noteText || noteText === '') {
            return false;
        }
        props.show();
        try {
            const data = await createNote(id, noteText);
            const append = data;
            append.author = {
                name: props.user.name,
            };

            setNotes(value => ([
                ...value,
                append,
            ]));            

            setNoteText('');
            props.hide();
        } catch (err) {
            props.hide();
            swal({   
                title: "Hubo un problema.",   
                text: "Por favor, intentelo nuevamente más tarde.",   
                icon: "error",
                confirmButtonColor: "#DD6B55",
                className: 'logout-alert'
            });
        }
    };

    const setUnread = async () => {
        props.show();
        try {
            props.hide();
            const unread = await markAsUnread(id);
            if (unread) {
                setSection('info');
                setDispute(value => ({
                    ...value,
                    messages: 1,
                }))
            }
        } catch (err) {
            props.hide();
            swal({   
                title: "Hubo un problema.",   
                text: "Por favor, intentelo nuevamente más tarde.",   
                icon: "error",
                confirmButtonColor: "#DD6B55",
                className: 'logout-alert'
            });
        }
    };

    const upload = async (file) => {
        let options = {
            file,
            type: 'message_attachments',
            name: id,
        }

        try {
            let uploaded = await uploadImage(options);
            console.log(uploaded);
            setFile(uploaded.file);
        } catch (e) {
            console.error(e);
        }
    };

    const deleteFile = () => {
        setFile(null);
    }

    return (
        dispute
        ? (
        <section id="wrapper">
            <div className="row">
                <div className="col-md-3 col-xs-12">
                    <button onClick={resolveDispute} className="btn btn-danger btn-mail-main btn-block margin-bottom-20" disabled={dispute.resolved}>MARCAR COMO RESUELTO</button>
                    <NavLink to={'../departures/' + dispute.departure._id} className="btn btn-info btn-mail-main btn-block margin-bottom-20">VER DETALLES DE SALIDA</NavLink>
                    <div className="box box-solid">
                        <div className="box-body">
                            <ul className="nav nav-pills nav-stacked">
                                <li className={section === 'info' ? 'active' : ''}>
                                    <span onClick={() => setSection('info')}><i className="fa fa-info-circle"></i> Info</span>
                                </li>
                                <li className={section === 'messages' ? 'active' : ''}>
                                    <span onClick={() => setSection('messages')}><i className="fa fa-inbox"></i> Mensajes
                                    {
                                        dispute.messages > 0
                                        &&
                                        <span className="label-right bg-primary float-right text-white">{dispute.messages}</span>
                                    }
                                    </span>
                                </li>
                                <li className={section === 'notes' ? 'active' : ''}>
                                    <span onClick={() => setSection('notes')}><i className="fa fa-file-text"></i> Notas</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            {
                                dispute.prev && <NavLink to={'../tickets/' + dispute.prev} className="btn btn-black btn-mail-main btn-block margin-bottom-20">Anterior</NavLink>
                            }
                        </div>
                        <div className="col-6">
                            {
                                dispute.next && <NavLink to={'../tickets/' + dispute.next} className="btn btn-black btn-mail-main btn-block margin-bottom-20">Siguiente</NavLink>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-md-9 col-xs-12">
                    <div className="box">
                        <div className="box-header with-border">
                            <h3 className="box-title capitalize">{section}</h3>
                            <div className="box-tools">
                                {
                                    section === 'messages'
                                    &&
                                    <button className="btn-sm btn-danger" disabled={dispute.resolved || messages.length === 0 || !inbound} onClick={setUnread}>Marcar como no leído</button>
                                }
                            </div>
                        </div>
                        <div className="box-body no-padding">
                            {
                                section === 'info'
                                &&
                                <div>
                                    <ul className="list-group">
                                        <li className="list-group-item"><b>Cliente:</b> {dispute.user.name} - {dispute.user.email}</li>
                                        <li className="list-group-item"><b>Motivo:</b> {dispute.reason}</li>
                                        <li className="list-group-item"><b>Descripción:</b> {dispute.description}</li>
                                        <li className="list-group-item"><b>Fecha de Salida:</b> {new Date(dispute.departure.date).toLocaleDateString(props.locale)}</li>
                                        <li className="list-group-item"><b>Apertura de Ticket:</b> {new Date(dispute.createdAt).toLocaleString(props.locale, {timeZone: props.timeZone})}</li>
                                        {
                                            dispute.resolved
                                            &&
                                            <li className="list-group-item"><b>Fecha de Resolución:</b> {new Date(dispute.resolvedAt).toLocaleString(props.locale, {timeZone: props.timeZone})}</li>
                                        }
                                    </ul>
                                </div>
                            }
                            {
                                section === 'messages'
                                &&
                                <div>
                                    <div className="col-12 p-3 bg-secondary">
                                        {
                                            messages.length === 0
                                            ?
                                            <SystemMessage text={'No hay mensajes'}/>
                                            :
                                            messages.map(m => (
                                                <MessageBox
                                                    id={m._id}
                                                    key={m._id}
                                                    position={m.direction === 'inbound' ? 'left' : 'right'}
                                                    type={m.content}
                                                    title={m.direction === 'inbound' ? 'Cliente' : `BoatShare (enviado por ${m.admin.name})`}
                                                    titleColor={m.direction === 'inbound' ? '#000000' : 'red'}
                                                    text={m.text}
                                                    date={new Date(m.createdAt)}
                                                    status={m.seen ? 'read' : 'received'}
                                                    data={{
                                                        uri: m.file,
                                                        status: {
                                                            click: false,
                                                            loading: 0,
                                                        }
                                                    }}
                                                    onDownload={(o) => console.log(o.target.value)}
                                                    contextMenu={true}
                                                    />
                                            ))
                                        }
                                        {
                                            dispute.resolved
                                            &&
                                            <SystemMessage text={'Conversación finalizada'}/>                                            
                                        }
                                    </div>
                                    {
                                        !dispute.resolved
                                        &&
                                        <div className="col-12 p-3 bg-light">
                                            {
                                                file
                                                &&
                                                <div className='image-drag-container mb-3'>
                                                    <button id={file} className='btn btn-circle btn-danger' onClick={deleteFile}><i className='fas fa-times' /></button>
                                                    <img src={file} alt="attachment" />
                                                </div>
                                            }
                                            <div className="input-group">
                                                <input className="form-control" type="text" placeholder="Write your message..." value={msg} onChange={(e) => setMsg(e.target.value)} />
                                                <input type="file" id="my_file" style={{display: 'none'}} ref={fileInput} onChange={(e) => upload(e.target.files[0])} />
                                                <div className="input-group-append">
                                                    {
                                                        /* <button className="btn btn-primary" type="button" id="button-addon2" onClick={sendNewMessage} onClick={() => fileInput.current.click()}><i className="fas fa-image" /></button> */
                                                    }
                                                    <button className="btn btn-danger" type="button" id="button-addon2" onClick={sendNewMessage} disabled={(!msg || msg === '') && (!file || file === '')}>Enviar Mensaje</button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                            {
                                section === 'notes'
                                &&
                                <div>
                                    <div className="col-12 p-3 bg-secondary">
                                        {
                                            notes.map(note => (
                                                <div className="box-content bordered" key={note._id}>
                                                    <h4>{note.author.name}</h4>
                                                    <p>{note.content}</p>
                                                    <p className="small text-right">{new Date(note.createdAt).toLocaleString(props.locale, { timeZone: props.timeZone})}</p>
                                                </div>
                                            ))
                                        }
                                        {
                                            notes.length === 0
                                            &&
                                            <SystemMessage text={'No hay notas.'}/>
                                        }
                                    </div>
                                    <div className="col-12 p-3 bg-light">
                                        <div className="input-group">
                                            <input className="form-control" type="text" placeholder="Write your note..." value={noteText} onChange={(e) => setNoteText(e.target.value)} />
                                            <div className="input-group-append">
                                                {
                                                    /* <button className="btn btn-primary" type="button" id="button-addon2" onClick={sendNewMessage} onClick={() => fileInput.current.click()}><i className="fas fa-image" /></button> */
                                                }
                                                <button className="btn btn-primary" type="button" id="button-addon2" onClick={createNewNote} disabled={!noteText || noteText === ''}>Crear Nota</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
        ) : null
    );
};

const mapStateToProps = (state) => {
    return ({timeZone: state.auth.user.timezone, locale: state.auth.user.locale, user: state.auth.user});
};

const mapDispatchToProps = (dispatch) => ({
    show: () => dispatch({ type: 'LOADING' }),
    hide: () => dispatch({ type: 'LOADED' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(DisputeDetails);

import axios from 'axios';
import Store from '../reducers';
const expired = 'TokenExpiredError';

const CancelToken = axios.CancelToken;
export let cancel;

export const getFleetById = async id => {
    const url = `${process.env.REACT_APP_API_URL}/admin/fleet/get/${id}`;

    try {
        let request = await axios.get(url,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
    
        return request.data;
    } catch (err) {
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            throw err.response.data;
        }
        throw err;
    }
};

export const getLogsById = async id => {
    const url = `${process.env.REACT_APP_API_URL}/admin/fleet/logs/${id}`;

    try {
        let request = await axios.get(url,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
    
        return request.data;
    } catch (err) {
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            throw err.response.data;
        }
        throw err;
    }
};

export const updateLogsById = async (id, hours) => {
    const url = `${process.env.REACT_APP_API_URL}/admin/fleet/logs/${id}`;

    try {
        const request = await axios.post(url, { hours },
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
    
        return request.data;
    } catch (err) {
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            throw err.response.data;
        }
        throw err;
    }
};

export const getFleetList = async (options) => {
    const { sort, direction, filters, results, offset } = options;

    const body = {
        sort,
        direction,
        filters,
    };

    const url = `${process.env.REACT_APP_API_URL}/admin/fleet/getAll?results=${results}&offset=${offset}`;

    try {
        let request = await axios.post(url, body,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
    
        return request.data;
    } catch (err) {
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            throw err.response.data;
        }
        throw err;
    }
};

export const addBoatToFleet = async (data) => {
    Store.dispatch({ type: 'LOADING' });
    const url = `${process.env.REACT_APP_API_URL}/admin/fleet/create`;

    try {
        let request = await axios.post(url, data,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
        Store.dispatch({ type: 'LOADED' });
        return request.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            throw err.response.data;
        }
        throw err;
    }
};

export const updateBoat = async (id, data) => {
    Store.dispatch({ type: 'LOADING' });
    const url = `${process.env.REACT_APP_API_URL}/admin/fleet/update/${id}`;

    try {
        let request = await axios.post(url, data,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
        Store.dispatch({ type: 'LOADED' });
        return request.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            throw err.response.data;
        }
        throw err;
    }
};
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import "react-table/react-table.css";
import { getUsersList, cancel } from '../services/users';

const Users = (props) => {
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [pages, setPages] = useState(1);
    const [show, setShow] = useState(10);
    const [columns, setColumns] = useState([]);
    const table = useRef();

    useEffect(() => { 
        function generateColumns() {
            const columns = [{
                Header: "Nombre",
                accessor: 'name',
            },
            {
                Header: "Email",
                accessor: 'email',
            },
            {
                Header: 'Sucursal',
                accessor: 'branch',
            },
            {
                id: 'balance',
                Header: 'Balance',
                accessor: (d) => <span className={!d.balance || d.balance > 0 ? 'text-success' : 'text-danger' }>$ {d.balance || 0}</span>
            },
            {
                id: 'createdAt',
                Header: 'Fecha de Alta',
                accessor: (d) => new Date(d.createdAt).toLocaleDateString('es-ar'),
                filterable: false,
            }];
        
            setColumns(columns);
        };
        generateColumns();

        return function cleanup() {
            cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = async (state, instance) => {
        setLoading(true);
        
        const options = {
            sort: state.sorted.length > 0 ? state.sorted[0].id : 'balance',
            direction: state.sorted.length > 0 ? state.sorted[0].desc ? 'desc' : 'asc' : 'asc',
            filters: state.filtered.length > 0 ? state.filtered : [],
            results: state.pageSize,
            offset: (state.page +1) * state.pageSize - state.pageSize
        };

        try {
            const data = await getUsersList(options);
            if (data.users.length > -1) {
                setUsers(data.users);
                setLoading(false);
                setPages(data.pages);
                setShow(data.results);
            } else {
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
        }
    };
    
    return (
        <section>
            <div className='row'>
                <div className='col-md-6 col-xs-12 align-self-center'>
                    <h3>Usuarios</h3>
                </div>
            </div>
            <hr />
            <ReactTable
                ref={table}
                data={users}
                columns={columns}
                pages={pages}
                defaultPageSize={show}
                loading={loading}
                showPagination={true}
                showPaginationTop={false}
                showPaginationBottom={true}
                showPageJump={true}
                resizable={true}
                sortable={true}
                filterable={true}
                manual
                onFetchData={fetchData}
                onSortedChange={cancel}
                onFilteredChange={cancel}
                className="-striped -highlight"
            />
        </section>
    );
};

const mapStateToProps = (state) => {
    return ({timeZone: state.auth.user.timezone, locale: state.auth.user.locale});
};

export default connect(mapStateToProps)(Users);

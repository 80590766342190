import React from 'react';
import NavBar from './NavBar';
import SideBar from './SideBar';
import Footer from './Footer';

const AppContainer = props => {
    return (
        <main>
            <NavBar />
            <SideBar />
            <div id="wrapper">
                <div className="main-content">
                    {props.children}
                    <Footer />
                </div>
            </div>
        </main>
    )
};

export default AppContainer;
import React from 'react';
import './template.css';

const NotificationTemplate = (props) => {
    return (
        <div className="notification-template">
            <div className="notification-header">
                <div className="notification-header-left">
                    <div className="notification-icon"></div>
                    <p className="notification-app">BoatShare</p>
                </div>
                <div className="notification-header-right">
                    <p className="notification-time">now</p>
                </div>
            </div>
            <div className="notification-body">
                {
                    props.title
                    &&
                    <p className="notification-title">{props.title}</p>
                }
                <p className="notification-message">{props.message}</p>
            </div>
        </div>
    )
};

export default NotificationTemplate;

import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ReactTable from 'react-table';
import "react-table/react-table.css";
import { getFleetList, cancel } from '../services/fleet';
import ModalImage from "./assets/modal-images";

const Fleet = (props) => {
    const [loading, setLoading] = useState(false);
    const [fleet, setFleet] = useState([]);
    const [pages, setPages] = useState(1);
    const [show, setShow] = useState(10);
    const [columns, setColumns] = useState([]);
    const [modalIsOpen, setModalOpen] = useState(false);
    const [detailedContent, setDetailedContent] = useState(false);
    const table = useRef();

    useEffect(() => { 
        function generateColumns() {
            let columns = [{
                Header: "Nombre",
                id: 'name',
                accessor: (d) => (<span>
                    {
                        d.image &&
                        <i id={d._id} className='open-modal-image fa fa-image text-warning' onClick={() => openModal(d.image)} />
                    }
                    {d.name}
                </span>),
            },
            {
                Header: 'Categoría',
                accessor: 'category',
                filterable: true,
            },
            {
                Header: 'Tiempos de Motor',
                accessor: 'stroke',
                filterable: false,
                width: 140,
            },
            {
                id: 'active',
                Header: 'Activa',

                width: 140,
                accessor: (d) => d.active ? <i className="fas fa-check text-success" /> : <i className="fas fa-times text-danger" />,
                Filter: ({ filter, onChange }) =>
                    <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : ""}
                    >
                        <option value="all">Mostrar Todo</option>
                        <option value="yes">Si</option>
                        <option value="no">No</option>
                    </select>
            },
            {
                Header: 'Sucursal',
                accessor: 'branch',
            },
            {
                Header: '',
                accessor: '_id',
                filterable: false,
                className: 'actions-cell',
                Cell: property => (
                    <div className="dropdown">
                        <button className="btn btn-xs btn-circle btn-default" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="ico fas fa-ellipsis-v text-dark" />
                        </button>
                        <div className="dropdown-menu sub-menu" aria-labelledby="dropdownMenuButton">
                            <NavLink to={"fleet/edit/" + property.value } className="dropdown-item">
                                Editar
                            </NavLink>
                            <NavLink to={"fleet/log/" + property.value } className="dropdown-item">
                                Bitácora
                            </NavLink>
                        </div>
                    </div>
                ),
                width: 50
            }
            ];
        
            setColumns(columns);
        };
        generateColumns();

        return function cleanup() {
            cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = async (state, instance) => {
        setLoading(true);
        
        let options = {
            sort: state.sorted.length > 0 ? state.sorted[0].id : 'createdAt',
            direction: state.sorted.length > 0 ? state.sorted[0].desc ? 'desc' : 'asc' : 'desc',
            filters: state.filtered.length > 0 ? state.filtered : [],
            results: state.pageSize,
            offset: (state.page +1) * state.pageSize - state.pageSize
        };

        try {
            let data = await getFleetList(options);
            if (data.fleet.length > -1) {
                setFleet(data.fleet);
                setLoading(false);
                setPages(data.pages);
                setShow(data.results);
            } else {
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
        }
    };

    const openModal = async (url) => {
        setModalOpen(true);
        setDetailedContent(url);
    };

    const closeModal = () => {
        setModalOpen(false);
        setDetailedContent(false);
    };
    
    return (
        <section>
            <div className='row'>
                <div className='col-md-6 col-xs-12 align-self-center'>
                    <h3>Flota</h3>
                </div>
                <div className='col-md-6 col-xs-12 text-right'>
                    <NavLink to='/fleet/new' className="btn btn-icon btn-icon-left btn-success">
                        <i className="ico fas fa-plus" /> Agregar Embarcación
                    </NavLink>
                </div>
            </div>
            <hr />
            <ReactTable
                ref={table}
                data={fleet}
                columns={columns}
                pages={pages}
                defaultPageSize={show}
                loading={loading}
                showPagination={true}
                showPaginationTop={false}
                showPaginationBottom={true}
                showPageJump={true}
                resizable={true}
                sortable={true}
                filterable={true}
                manual
                onFetchData={fetchData}
                onSortedChange={cancel}
                onFilteredChange={cancel}
                className="-striped -highlight"
            />
            <ModalImage modalIsOpen={modalIsOpen} closeModal={closeModal} detailedContent={detailedContent} />
        </section>
    );
};

const mapStateToProps = (state) => {
    return ({timeZone: state.auth.user.timezone, locale: state.auth.user.locale});
};

export default connect(mapStateToProps)(Fleet);

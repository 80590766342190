import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import StatisticsBox from './assets/StatisticsBox';
import { getDashboard, cancel } from '../services/dashboard';
import { getDebt, getBalance, cancel as cancelDebt } from '../services/users';

const Dashboard = (props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [debt, setDebt] = useState(0);
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    async function start() {
      try {
        const dashboard = await getDashboard();
        const d = await getDebt();
        const b = await getBalance();
        setData(dashboard);
        setBalance(b);
        setDebt(d);
        setLoading(false);
      } catch (e) {
        
      }
    }
    start();

    return function cleanup() {
        cancel();
        cancelDebt();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  return (
    <div id="wrapper">
      <div className='row'>
        <div className='col-12 align-self-center'>
            <h3>Panel de Administración</h3>
        </div>
      </div>
      <hr />
      <div className='row mt-3 mb-5'>
        <NavLink to="/users" className="col-lg-6 col-md-6 col-xs-12">
          <StatisticsBox
            background={balance > 0 ? 'bg-success' : balance === 0 ? 'bg-dark' : 'bg-danger'}
            icon="fas fa-money-bill-alt"
            loading={loading}
            value={`$ ${balance}`}
            text="Balance"
          />
        </NavLink>
        <NavLink to="/users" className="col-lg-6 col-md-6 col-xs-12">
          <StatisticsBox
            background={debt > 0 ? 'bg-success' : debt === 0 ? 'bg-dark' : 'bg-danger'}
            icon="fas fa-money-bill-alt"
            loading={loading}
            value={`$ ${debt}`}
            text="Total Deuda"
          />
        </NavLink>
        <NavLink to="/tickets" className="col-lg-6 col-md-6 col-xs-12">
          <StatisticsBox
            background="bg-danger"
            icon="fas fa-bullhorn"
            loading={loading}
            value={data.disputes}
            text="Tickets sin resolver"
          />
        </NavLink>
        <NavLink to="/departures" className="col-lg-6 col-md-6 col-xs-12">
          <StatisticsBox
            background="bg-info"
            icon="fas fa-route"
            loading={loading}
            value={data.ongoing}
            text="Salidas en curso"
          />
        </NavLink>
        <NavLink to="/departures" className="col-lg-6 col-md-6 col-xs-12">
          <StatisticsBox
            background="bg-primary"
            icon="fas fa-anchor"
            loading={loading}
            value={data.upcoming}
            text="Próximas Salidas"
          />
        </NavLink>
        <NavLink to="/fleet" className="col-lg-6 col-md-6 col-xs-12">
          <StatisticsBox
            background="bg-warning"
            icon="fas fa-ship"
            loading={loading}
            value={data.fleet}
            text="Lanchas Disponibles"
          />
        </NavLink>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return ({timeZone: state.auth.user.timezone, locale: state.auth.user.locale});
};

export default connect(mapStateToProps)(Dashboard);

import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ReactTable from 'react-table';
import "react-table/react-table.css";
import { getDisputesList, cancel } from '../services/disputes';

const Disputes = (props) => {
    const [loading, setLoading] = useState(false);
    const [disputes, setDisputes] = useState([]);
    const [pages, setPages] = useState(1);
    const [show, setShow] = useState(10);
    const [columns, setColumns] = useState([]);
    const table = useRef();

    useEffect(() => { 
        function generateColumns() {
            let columns = [{
                Header: "Cliente",
                id: 'user.name',
                accessor: (d) => d.user.name,
            },
            {
                Header: 'Motivo',
                accessor: 'reason',
            },
            {
                Header: 'Sucursal',
                accessor: 'user.branch',
                filterable: false,
            },
            {
                id: 'departure.date',
                Header: 'Fecha de Salida',
                accessor: (d) => (
                    <span>
                        {new Date(d.departure.date).toLocaleDateString('es-ar')}
                        <NavLink to={"/departures/" + d.departure._id }> <i className="fas fa-link" /></NavLink>
                    </span>),
                filterable: false,
            },
            {
                id: 'resolved',
                Header: 'Resuelto',
                accessor: (d) => d.resolved ? <i className="fas fa-check text-success" /> : <i className="fas fa-times text-danger" />,
                Filter: ({ filter, onChange }) =>
                    <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : ""}
                    >
                        <option value="all">Mostrar Todo</option>
                        <option value="yes">Si</option>
                        <option value="no">No</option>
                    </select>
            },
            {
                Header: '',
                accessor: '_id',
                filterable: false,
                className: 'actions-cell',
                Cell: property => (
                    <NavLink to={"/tickets/" + property.value } className="btn btn-xs btn-circle btn-default">
                        <i className="ico fas fa-info text-dark" />
                    </NavLink>
                ),
                width: 50
            }
            ];
        
            setColumns(columns);
        };
        generateColumns();

        return function cleanup() {
            cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = async (state, instance) => {
        setLoading(true);
        
        let options = {
            sort: state.sorted.length > 0 ? state.sorted[0].id : 'createdAt',
            direction: state.sorted.length > 0 ? state.sorted[0].desc ? 'desc' : 'asc' : 'desc',
            filters: state.filtered.length > 0 ? state.filtered : [],
            results: state.pageSize,
            offset: (state.page +1) * state.pageSize - state.pageSize
        };

        try {
            let data = await getDisputesList(options);
            if (data.disputes.length > -1) {
                setDisputes(data.disputes);
                setLoading(false);
                setPages(data.pages);
                setShow(data.results);
            } else {
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
        }
    };
    
    return (
        <section>
            <div className='row'>
                <div className='col-md-6 col-xs-12 align-self-center'>
                    <h3>Tickets</h3>
                </div>
            </div>
            <hr />
            <ReactTable
                ref={table}
                data={disputes}
                columns={columns}
                pages={pages}
                defaultPageSize={show}
                loading={loading}
                showPagination={true}
                showPaginationTop={false}
                showPaginationBottom={true}
                showPageJump={true}
                resizable={true}
                sortable={true}
                filterable={true}
                manual
                onFetchData={fetchData}
                onSortedChange={cancel}
                onFilteredChange={cancel}
                className="-striped -highlight"
            />
        </section>
    );
};

const mapStateToProps = (state) => {
    return ({timeZone: state.auth.user.timezone, locale: state.auth.user.locale});
};

export default connect(mapStateToProps)(Disputes);

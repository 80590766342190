import React, { useState } from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "react-table/react-table.css";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import Modal from 'react-modal';
import { modalStyles } from '../utils/styles';
import swal from '@sweetalert/with-react';
import { createNotification } from '../services/notifications';
import { getContentByName } from '../services/contents';
import NotificationTemplate from '../components/Notifications/template';

Modal.setAppElement(document.getElementById('root'));

const maxDate = () => {
    let today = new Date();
    return today.setDate(today.getDate() + 30);
};

const NotificationsNew = (props) => {
    const [name, setName] = useState('');
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [start_date, setStart_date] = useState('');
    const [content, setContent] = useState(null);
    const [role, setRole] = useState('client');
    const [branches, setBranches] = useState([]);
    const [sendToAndroid, setSendToAndroid] = useState(true);
    const [sendToIos, setSendToIos] = useState(true);
    const [modal, setModal] = useState(false);
    const [activeModal, setActiveModal] = useState('');
    const [changes, setChanges] = useState(false);
    const [error, setError] = useState(null);

    const loadOptions = async (inputValue) => {
        const data = await getContentByName(inputValue);
        return data.contents.map(item => ({ label: item.name, value: item._id }))
    };

    const openModal = (event) => {
        setModal(true);
        setActiveModal(event.target.id);
    };

    const closeModal = () => {
        setModal(false);
        setActiveModal('');
    };

    const onBranchChange = (value, { action, removedValue }) => {
        let values = branches || [];

        console.log(value);
        switch (action) {
            case 'select-option': 
                values = value;
            break; 
            case 'remove-value':
                values = values.filter(val => removedValue.value !== val.value);
                break;
            case 'pop-value':
                break;
            case 'clear':
                values = [];
                break;
            default:
                break;
        }
        setBranches(values);
	};

    const discard = () => {
        if (changes) {
            swal({   
                title: "Descartar?",   
                text: "Are you sure you want to discard the changes you made?",   
                icon: "warning",  
                buttons: ['No, keep the changes.', 'Yes, delete them.'], 
                confirmButtonColor: "#DD6B55",
                className: 'logout-alert'
            }).then(
                (value) => {
                    if (value) {
                        props.history.push('/notifications');
                    }
                }
            );
        } else {
            props.history.push('/notifications');
        }
        
    };

    const submit = async (event) => {
        event.preventDefault();
        let today = new Date();

        if (!name || !message) {
            return setError("Please, complete all the required fields.");
        }

        if (!start_date) {
            return setError("Please, select a valid date and time.");
        }

        if (start_date < new Date()) {
            return setError("Please, select a date in the future.");
        }

        if (start_date < today) {
            return setError("The send date must be in the future.");
        }

        if (!sendToAndroid && !sendToIos) {
            return setError("You must choose at least one OS.");
        }

        const newNotification = {
            content: content ? content.value : null,
            branches: branches.map(a => a.value),
            role,
            name,
            title,
            message,
            start_date,
            sendToAndroid,
            sendToIos,
        };

        swal({   
            title: "New Notification",
            text: `Datetime: ${start_date.toLocaleString()}
            Platform: ${sendToAndroid ? 'Android' : ''} ${sendToIos ? 'iOS' : ''}`,
            content: <NotificationTemplate title={title} message={message} />,
            icon: "info",
            buttons: true,
        }).then(
            (value) => {
                if (value) {
                    create(newNotification);
                }
            }
        );

        
    };

    const create = async (notification) => {
        try {
            const create = await createNotification(notification);
            setChanges(false);
            setError(null);

            if (create) {
                swal({   
                    title: "Listo",   
                    text: "La notificación está siendo creada.",   
                    icon: "success"
                }).then(
                    (value) => {
                        props.history.push('/notifications');
                    }
                );
            } else {
                setError("Hubo un problema. Por favor, intentelo nuevamente más tarde.");
            }
        } catch (e) {
            setError("Hubo un problema. Por favor, intentelo nuevamente más tarde.");
        }
    };

    const handleInputChanges = (event) => {
        let { id, value } = event.target;

        switch(id) {
            case 'name':
                setName(value);
                break;
            case 'title':
                setTitle(value);
                break;
            case 'message':
                setMessage(value);
                break;
            case 'role':
                setRole(value);
                break;
            default:
                break;
        }

        setChanges(true);
    };

    const handleStartChange = (date) => {
        setStart_date(date);
        setChanges(true);
    };

    const handleBooleansChange = (e) => {
        let { value, id } = e.target;
        switch(id) {
            case 'android-users':
                setSendToAndroid(value === 'yes' ? true : false);
                break;
            case 'ios-users':
                setSendToIos(value === 'yes' ? true : false);
                break;
            default:
                break;
        }
    };

    const handleContentChange = (value, { action, removedValue }) => {
        switch (action) {
            case 'select-option': 
            break; 
            case 'remove-value':
				value = null;
                break;
            case 'pop-value':
                break;
            case 'clear':
                value = null;
                break;
            default:
                break;
        }
        setContent(value);
    };
    
    const addEmoji = async (emoji) => {
        if (activeModal === 'message-modal' || activeModal === 'message-modal-icon') {
            setMessage(value => value + emoji.native);
            closeModal();
        } else if (activeModal === 'title-modal' || activeModal === 'title-modal-icon') {
            setTitle(value => value + emoji.native);
            closeModal();
        } else {
            closeModal()
        }
    };

    return (
        <section className="row small-spacing">
            <div className="col-lg-12 col-xs-12">
                <div className="box-content card white">
                    <h4 className="box-title">Nueva Notificación</h4>
                    <div className="card-content">
                        <form onSubmit={submit}>
                            <div className="row">
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="name">Nombre *</label> <em><small>(Sólo aparece en los listados internos)</small></em>
                                        <input type="text" className="form-control" id="name" placeholder="Name" value={name} onChange={handleInputChanges} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="title">Título</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" id="title" placeholder="Enter a Title" value={title} onChange={handleInputChanges} />
                                            <div className="input-group-btn">
                                                <button type="button" id="title-modal" onClick={openModal} className="btn btn-info no-border">
                                                    <i id="title-modal-icon" className="far fa-laugh text-white" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className='form-group'>
                                        <label htmlFor="message">Mensaje *</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" id="message" placeholder="Enter a Message" value={message} onChange={handleInputChanges} />
                                            <div className="input-group-btn">
                                                <button type="button" id="message-modal" onClick={openModal} className="btn btn-info no-border">
                                                    <i id="message-modal-icon" className="far fa-laugh text-white" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="start_date">Fecha de Envío *</label>
                                        <div className="form-control">
                                        <DatePicker
                                            id="start_date"
                                            className="datepick"
                                            selected={start_date}
                                            onChange={handleStartChange}
                                            showTimeSelect
                                            dateFormat="dd/MM/yyyy - HH:mm"
                                            timeFormat="HH:mm"
                                            timeIntervals={15}
                                            timeCaption="time"
                                            minDate={new Date()}
                                            maxDate={maxDate()}
                                            placeholderText="Enter a Send Date"
                                        />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="content">Contenido Vinculado</label>
                                        <AsyncSelect
                                            name="content"
                                            cacheOptions
                                            isSearchable
                                            defaultOptions
                                            loadOptions={loadOptions}
                                            value={content}
                                            onChange={handleContentChange}
                                            isClearable
                                            placeholder="Select your content"
                                            noOptionsMessage={() => 'No contents found'}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="role">Rol de usuario *</label>
                                        <select id="role" className="form-control" onChange={handleInputChanges} value={role}>
                                            <option value="client">Cliente</option>
                                            <option value="admin">Administrador</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="branches">Sucursal *</label>
                                        <Select
                                            closeMenuOnSelect={false}
                                            value={branches}
                                            isMulti
                                            name="branches"
                                            onChange={onBranchChange}
                                            options={props.user.branch.map(a => ({ label: a, value: a}))}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="android-users">Enviar a usuarios de Android</label>
                                        <select id="android-users" className="form-control" onChange={handleBooleansChange} value={sendToAndroid ? 'yes' : 'no'}>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="ios-users">Enviar a usuarios de iOS</label>
                                        <select id="ios-users" className="form-control" onChange={handleBooleansChange} value={sendToIos ? 'yes' : 'no'}>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xs-12 margin-top-20">
                                    <p className="danger">{error}</p>
                                </div>
                                <div className="col-lg-6 col-xs-12 text-right margin-top-20">
                                    <button type="button" className="btn btn-secondary btn-md" style={ {marginRight: 20} } onClick={discard}>Descartar</button>
                                    <button type="submit" className="btn btn-primary btn-md" disabled={!changes}>Crear Notificación</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Modal
                isOpen={modal}
                style={modalStyles}
                onRequestClose={closeModal}
                contentLabel="Emoji Modal"
            >
                <Picker title="Seleccioná un Emoji" exclude={['recent']} onSelect={(emoji) => addEmoji(emoji)} />
            </Modal>
        </section>
    );
};


const mapStateToProps = state => ({ user: state.auth.user })

export default connect(mapStateToProps)(NotificationsNew);

import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';
import swal from 'sweetalert';
import { getAdminById, createAdmin, updateAdminById, cancel } from '../services/admin';

const PersonnelEdit = (props) => {
    const [data, setData] = useState({
        first_name: '',
        last_name: '',
        active: true,
        branch: [],
        email: '',
        role: 'admin',
    });
    const [error, setError] = useState('');
    const [isNew, setIsNew] = useState(false);
    const { id } = props.match.params;

    useEffect(() => {
        if (!id) {
            setIsNew(true);
        } else {
            getData();
        }

        return () => {
            if (id) {
                cancel();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const getData = async () => {
        props.show();
        try {
            const d = await getAdminById(id);
            setData(d);
            props.hide();
        } catch (err) {
            swal({   
                title: "Hubo un problema.",   
                text: "Por favor, intentelo nuevamente más tarde.",   
                icon: "error",
                confirmButtonColor: "#DD6B55",
                className: 'logout-alert'
            });
            props.hide();
        }
    };

    const submit = async () => {
        if (!data || !data.first_name || !data.last_name || data.branch.length === 0 || !data.role || !data.email) {
            return setError('Please, complete all the required fields.');
        }
        if (isNew) {
            CreateAdmin();
        } else {
            UpdateAdmin();
        }
    };

    const discard = () => {
        swal({   
            title: "Descartar cambios?",   
            text: "Esta acción es irreversible.",   
            icon: "warning",  
            buttons: ['Cancel', 'Descartar'], 
            confirmButtonColor: "#DD6B55",
            className: 'logout-alert'
        }).then(
            async (value) => {
                if (value) {
                    props.history.push('/personnel')
                }
            }
        );
    };

    const CreateAdmin = async () => {
        swal("Please, add a Password for the New Admin User:", {
            content: "input",
          }).then(async (password) => {
            if (!password || password.length < 4) {
                return swal('Please, add a valid password');
            }

            const user = { ...data, password }

            try {
                const adm = await createAdmin(user);
                console.log('created', adm);
                swal({   
                    title: "Nuevo Administrador Creado",   
                    text: "Por favor, compartí las credenciales con el nuevo administrador.",   
                    icon: "success",
                }).then(value => props.history.push('/personnel'));
            } catch (err) {
                swal({   
                    title: "Hubo un problema.",   
                    text: "Por favor, intentelo nuevamente más tarde.",   
                    icon: "error",
                    confirmButtonColor: "#DD6B55",
                    className: 'logout-alert'
                });
            }
          });
    };

    const UpdateAdmin = async () => {
        try {
            const adm = await updateAdminById(id, data);
            console.log('updated', adm);
            swal({   
                title: "Cambios guardados",   
                text: "Admin user has been updated.",   
                icon: "success",
            }).then(value => props.history.push('/personnel'));
        } catch (err) {
            swal({   
                title: "Hubo un problema.",   
                text: "Por favor, intentelo nuevamente más tarde.",   
                icon: "error",
                confirmButtonColor: "#DD6B55",
                className: 'logout-alert'
            });
        }
    };

    const onBranchChange = (value, { action, removedValue }) => {
        let values = data.branch || [];
        switch (action) {
            case 'select-option': 
                values = value;
            break; 
            case 'remove-value':
                values = values.filter(val => removedValue.value !== val);
                break;
            case 'pop-value':
                break;
            case 'clear':
                values = [];
                break;
            default:
                break;
        }
        const d = values.map(a => a.value);
        setData(v => ({ ...v, branch: d }));
	};

    return (
        <section className="row small-spacing">
            <div className="col-lg-12 col-xs-12">
                <div className="box-content card white">
                    <h4 className="box-title">{isNew ? 'Crear' : 'Editar'} Administrador</h4>
                    <div className="card-content">
                            <div className="row">
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="fname">Nombre *</label>
                                        <input type="text" className="form-control" id="fname" placeholder="First Name" value={data.first_name}
                                            onChange={(event) => {
                                                const v = event.target.value;
                                                setData(value => ({...value, first_name: v }))
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="lname">Apellido *</label>
                                        <input type="text" className="form-control" id="lname" placeholder="Last Name" value={data.last_name}
                                            onChange={(event) => {
                                                const v = event.target.value;
                                                setData(value => ({...value, last_name: v }))
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="email">Email *</label>
                                        <input type="text" className="form-control" id="email" placeholder="Email" value={data.email}
                                            onChange={(event) => {
                                                const v = event.target.value;
                                                setData(value => ({...value, email: v }))
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="rented">Rol *</label>
                                        <select id="rented" className="form-control" value={data.role}
                                            onChange={(event) => {
                                                const v = event.target.value;
                                                setData(value => ({...value, role: v }))
                                            }
                                        }>
                                            <option value='' disabled>Select...</option>
                                            <option value={'admin'}>Admin</option>
                                            <option value={'superadmin'}>SuperAdmin</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="branch">Sucursales *</label>
                                        <Select
                                            closeMenuOnSelect={false}
                                            value={data.branch.map(a => ({ label: a, value: a}))}
                                            isMulti
                                            name="branch"
                                            onChange={onBranchChange}
                                            options={props.user.branch.map(a => ({ label: a, value: a}))}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="active">Activo *</label>
                                        <select id="active" className="form-control" value={data.active}
                                            onChange={(event) => {
                                                const v = event.target.value;
                                                setData(value => ({...value, active: v }))
                                            }
                                        }>
                                            <option value='' disabled>Select...</option>
                                            <option value={true}>Si</option>
                                            <option value={false}>No</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-xs-12 margin-top-20">
                                    <p className="danger">{error}</p>
                                </div>
                                <div className="col-lg-6 col-xs-12 text-right margin-top-20">
                                    <button type="button" className="btn btn-secondary btn-md" style={ {marginRight: 20} } onClick={discard}>Descartar</button>
                                    <button onClick={submit} className="btn btn-primary btn-md">{ isNew ? 'Crear Administrador' : 'Guardar Cambios'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    )
};

const mapStateToProps = (state) => {
    return ({timeZone: state.auth.user.timezone, locale: state.auth.user.locale, user: state.auth.user});
};

const mapDispatchToProps = (dispatch) => ({
    show: () => dispatch({ type: 'LOADING' }),
    hide: () => dispatch({ type: 'LOADED' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonnelEdit);
const Locales = [
	{
		"value": "af",
		"label": "Afrikaans",
		"name": "Afrikaans",
		"commonName": ""
	},
	{
		"value": "af-NA",
		"label": "Afrikaans (Namibia)",
		"name": "Afrikaans (Namibië)",
		"commonName": ""
	},
	{
		"value": "af-ZA",
		"label": "Afrikaans (South Africa)",
		"name": "Afrikaans (Suid-Afrika)",
		"commonName": ""
	},
	{
		"value": "agq",
		"label": "Aghem",
		"name": "Aghem",
		"commonName": ""
	},
	{
		"value": "agq-CM",
		"label": "Aghem (Cameroon)",
		"name": "Aghem (Kàmàlûŋ)",
		"commonName": ""
	},
	{
		"value": "ak",
		"label": "Akan",
		"name": "Akan",
		"commonName": ""
	},
	{
		"value": "ak-GH",
		"label": "Akan (Ghana)",
		"name": "Akan (Gaana)",
		"commonName": ""
	},
	{
		"value": "am",
		"label": "Amharic",
		"name": "አማርኛ",
		"commonName": ""
	},
	{
		"value": "am-ET",
		"label": "Amharic (Ethiopia)",
		"name": "አማርኛ (ኢትዮጵያ)",
		"commonName": ""
	},
	{
		"value": "ar",
		"label": "Arabic",
		"name": "العربية",
		"commonName": ""
	},
	{
		"value": "ar-001",
		"label": "Arabic (World)",
		"name": "العربية (العالم)",
		"commonName": "Modern Standard Arabic"
	},
	{
		"value": "ar-AE",
		"label": "Arabic (United Arab Emirates)",
		"name": "العربية (الإمارات العربية المتحدة)",
		"commonName": ""
	},
	{
		"value": "ar-BH",
		"label": "Arabic (Bahrain)",
		"name": "العربية (البحرين)",
		"commonName": ""
	},
	{
		"value": "ar-DJ",
		"label": "Arabic (Djibouti)",
		"name": "العربية (جيبوتي)",
		"commonName": ""
	},
	{
		"value": "ar-DZ",
		"label": "Arabic (Algeria)",
		"name": "العربية (الجزائر)",
		"commonName": ""
	},
	{
		"value": "ar-EG",
		"label": "Arabic (Egypt)",
		"name": "العربية (مصر)"
	},
	{
		"value": "ar-EH",
		"label": "Arabic (Western Sahara)",
		"name": "العربية (الصحراء الغربية)"
	},
	{
		"value": "ar-ER",
		"label": "Arabic (Eritrea)",
		"name": "العربية (إريتريا)"
	},
	{
		"value": "ar-IL",
		"label": "Arabic (Israel)",
		"name": "العربية (إسرائيل)"
	},
	{
		"value": "ar-IQ",
		"label": "Arabic (Iraq)",
		"name": "العربية (العراق)"
	},
	{
		"value": "ar-JO",
		"label": "Arabic (Jordan)",
		"name": "العربية (الأردن)"
	},
	{
		"value": "ar-KM",
		"label": "Arabic (Comoros)",
		"name": "العربية (جزر القمر)"
	},
	{
		"value": "ar-KW",
		"label": "Arabic (Kuwait)",
		"name": "العربية (الكويت)"
	},
	{
		"value": "ar-LB",
		"label": "Arabic (Lebanon)",
		"name": "العربية (لبنان)"
	},
	{
		"value": "ar-LY",
		"label": "Arabic (Libya)",
		"name": "العربية (ليبيا)"
	},
	{
		"value": "ar-MA",
		"label": "Arabic (Morocco)",
		"name": "العربية (المغرب)"
	},
	{
		"value": "ar-MR",
		"label": "Arabic (Mauritania)",
		"name": "العربية (موريتانيا)"
	},
	{
		"value": "ar-OM",
		"label": "Arabic (Oman)",
		"name": "العربية (عُمان)"
	},
	{
		"value": "ar-PS",
		"label": "Arabic (Palestinian Territories)",
		"name": "العربية (الأراضي الفلسطينية)"
	},
	{
		"value": "ar-QA",
		"label": "Arabic (Qatar)",
		"name": "العربية (قطر)"
	},
	{
		"value": "ar-SA",
		"label": "Arabic (Saudi Arabia)",
		"name": "العربية (المملكة العربية السعودية)"
	},
	{
		"value": "ar-SD",
		"label": "Arabic (Sudan)",
		"name": "العربية (السودان)"
	},
	{
		"value": "ar-SO",
		"label": "Arabic (Somalia)",
		"name": "العربية (الصومال)"
	},
	{
		"value": "ar-SS",
		"label": "Arabic (South Sudan)",
		"name": "العربية (جنوب السودان)"
	},
	{
		"value": "ar-SY",
		"label": "Arabic (Syria)",
		"name": "العربية (سوريا)"
	},
	{
		"value": "ar-TD",
		"label": "Arabic (Chad)",
		"name": "العربية (تشاد)"
	},
	{
		"value": "ar-TN",
		"label": "Arabic (Tunisia)",
		"name": "العربية (تونس)"
	},
	{
		"value": "ar-YE",
		"label": "Arabic (Yemen)",
		"name": "العربية (اليمن)"
	},
	{
		"value": "as",
		"label": "Assamese",
		"name": "অসমীয়া"
	},
	{
		"value": "as-IN",
		"label": "Assamese (India)",
		"name": "অসমীয়া (ভারত)"
	},
	{
		"value": "asa",
		"label": "Asu",
		"name": "Kipare"
	},
	{
		"value": "asa-TZ",
		"label": "Asu (Tanzania)",
		"name": "Kipare (Tadhania)"
	},
	{
		"value": "ast",
		"label": "Asturian",
		"name": "asturianu"
	},
	{
		"value": "ast-ES",
		"label": "Asturian (Spain)",
		"name": "asturianu (España)"
	},
	{
		"value": "az",
		"label": "Azerbaijani",
		"name": "azərbaycan"
	},
	{
		"value": "az-Cyrl",
		"label": "Azerbaijani (Cyrillic)",
		"name": "азәрбајҹан (Кирил)"
	},
	{
		"value": "az-Cyrl-AZ",
		"label": "Azerbaijani (Cyrillic",
		"name": " Azerbaijan)",
		"commonName": "азәрбајҹан (Кирил"
	},
	{
		"value": "az-Latn",
		"label": "Azerbaijani (Latin)",
		"name": "azərbaycan (latın)"
	},
	{
		"value": "az-Latn-AZ",
		"label": "Azerbaijani (Latin",
		"name": " Azerbaijan)",
		"commonName": "azərbaycan (latın"
	},
	{
		"value": "bas",
		"label": "Basaa",
		"name": "Ɓàsàa"
	},
	{
		"value": "bas-CM",
		"label": "Basaa (Cameroon)",
		"name": "Ɓàsàa (Kàmɛ̀rûn)"
	},
	{
		"value": "be",
		"label": "Belarusian",
		"name": "беларуская"
	},
	{
		"value": "be-BY",
		"label": "Belarusian (Belarus)",
		"name": "беларуская (Беларусь)"
	},
	{
		"value": "bem",
		"label": "Bemba",
		"name": "Ichibemba"
	},
	{
		"value": "bem-ZM",
		"label": "Bemba (Zambia)",
		"name": "Ichibemba (Zambia)"
	},
	{
		"value": "bez",
		"label": "Bena",
		"name": "Hibena"
	},
	{
		"value": "bez-TZ",
		"label": "Bena (Tanzania)",
		"name": "Hibena (Hutanzania)"
	},
	{
		"value": "bg",
		"label": "Bulgarian",
		"name": "български"
	},
	{
		"value": "bg-BG",
		"label": "Bulgarian (Bulgaria)",
		"name": "български (България)"
	},
	{
		"value": "bm",
		"label": "Bambara",
		"name": "bamanakan"
	},
	{
		"value": "bm-ML",
		"label": "Bambara (Mali)",
		"name": "bamanakan (Mali)"
	},
	{
		"value": "bn",
		"label": "Bangla",
		"name": "বাংলা"
	},
	{
		"value": "bn-BD",
		"label": "Bangla (Bangladesh)",
		"name": "বাংলা (বাংলাদেশ)"
	},
	{
		"value": "bn-IN",
		"label": "Bangla (India)",
		"name": "বাংলা (ভারত)"
	},
	{
		"value": "bo",
		"label": "Tibetan",
		"name": "བོད་སྐད་"
	},
	{
		"value": "bo-CN",
		"label": "Tibetan (China)",
		"name": "བོད་སྐད་ (རྒྱ་ནག)"
	},
	{
		"value": "bo-IN",
		"label": "Tibetan (India)",
		"name": "བོད་སྐད་ (རྒྱ་གར་)"
	},
	{
		"value": "br",
		"label": "Breton",
		"name": "brezhoneg"
	},
	{
		"value": "br-FR",
		"label": "Breton (France)",
		"name": "brezhoneg (Frañs)"
	},
	{
		"value": "brx",
		"label": "Bodo",
		"name": "बड़ो"
	},
	{
		"value": "brx-IN",
		"label": "Bodo (India)",
		"name": "बड़ो (भारत)"
	},
	{
		"value": "bs",
		"label": "Bosnian",
		"name": "bosanski"
	},
	{
		"value": "bs-Cyrl",
		"label": "Bosnian (Cyrillic)",
		"name": "босански (ћирилица)"
	},
	{
		"value": "bs-Cyrl-BA",
		"label": "Bosnian (Cyrillic",
		"name": " Bosnia & Herzegovina)",
		"commonName": "босански (ћирилица"
	},
	{
		"value": "bs-Latn",
		"label": "Bosnian (Latin)",
		"name": "bosanski (latinica)"
	},
	{
		"value": "bs-Latn-BA",
		"label": "Bosnian (Latin",
		"name": " Bosnia & Herzegovina)",
		"commonName": "bosanski (latinica"
	},
	{
		"value": "ca",
		"label": "Catalan",
		"name": "català"
	},
	{
		"value": "ca-AD",
		"label": "Catalan (Andorra)",
		"name": "català (Andorra)"
	},
	{
		"value": "ca-ES",
		"label": "Catalan (Spain)",
		"name": "català (Espanya)"
	},
	{
		"value": "ca-FR",
		"label": "Catalan (France)",
		"name": "català (França)"
	},
	{
		"value": "ca-IT",
		"label": "Catalan (Italy)",
		"name": "català (Itàlia)"
	},
	{
		"value": "ccp",
		"label": "Chakma",
		"name": "𑄌𑄋𑄴𑄟𑄳𑄦"
	},
	{
		"value": "ccp-BD",
		"label": "Chakma (Bangladesh)",
		"name": "𑄌𑄋𑄴𑄟𑄳𑄦 (𑄝𑄁𑄣𑄘𑄬𑄌𑄴)"
	},
	{
		"value": "ccp-IN",
		"label": "Chakma (India)",
		"name": "𑄌𑄋𑄴𑄟𑄳𑄦 (𑄞𑄢𑄧𑄖𑄴)"
	},
	{
		"value": "ce",
		"label": "Chechen",
		"name": "нохчийн"
	},
	{
		"value": "ce-RU",
		"label": "Chechen (Russia)",
		"name": "нохчийн (Росси)"
	},
	{
		"value": "cgg",
		"label": "Chiga",
		"name": "Rukiga"
	},
	{
		"value": "cgg-UG",
		"label": "Chiga (Uganda)",
		"name": "Rukiga (Uganda)"
	},
	{
		"value": "chr",
		"label": "Cherokee",
		"name": "ᏣᎳᎩ"
	},
	{
		"value": "chr-US",
		"label": "Cherokee (United States)",
		"name": "ᏣᎳᎩ (ᏌᏊ ᎢᏳᎾᎵᏍᏔᏅ ᏍᎦᏚᎩ)"
	},
	{
		"value": "ckb",
		"label": "Central Kurdish",
		"name": "کوردیی ناوەندی"
	},
	{
		"value": "ckb-IQ",
		"label": "Central Kurdish (Iraq)",
		"name": "کوردیی ناوەندی (عێراق)"
	},
	{
		"value": "ckb-IR",
		"label": "Central Kurdish (Iran)",
		"name": "کوردیی ناوەندی (ئێران)"
	},
	{
		"value": "cs",
		"label": "Czech",
		"name": "čeština"
	},
	{
		"value": "cs-CZ",
		"label": "Czech (Czechia)",
		"name": "čeština (Česko)"
	},
	{
		"value": "cy",
		"label": "Welsh",
		"name": "Cymraeg",
		"commonName": ""
	},
	{
		"value": "cy-GB",
		"label": "Welsh (United Kingdom)",
		"name": "Cymraeg (Y Deyrnas Unedig)",
		"commonName": ""
	},
	{
		"value": "da",
		"label": "Danish",
		"name": "dansk",
		"commonName": ""
	},
	{
		"value": "da-DK",
		"label": "Danish (Denmark)",
		"name": "dansk (Danmark)",
		"commonName": ""
	},
	{
		"value": "da-GL",
		"label": "Danish (Greenland)",
		"name": "dansk (Grønland)",
		"commonName": ""
	},
	{
		"value": "dav",
		"label": "Taita",
		"name": "Kitaita",
		"commonName": ""
	},
	{
		"value": "dav-KE",
		"label": "Taita (Kenya)",
		"name": "Kitaita (Kenya)",
		"commonName": ""
	},
	{
		"value": "de",
		"label": "German",
		"name": "Deutsch",
		"commonName": ""
	},
	{
		"value": "de-AT",
		"label": "German (Austria)",
		"name": "Deutsch (Österreich)",
		"commonName": "Austrian German"
	},
	{
		"value": "de-BE",
		"label": "German (Belgium)",
		"name": "Deutsch (Belgien)",
		"commonName": ""
	},
	{
		"value": "de-CH",
		"label": "German (Switzerland)",
		"name": "Deutsch (Schweiz)",
		"commonName": "Swiss High German"
	},
	{
		"value": "de-DE",
		"label": "German (Germany)",
		"name": "Deutsch (Deutschland)",
		"commonName": ""
	},
	{
		"value": "de-IT",
		"label": "German (Italy)",
		"name": "Deutsch (Italien)",
		"commonName": ""
	},
	{
		"value": "de-LI",
		"label": "German (Liechtenstein)",
		"name": "Deutsch (Liechtenstein)",
		"commonName": ""
	},
	{
		"value": "de-LU",
		"label": "German (Luxembourg)",
		"name": "Deutsch (Luxemburg)",
		"commonName": ""
	},
	{
		"value": "dje",
		"label": "Zarma",
		"name": "Zarmaciine",
		"commonName": ""
	},
	{
		"value": "dje-NE",
		"label": "Zarma (Niger)",
		"name": "Zarmaciine (Nižer)"
	},
	{
		"value": "dsb",
		"label": "Lower Sorbian",
		"name": "dolnoserbšćina"
	},
	{
		"value": "dsb-DE",
		"label": "Lower Sorbian (Germany)",
		"name": "dolnoserbšćina (Nimska)"
	},
	{
		"value": "dua",
		"label": "Duala",
		"name": "duálá"
	},
	{
		"value": "dua-CM",
		"label": "Duala (Cameroon)",
		"name": "duálá (Cameroun)"
	},
	{
		"value": "dyo",
		"label": "Jola-Fonyi",
		"name": "joola"
	},
	{
		"value": "dyo-SN",
		"label": "Jola-Fonyi (Senegal)",
		"name": "joola (Senegal)"
	},
	{
		"value": "dz",
		"label": "Dzongkha",
		"name": "རྫོང་ཁ"
	},
	{
		"value": "dz-BT",
		"label": "Dzongkha (Bhutan)",
		"name": "རྫོང་ཁ། (འབྲུག།)"
	},
	{
		"value": "ebu",
		"label": "Embu",
		"name": "Kĩembu"
	},
	{
		"value": "ebu-KE",
		"label": "Embu (Kenya)",
		"name": "Kĩembu (Kenya)"
	},
	{
		"value": "ee",
		"label": "Ewe",
		"name": "Eʋegbe"
	},
	{
		"value": "ee-GH",
		"label": "Ewe (Ghana)",
		"name": "Eʋegbe (Ghana nutome)"
	},
	{
		"value": "ee-TG",
		"label": "Ewe (Togo)",
		"name": "Eʋegbe (Togo nutome)"
	},
	{
		"value": "el",
		"label": "Greek",
		"name": "Ελληνικά"
	},
	{
		"value": "el-CY",
		"label": "Greek (Cyprus)",
		"name": "Ελληνικά (Κύπρος)"
	},
	{
		"value": "el-GR",
		"label": "Greek (Greece)",
		"name": "Ελληνικά (Ελλάδα)",
		"commonName": ""
	},
	{
		"value": "en",
		"label": "English",
		"name": "English",
		"commonName": ""
	},
	{
		"value": "en-001",
		"label": "English (World)",
		"name": "English (World)",
		"commonName": ""
	},
	{
		"value": "en-150",
		"label": "English (Europe)",
		"name": "English (Europe)",
		"commonName": ""
	},
	{
		"value": "en-AG",
		"label": "English (Antigua & Barbuda)",
		"name": "English (Antigua & Barbuda)",
		"commonName": ""
	},
	{
		"value": "en-AI",
		"label": "English (Anguilla)",
		"name": "English (Anguilla)",
		"commonName": ""
	},
	{
		"value": "en-AS",
		"label": "English (American Samoa)",
		"name": "English (American Samoa)",
		"commonName": ""
	},
	{
		"value": "en-AT",
		"label": "English (Austria)",
		"name": "English (Austria)",
		"commonName": ""
	},
	{
		"value": "en-AU",
		"label": "English (Australia)",
		"name": "English (Australia)",
		"commonName": "Australian English"
	},
	{
		"value": "en-BB",
		"label": "English (Barbados)",
		"name": "English (Barbados)",
		"commonName": ""
	},
	{
		"value": "en-BE",
		"label": "English (Belgium)",
		"name": "English (Belgium)",
		"commonName": ""
	},
	{
		"value": "en-BI",
		"label": "English (Burundi)",
		"name": "English (Burundi)",
		"commonName": ""
	},
	{
		"value": "en-BM",
		"label": "English (Bermuda)",
		"name": "English (Bermuda)",
		"commonName": ""
	},
	{
		"value": "en-BS",
		"label": "English (Bahamas)",
		"name": "English (Bahamas)",
		"commonName": ""
	},
	{
		"value": "en-BW",
		"label": "English (Botswana)",
		"name": "English (Botswana)",
		"commonName": ""
	},
	{
		"value": "en-BZ",
		"label": "English (Belize)",
		"name": "English (Belize)",
		"commonName": ""
	},
	{
		"value": "en-CA",
		"label": "English (Canada)",
		"name": "English (Canada)",
		"commonName": "Canadian English"
	},
	{
		"value": "en-CC",
		"label": "English (Cocos [Keeling] Islands)",
		"name": "English (Cocos [Keeling] Islands)",
		"commonName": ""
	},
	{
		"value": "en-CH",
		"label": "English (Switzerland)",
		"name": "English (Switzerland)",
		"commonName": ""
	},
	{
		"value": "en-CK",
		"label": "English (Cook Islands)",
		"name": "English (Cook Islands)",
		"commonName": ""
	},
	{
		"value": "en-CM",
		"label": "English (Cameroon)",
		"name": "English (Cameroon)",
		"commonName": ""
	},
	{
		"value": "en-CX",
		"label": "English (Christmas Island)",
		"name": "English (Christmas Island)",
		"commonName": ""
	},
	{
		"value": "en-CY",
		"label": "English (Cyprus)",
		"name": "English (Cyprus)",
		"commonName": ""
	},
	{
		"value": "en-DE",
		"label": "English (Germany)",
		"name": "English (Germany)",
		"commonName": ""
	},
	{
		"value": "en-DG",
		"label": "English (Diego Garcia)",
		"name": "English (Diego Garcia)",
		"commonName": ""
	},
	{
		"value": "en-DK",
		"label": "English (Denmark)",
		"name": "English (Denmark)",
		"commonName": ""
	},
	{
		"value": "en-DM",
		"label": "English (Dominica)",
		"name": "English (Dominica)",
		"commonName": ""
	},
	{
		"value": "en-ER",
		"label": "English (Eritrea)",
		"name": "English (Eritrea)",
		"commonName": ""
	},
	{
		"value": "en-FI",
		"label": "English (Finland)",
		"name": "English (Finland)",
		"commonName": ""
	},
	{
		"value": "en-FJ",
		"label": "English (Fiji)",
		"name": "English (Fiji)",
		"commonName": ""
	},
	{
		"value": "en-FK",
		"label": "English (Falkland Islands)",
		"name": "English (Falkland Islands)",
		"commonName": ""
	},
	{
		"value": "en-FM",
		"label": "English (Micronesia)",
		"name": "English (Micronesia)",
		"commonName": ""
	},
	{
		"value": "en-GB",
		"label": "English (United Kingdom)",
		"name": "English (United Kingdom)",
		"commonName": "British English"
	},
	{
		"value": "en-GD",
		"label": "English (Grenada)",
		"name": "English (Grenada)",
		"commonName": ""
	},
	{
		"value": "en-GG",
		"label": "English (Guernsey)",
		"name": "English (Guernsey)",
		"commonName": ""
	},
	{
		"value": "en-GH",
		"label": "English (Ghana)",
		"name": "English (Ghana)",
		"commonName": ""
	},
	{
		"value": "en-GI",
		"label": "English (Gibraltar)",
		"name": "English (Gibraltar)",
		"commonName": ""
	},
	{
		"value": "en-GM",
		"label": "English (Gambia)",
		"name": "English (Gambia)",
		"commonName": ""
	},
	{
		"value": "en-GU",
		"label": "English (Guam)",
		"name": "English (Guam)",
		"commonName": ""
	},
	{
		"value": "en-GY",
		"label": "English (Guyana)",
		"name": "English (Guyana)",
		"commonName": ""
	},
	{
		"value": "en-HK",
		"label": "English (Hong Kong SAR China)",
		"name": "English (Hong Kong SAR China)",
		"commonName": ""
	},
	{
		"value": "en-IE",
		"label": "English (Ireland)",
		"name": "English (Ireland)",
		"commonName": ""
	},
	{
		"value": "en-IL",
		"label": "English (Israel)",
		"name": "English (Israel)",
		"commonName": ""
	},
	{
		"value": "en-IM",
		"label": "English (Isle of Man)",
		"name": "English (Isle of Man)",
		"commonName": ""
	},
	{
		"value": "en-IN",
		"label": "English (India)",
		"name": "English (India)",
		"commonName": ""
	},
	{
		"value": "en-IO",
		"label": "English (British Indian Ocean Territory)",
		"name": "English (British Indian Ocean Territory)",
		"commonName": ""
	},
	{
		"value": "en-JE",
		"label": "English (Jersey)",
		"name": "English (Jersey)",
		"commonName": ""
	},
	{
		"value": "en-JM",
		"label": "English (Jamaica)",
		"name": "English (Jamaica)",
		"commonName": ""
	},
	{
		"value": "en-KE",
		"label": "English (Kenya)",
		"name": "English (Kenya)"
	},
	{
		"value": "en-KI",
		"label": "English (Kiribati)",
		"name": "English (Kiribati)"
	},
	{
		"value": "en-KN",
		"label": "English (St. Kitts & Nevis)",
		"name": "English (St. Kitts & Nevis)"
	},
	{
		"value": "en-KY",
		"label": "English (Cayman Islands)",
		"name": "English (Cayman Islands)"
	},
	{
		"value": "en-LC",
		"label": "English (St. Lucia)",
		"name": "English (St. Lucia)"
	},
	{
		"value": "en-LR",
		"label": "English (Liberia)",
		"name": "English (Liberia)"
	},
	{
		"value": "en-LS",
		"label": "English (Lesotho)",
		"name": "English (Lesotho)"
	},
	{
		"value": "en-MG",
		"label": "English (Madagascar)",
		"name": "English (Madagascar)"
	},
	{
		"value": "en-MH",
		"label": "English (Marshall Islands)",
		"name": "English (Marshall Islands)"
	},
	{
		"value": "en-MO",
		"label": "English (Macau SAR China)",
		"name": "English (Macau SAR China)"
	},
	{
		"value": "en-MP",
		"label": "English (Northern Mariana Islands)",
		"name": "English (Northern Mariana Islands)"
	},
	{
		"value": "en-MS",
		"label": "English (Montserrat)",
		"name": "English (Montserrat)"
	},
	{
		"value": "en-MT",
		"label": "English (Malta)",
		"name": "English (Malta)"
	},
	{
		"value": "en-MU",
		"label": "English (Mauritius)",
		"name": "English (Mauritius)"
	},
	{
		"value": "en-MW",
		"label": "English (Malawi)",
		"name": "English (Malawi)"
	},
	{
		"value": "en-MY",
		"label": "English (Malaysia)",
		"name": "English (Malaysia)"
	},
	{
		"value": "en-NA",
		"label": "English (Namibia)",
		"name": "English (Namibia)"
	},
	{
		"value": "en-NF",
		"label": "English (Norfolk Island)",
		"name": "English (Norfolk Island)"
	},
	{
		"value": "en-NG",
		"label": "English (Nigeria)",
		"name": "English (Nigeria)"
	},
	{
		"value": "en-NL",
		"label": "English (Netherlands)",
		"name": "English (Netherlands)"
	},
	{
		"value": "en-NR",
		"label": "English (Nauru)",
		"name": "English (Nauru)"
	},
	{
		"value": "en-NU",
		"label": "English (Niue)",
		"name": "English (Niue)"
	},
	{
		"value": "en-NZ",
		"label": "English (New Zealand)",
		"name": "English (New Zealand)"
	},
	{
		"value": "en-PG",
		"label": "English (Papua New Guinea)",
		"name": "English (Papua New Guinea)"
	},
	{
		"value": "en-PH",
		"label": "English (Philippines)",
		"name": "English (Philippines)"
	},
	{
		"value": "en-PK",
		"label": "English (Pakistan)",
		"name": "English (Pakistan)"
	},
	{
		"value": "en-PN",
		"label": "English (Pitcairn Islands)",
		"name": "English (Pitcairn Islands)"
	},
	{
		"value": "en-PR",
		"label": "English (Puerto Rico)",
		"name": "English (Puerto Rico)"
	},
	{
		"value": "en-PW",
		"label": "English (Palau)",
		"name": "English (Palau)"
	},
	{
		"value": "en-RW",
		"label": "English (Rwanda)",
		"name": "English (Rwanda)"
	},
	{
		"value": "en-SB",
		"label": "English (Solomon Islands)",
		"name": "English (Solomon Islands)"
	},
	{
		"value": "en-SC",
		"label": "English (Seychelles)",
		"name": "English (Seychelles)"
	},
	{
		"value": "en-SD",
		"label": "English (Sudan)",
		"name": "English (Sudan)"
	},
	{
		"value": "en-SE",
		"label": "English (Sweden)",
		"name": "English (Sweden)"
	},
	{
		"value": "en-SG",
		"label": "English (Singapore)",
		"name": "English (Singapore)"
	},
	{
		"value": "en-SH",
		"label": "English (St. Helena)",
		"name": "English (St. Helena)"
	},
	{
		"value": "en-SI",
		"label": "English (Slovenia)",
		"name": "English (Slovenia)"
	},
	{
		"value": "en-SL",
		"label": "English (Sierra Leone)",
		"name": "English (Sierra Leone)"
	},
	{
		"value": "en-SS",
		"label": "English (South Sudan)",
		"name": "English (South Sudan)"
	},
	{
		"value": "en-SX",
		"label": "English (Sint Maarten)",
		"name": "English (Sint Maarten)"
	},
	{
		"value": "en-SZ",
		"label": "English (Swaziland)",
		"name": "English (Swaziland)"
	},
	{
		"value": "en-TC",
		"label": "English (Turks & Caicos Islands)",
		"name": "English (Turks & Caicos Islands)"
	},
	{
		"value": "en-TK",
		"label": "English (Tokelau)",
		"name": "English (Tokelau)"
	},
	{
		"value": "en-TO",
		"label": "English (Tonga)",
		"name": "English (Tonga)"
	},
	{
		"value": "en-TT",
		"label": "English (Trinidad & Tobago)",
		"name": "English (Trinidad & Tobago)"
	},
	{
		"value": "en-TV",
		"label": "English (Tuvalu)",
		"name": "English (Tuvalu)"
	},
	{
		"value": "en-TZ",
		"label": "English (Tanzania)",
		"name": "English (Tanzania)"
	},
	{
		"value": "en-UG",
		"label": "English (Uganda)",
		"name": "English (Uganda)"
	},
	{
		"value": "en-UM",
		"label": "English (U.S. Outlying Islands)",
		"name": "English (U.S. Outlying Islands)",
		"commonName": ""
	},
	{
		"value": "en-US",
		"label": "English (United States)",
		"name": "English (United States)",
		"commonName": "American English"
	},
	{
		"value": "en-US-POSIX",
		"label": "English (United States",
		"name": " Computer)",
		"commonName": "English (United States"
	},
	{
		"value": "en-VC",
		"label": "English (St. Vincent & Grenadines)",
		"name": "English (St. Vincent & Grenadines)",
		"commonName": ""
	},
	{
		"value": "en-VG",
		"label": "English (British Virgin Islands)",
		"name": "English (British Virgin Islands)",
		"commonName": ""
	},
	{
		"value": "en-VI",
		"label": "English (U.S. Virgin Islands)",
		"name": "English (U.S. Virgin Islands)",
		"commonName": ""
	},
	{
		"value": "en-VU",
		"label": "English (Vanuatu)",
		"name": "English (Vanuatu)",
		"commonName": ""
	},
	{
		"value": "en-WS",
		"label": "English (Samoa)",
		"name": "English (Samoa)",
		"commonName": ""
	},
	{
		"value": "en-ZA",
		"label": "English (South Africa)",
		"name": "English (South Africa)",
		"commonName": ""
	},
	{
		"value": "en-ZM",
		"label": "English (Zambia)",
		"name": "English (Zambia)",
		"commonName": ""
	},
	{
		"value": "en-ZW",
		"label": "English (Zimbabwe)",
		"name": "English (Zimbabwe)",
		"commonName": ""
	},
	{
		"value": "eo",
		"label": "Esperanto",
		"name": "esperanto",
		"commonName": ""
	},
	{
		"value": "es",
		"label": "Spanish",
		"name": "español",
		"commonName": ""
	},
	{
		"value": "es-419",
		"label": "Spanish (Latin America)",
		"name": "español (Latinoamérica)",
		"commonName": "Latin American Spanish"
	},
	{
		"value": "es-AR",
		"label": "Spanish (Argentina)",
		"name": "español (Argentina)",
		"commonName": ""
	},
	{
		"value": "es-BO",
		"label": "Spanish (Bolivia)",
		"name": "español (Bolivia)",
		"commonName": ""
	},
	{
		"value": "es-BR",
		"label": "Spanish (Brazil)",
		"name": "español (Brasil)",
		"commonName": ""
	},
	{
		"value": "es-BZ",
		"label": "Spanish (Belize)",
		"name": "español (Belice)",
		"commonName": ""
	},
	{
		"value": "es-CL",
		"label": "Spanish (Chile)",
		"name": "español (Chile)",
		"commonName": ""
	},
	{
		"value": "es-CO",
		"label": "Spanish (Colombia)",
		"name": "español (Colombia)",
		"commonName": ""
	},
	{
		"value": "es-CR",
		"label": "Spanish (Costa Rica)",
		"name": "español (Costa Rica)",
		"commonName": ""
	},
	{
		"value": "es-CU",
		"label": "Spanish (Cuba)",
		"name": "español (Cuba)",
		"commonName": ""
	},
	{
		"value": "es-DO",
		"label": "Spanish (Dominican Republic)",
		"name": "español (República Dominicana)",
		"commonName": ""
	},
	{
		"value": "es-EA",
		"label": "Spanish (Ceuta & Melilla)",
		"name": "español (Ceuta y Melilla)",
		"commonName": ""
	},
	{
		"value": "es-EC",
		"label": "Spanish (Ecuador)",
		"name": "español (Ecuador)",
		"commonName": ""
	},
	{
		"value": "es-ES",
		"label": "Spanish (Spain)",
		"name": "español (España)",
		"commonName": "European Spanish"
	},
	{
		"value": "es-GQ",
		"label": "Spanish (Equatorial Guinea)",
		"name": "español (Guinea Ecuatorial)",
		"commonName": ""
	},
	{
		"value": "es-GT",
		"label": "Spanish (Guatemala)",
		"name": "español (Guatemala)",
		"commonName": ""
	},
	{
		"value": "es-HN",
		"label": "Spanish (Honduras)",
		"name": "español (Honduras)",
		"commonName": ""
	},
	{
		"value": "es-IC",
		"label": "Spanish (Canary Islands)",
		"name": "español (Canarias)",
		"commonName": ""
	},
	{
		"value": "es-MX",
		"label": "Spanish (Mexico)",
		"name": "español (México)",
		"commonName": "Mexican Spanish"
	},
	{
		"value": "es-NI",
		"label": "Spanish (Nicaragua)",
		"name": "español (Nicaragua)",
		"commonName": ""
	},
	{
		"value": "es-PA",
		"label": "Spanish (Panama)",
		"name": "español (Panamá)"
	},
	{
		"value": "es-PE",
		"label": "Spanish (Peru)",
		"name": "español (Perú)"
	},
	{
		"value": "es-PH",
		"label": "Spanish (Philippines)",
		"name": "español (Filipinas)"
	},
	{
		"value": "es-PR",
		"label": "Spanish (Puerto Rico)",
		"name": "español (Puerto Rico)"
	},
	{
		"value": "es-PY",
		"label": "Spanish (Paraguay)",
		"name": "español (Paraguay)"
	},
	{
		"value": "es-SV",
		"label": "Spanish (El Salvador)",
		"name": "español (El Salvador)"
	},
	{
		"value": "es-US",
		"label": "Spanish (United States)",
		"name": "español (Estados Unidos)"
	},
	{
		"value": "es-UY",
		"label": "Spanish (Uruguay)",
		"name": "español (Uruguay)"
	},
	{
		"value": "es-VE",
		"label": "Spanish (Venezuela)",
		"name": "español (Venezuela)"
	},
	{
		"value": "et",
		"label": "Estonian",
		"name": "eesti"
	},
	{
		"value": "et-EE",
		"label": "Estonian (Estonia)",
		"name": "eesti (Eesti)"
	},
	{
		"value": "eu",
		"label": "Basque",
		"name": "euskara"
	},
	{
		"value": "eu-ES",
		"label": "Basque (Spain)",
		"name": "euskara (Espainia)"
	},
	{
		"value": "ewo",
		"label": "Ewondo",
		"name": "ewondo"
	},
	{
		"value": "ewo-CM",
		"label": "Ewondo (Cameroon)",
		"name": "ewondo (Kamərún)"
	},
	{
		"value": "fa",
		"label": "Persian",
		"name": "فارسی"
	},
	{
		"value": "fa-AF",
		"label": "Persian (Afghanistan)",
		"name": "فارسی (افغانستان)",
		"commonName": "Dari"
	},
	{
		"value": "fa-IR",
		"label": "Persian (Iran)",
		"name": "فارسی (ایران)",
		"commonName": ""
	},
	{
		"value": "ff",
		"label": "Fulah",
		"name": "Pulaar",
		"commonName": ""
	},
	{
		"value": "ff-CM",
		"label": "Fulah (Cameroon)",
		"name": "Pulaar (Kameruun)",
		"commonName": ""
	},
	{
		"value": "ff-GN",
		"label": "Fulah (Guinea)",
		"name": "Pulaar (Gine)",
		"commonName": ""
	},
	{
		"value": "ff-MR",
		"label": "Fulah (Mauritania)",
		"name": "Pulaar (Muritani)",
		"commonName": ""
	},
	{
		"value": "ff-SN",
		"label": "Fulah (Senegal)",
		"name": "Pulaar (Senegaal)",
		"commonName": ""
	},
	{
		"value": "fi",
		"label": "Finnish",
		"name": "suomi",
		"commonName": ""
	},
	{
		"value": "fi-FI",
		"label": "Finnish (Finland)",
		"name": "suomi (Suomi)",
		"commonName": ""
	},
	{
		"value": "fil",
		"label": "Filipino",
		"name": "Filipino",
		"commonName": ""
	},
	{
		"value": "fil-PH",
		"label": "Filipino (Philippines)",
		"name": "Filipino (Pilipinas)",
		"commonName": ""
	},
	{
		"value": "fo",
		"label": "Faroese",
		"name": "føroyskt",
		"commonName": ""
	},
	{
		"value": "fo-DK",
		"label": "Faroese (Denmark)",
		"name": "føroyskt (Danmark)",
		"commonName": ""
	},
	{
		"value": "fo-FO",
		"label": "Faroese (Faroe Islands)",
		"name": "føroyskt (Føroyar)",
		"commonName": ""
	},
	{
		"value": "fr",
		"label": "French",
		"name": "français",
		"commonName": ""
	},
	{
		"value": "fr-BE",
		"label": "French (Belgium)",
		"name": "français (Belgique)",
		"commonName": ""
	},
	{
		"value": "fr-BF",
		"label": "French (Burkina Faso)",
		"name": "français (Burkina Faso)",
		"commonName": ""
	},
	{
		"value": "fr-BI",
		"label": "French (Burundi)",
		"name": "français (Burundi)",
		"commonName": ""
	},
	{
		"value": "fr-BJ",
		"label": "French (Benin)",
		"name": "français (Bénin)",
		"commonName": ""
	},
	{
		"value": "fr-BL",
		"label": "French (St. Barthélemy)",
		"name": "français (Saint-Barthélemy)",
		"commonName": ""
	},
	{
		"value": "fr-CA",
		"label": "French (Canada)",
		"name": "français (Canada)",
		"commonName": "Canadian French"
	},
	{
		"value": "fr-CD",
		"label": "French (Congo - Kinshasa)",
		"name": "français (Congo-Kinshasa)",
		"commonName": ""
	},
	{
		"value": "fr-CF",
		"label": "French (Central African Republic)",
		"name": "français (République centrafricaine)",
		"commonName": ""
	},
	{
		"value": "fr-CG",
		"label": "French (Congo - Brazzaville)",
		"name": "français (Congo-Brazzaville)",
		"commonName": ""
	},
	{
		"value": "fr-CH",
		"label": "French (Switzerland)",
		"name": "français (Suisse)",
		"commonName": "Swiss French"
	},
	{
		"value": "fr-CI",
		"label": "French (Côte d’Ivoire)",
		"name": "français (Côte d’Ivoire)",
		"commonName": ""
	},
	{
		"value": "fr-CM",
		"label": "French (Cameroon)",
		"name": "français (Cameroun)",
		"commonName": ""
	},
	{
		"value": "fr-DJ",
		"label": "French (Djibouti)",
		"name": "français (Djibouti)",
		"commonName": ""
	},
	{
		"value": "fr-DZ",
		"label": "French (Algeria)",
		"name": "français (Algérie)",
		"commonName": ""
	},
	{
		"value": "fr-FR",
		"label": "French (France)",
		"name": "français (France)",
		"commonName": ""
	},
	{
		"value": "fr-GA",
		"label": "French (Gabon)",
		"name": "français (Gabon)",
		"commonName": ""
	},
	{
		"value": "fr-GF",
		"label": "French (French Guiana)",
		"name": "français (Guyane française)",
		"commonName": ""
	},
	{
		"value": "fr-GN",
		"label": "French (Guinea)",
		"name": "français (Guinée)"
	},
	{
		"value": "fr-GP",
		"label": "French (Guadeloupe)",
		"name": "français (Guadeloupe)"
	},
	{
		"value": "fr-GQ",
		"label": "French (Equatorial Guinea)",
		"name": "français (Guinée équatoriale)"
	},
	{
		"value": "fr-HT",
		"label": "French (Haiti)",
		"name": "français (Haïti)"
	},
	{
		"value": "fr-KM",
		"label": "French (Comoros)",
		"name": "français (Comores)"
	},
	{
		"value": "fr-LU",
		"label": "French (Luxembourg)",
		"name": "français (Luxembourg)"
	},
	{
		"value": "fr-MA",
		"label": "French (Morocco)",
		"name": "français (Maroc)"
	},
	{
		"value": "fr-MC",
		"label": "French (Monaco)",
		"name": "français (Monaco)"
	},
	{
		"value": "fr-MF",
		"label": "French (St. Martin)",
		"name": "français (Saint-Martin)"
	},
	{
		"value": "fr-MG",
		"label": "French (Madagascar)",
		"name": "français (Madagascar)"
	},
	{
		"value": "fr-ML",
		"label": "French (Mali)",
		"name": "français (Mali)"
	},
	{
		"value": "fr-MQ",
		"label": "French (Martinique)",
		"name": "français (Martinique)"
	},
	{
		"value": "fr-MR",
		"label": "French (Mauritania)",
		"name": "français (Mauritanie)"
	},
	{
		"value": "fr-MU",
		"label": "French (Mauritius)",
		"name": "français (Maurice)"
	},
	{
		"value": "fr-NC",
		"label": "French (New Caledonia)",
		"name": "français (Nouvelle-Calédonie)"
	},
	{
		"value": "fr-NE",
		"label": "French (Niger)",
		"name": "français (Niger)"
	},
	{
		"value": "fr-PF",
		"label": "French (French Polynesia)",
		"name": "français (Polynésie française)"
	},
	{
		"value": "fr-PM",
		"label": "French (St. Pierre & Miquelon)",
		"name": "français (Saint-Pierre-et-Miquelon)"
	},
	{
		"value": "fr-RE",
		"label": "French (Réunion)",
		"name": "français (La Réunion)"
	},
	{
		"value": "fr-RW",
		"label": "French (Rwanda)",
		"name": "français (Rwanda)"
	},
	{
		"value": "fr-SC",
		"label": "French (Seychelles)",
		"name": "français (Seychelles)"
	},
	{
		"value": "fr-SN",
		"label": "French (Senegal)",
		"name": "français (Sénégal)"
	},
	{
		"value": "fr-SY",
		"label": "French (Syria)",
		"name": "français (Syrie)"
	},
	{
		"value": "fr-TD",
		"label": "French (Chad)",
		"name": "français (Tchad)"
	},
	{
		"value": "fr-TG",
		"label": "French (Togo)",
		"name": "français (Togo)"
	},
	{
		"value": "fr-TN",
		"label": "French (Tunisia)",
		"name": "français (Tunisie)"
	},
	{
		"value": "fr-VU",
		"label": "French (Vanuatu)",
		"name": "français (Vanuatu)"
	},
	{
		"value": "fr-WF",
		"label": "French (Wallis & Futuna)",
		"name": "français (Wallis-et-Futuna)"
	},
	{
		"value": "fr-YT",
		"label": "French (Mayotte)",
		"name": "français (Mayotte)"
	},
	{
		"value": "fur",
		"label": "Friulian",
		"name": "furlan"
	},
	{
		"value": "fur-IT",
		"label": "Friulian (Italy)",
		"name": "furlan (Italie)"
	},
	{
		"value": "fy",
		"label": "Western Frisian",
		"name": "Frysk"
	},
	{
		"value": "fy-NL",
		"label": "Western Frisian (Netherlands)",
		"name": "Frysk (Nederlân)"
	},
	{
		"value": "ga",
		"label": "Irish",
		"name": "Gaeilge"
	},
	{
		"value": "ga-IE",
		"label": "Irish (Ireland)",
		"name": "Gaeilge (Éire)"
	},
	{
		"value": "gd",
		"label": "Scottish Gaelic",
		"name": "Gàidhlig"
	},
	{
		"value": "gd-GB",
		"label": "Scottish Gaelic (United Kingdom)",
		"name": "Gàidhlig (An Rìoghachd Aonaichte)"
	},
	{
		"value": "gl",
		"label": "Galician",
		"name": "galego"
	},
	{
		"value": "gl-ES",
		"label": "Galician (Spain)",
		"name": "galego (España)"
	},
	{
		"value": "gsw",
		"label": "Swiss German",
		"name": "Schwiizertüütsch"
	},
	{
		"value": "gsw-CH",
		"label": "Swiss German (Switzerland)",
		"name": "Schwiizertüütsch (Schwiiz)"
	},
	{
		"value": "gsw-FR",
		"label": "Swiss German (France)",
		"name": "Schwiizertüütsch (Frankriich)"
	},
	{
		"value": "gsw-LI",
		"label": "Swiss German (Liechtenstein)",
		"name": "Schwiizertüütsch (Liächteschtäi)"
	},
	{
		"value": "gu",
		"label": "Gujarati",
		"name": "ગુજરાતી"
	},
	{
		"value": "gu-IN",
		"label": "Gujarati (India)",
		"name": "ગુજરાતી (ભારત)"
	},
	{
		"value": "guz",
		"label": "Gusii",
		"name": "Ekegusii"
	},
	{
		"value": "guz-KE",
		"label": "Gusii (Kenya)",
		"name": "Ekegusii (Kenya)"
	},
	{
		"value": "gv",
		"label": "Manx",
		"name": "Gaelg"
	},
	{
		"value": "gv-IM",
		"label": "Manx (Isle of Man)",
		"name": "Gaelg (Ellan Vannin)"
	},
	{
		"value": "ha",
		"label": "Hausa",
		"name": "Hausa"
	},
	{
		"value": "ha-GH",
		"label": "Hausa (Ghana)",
		"name": "Hausa (Gana)"
	},
	{
		"value": "ha-NE",
		"label": "Hausa (Niger)",
		"name": "Hausa (Nijar)"
	},
	{
		"value": "ha-NG",
		"label": "Hausa (Nigeria)",
		"name": "Hausa (Najeriya)"
	},
	{
		"value": "haw",
		"label": "Hawaiian",
		"name": "ʻŌlelo Hawaiʻi"
	},
	{
		"value": "haw-US",
		"label": "Hawaiian (United States)",
		"name": "ʻŌlelo Hawaiʻi (ʻAmelika Hui Pū ʻIa)"
	},
	{
		"value": "he",
		"label": "Hebrew",
		"name": "עברית"
	},
	{
		"value": "he-IL",
		"label": "Hebrew (Israel)",
		"name": "עברית (ישראל)"
	},
	{
		"value": "hi",
		"label": "Hindi",
		"name": "हिन्दी"
	},
	{
		"value": "hi-IN",
		"label": "Hindi (India)",
		"name": "हिन्दी (भारत)"
	},
	{
		"value": "hr",
		"label": "Croatian",
		"name": "hrvatski"
	},
	{
		"value": "hr-BA",
		"label": "Croatian (Bosnia & Herzegovina)",
		"name": "hrvatski (Bosna i Hercegovina)"
	},
	{
		"value": "hr-HR",
		"label": "Croatian (Croatia)",
		"name": "hrvatski (Hrvatska)"
	},
	{
		"value": "hsb",
		"label": "Upper Sorbian",
		"name": "hornjoserbšćina"
	},
	{
		"value": "hsb-DE",
		"label": "Upper Sorbian (Germany)",
		"name": "hornjoserbšćina (Němska)"
	},
	{
		"value": "hu",
		"label": "Hungarian",
		"name": "magyar"
	},
	{
		"value": "hu-HU",
		"label": "Hungarian (Hungary)",
		"name": "magyar (Magyarország)"
	},
	{
		"value": "hy",
		"label": "Armenian",
		"name": "հայերեն"
	},
	{
		"value": "hy-AM",
		"label": "Armenian (Armenia)",
		"name": "հայերեն (Հայաստան)"
	},
	{
		"value": "id",
		"label": "Indonesian",
		"name": "Indonesia"
	},
	{
		"value": "id-ID",
		"label": "Indonesian (Indonesia)",
		"name": "Indonesia (Indonesia)"
	},
	{
		"value": "ig",
		"label": "Igbo",
		"name": "Igbo"
	},
	{
		"value": "ig-NG",
		"label": "Igbo (Nigeria)",
		"name": "Igbo (Naịjịrịa)"
	},
	{
		"value": "ii",
		"label": "Sichuan Yi",
		"name": "ꆈꌠꉙ"
	},
	{
		"value": "ii-CN",
		"label": "Sichuan Yi (China)",
		"name": "ꆈꌠꉙ (ꍏꇩ)"
	},
	{
		"value": "is",
		"label": "Icelandic",
		"name": "íslenska"
	},
	{
		"value": "is-IS",
		"label": "Icelandic (Iceland)",
		"name": "íslenska (Ísland)"
	},
	{
		"value": "it",
		"label": "Italian",
		"name": "italiano"
	},
	{
		"value": "it-CH",
		"label": "Italian (Switzerland)",
		"name": "italiano (Svizzera)"
	},
	{
		"value": "it-IT",
		"label": "Italian (Italy)",
		"name": "italiano (Italia)"
	},
	{
		"value": "it-SM",
		"label": "Italian (San Marino)",
		"name": "italiano (San Marino)"
	},
	{
		"value": "it-VA",
		"label": "Italian (Vatican City)",
		"name": "italiano (Città del Vaticano)"
	},
	{
		"value": "ja",
		"label": "Japanese",
		"name": "日本語"
	},
	{
		"value": "ja-JP",
		"label": "Japanese (Japan)",
		"name": "日本語 (日本)"
	},
	{
		"value": "jgo",
		"label": "Ngomba",
		"name": "Ndaꞌa"
	},
	{
		"value": "jgo-CM",
		"label": "Ngomba (Cameroon)",
		"name": "Ndaꞌa (Kamɛlûn)"
	},
	{
		"value": "jmc",
		"label": "Machame",
		"name": "Kimachame"
	},
	{
		"value": "jmc-TZ",
		"label": "Machame (Tanzania)",
		"name": "Kimachame (Tanzania)"
	},
	{
		"value": "ka",
		"label": "Georgian",
		"name": "ქართული"
	},
	{
		"value": "ka-GE",
		"label": "Georgian (Georgia)",
		"name": "ქართული (საქართველო)"
	},
	{
		"value": "kab",
		"label": "Kabyle",
		"name": "Taqbaylit"
	},
	{
		"value": "kab-DZ",
		"label": "Kabyle (Algeria)",
		"name": "Taqbaylit (Lezzayer)"
	},
	{
		"value": "kam",
		"label": "Kamba",
		"name": "Kikamba"
	},
	{
		"value": "kam-KE",
		"label": "Kamba (Kenya)",
		"name": "Kikamba (Kenya)"
	},
	{
		"value": "kde",
		"label": "Makonde",
		"name": "Chimakonde"
	},
	{
		"value": "kde-TZ",
		"label": "Makonde (Tanzania)",
		"name": "Chimakonde (Tanzania)"
	},
	{
		"value": "kea",
		"label": "Kabuverdianu",
		"name": "kabuverdianu"
	},
	{
		"value": "kea-CV",
		"label": "Kabuverdianu (Cape Verde)",
		"name": "kabuverdianu (Kabu Verdi)"
	},
	{
		"value": "khq",
		"label": "Koyra Chiini",
		"name": "Koyra ciini"
	},
	{
		"value": "khq-ML",
		"label": "Koyra Chiini (Mali)",
		"name": "Koyra ciini (Maali)"
	},
	{
		"value": "ki",
		"label": "Kikuyu",
		"name": "Gikuyu"
	},
	{
		"value": "ki-KE",
		"label": "Kikuyu (Kenya)",
		"name": "Gikuyu (Kenya)"
	},
	{
		"value": "kk",
		"label": "Kazakh",
		"name": "қазақ тілі"
	},
	{
		"value": "kk-KZ",
		"label": "Kazakh (Kazakhstan)",
		"name": "қазақ тілі (Қазақстан)"
	},
	{
		"value": "kkj",
		"label": "Kako",
		"name": "kakɔ"
	},
	{
		"value": "kkj-CM",
		"label": "Kako (Cameroon)",
		"name": "kakɔ (Kamɛrun)"
	},
	{
		"value": "kl",
		"label": "Kalaallisut",
		"name": "kalaallisut"
	},
	{
		"value": "kl-GL",
		"label": "Kalaallisut (Greenland)",
		"name": "kalaallisut (Kalaallit Nunaat)"
	},
	{
		"value": "kln",
		"label": "Kalenjin",
		"name": "Kalenjin"
	},
	{
		"value": "kln-KE",
		"label": "Kalenjin (Kenya)",
		"name": "Kalenjin (Emetab Kenya)"
	},
	{
		"value": "km",
		"label": "Khmer",
		"name": "ខ្មែរ"
	},
	{
		"value": "km-KH",
		"label": "Khmer (Cambodia)",
		"name": "ខ្មែរ (កម្ពុជា)"
	},
	{
		"value": "kn",
		"label": "Kannada",
		"name": "ಕನ್ನಡ"
	},
	{
		"value": "kn-IN",
		"label": "Kannada (India)",
		"name": "ಕನ್ನಡ (ಭಾರತ)"
	},
	{
		"value": "ko",
		"label": "Korean",
		"name": "한국어"
	},
	{
		"value": "ko-KP",
		"label": "Korean (North Korea)",
		"name": "한국어(조선민주주의인민공화국)"
	},
	{
		"value": "ko-KR",
		"label": "Korean (South Korea)",
		"name": "한국어(대한민국)"
	},
	{
		"value": "kok",
		"label": "Konkani",
		"name": "कोंकणी"
	},
	{
		"value": "kok-IN",
		"label": "Konkani (India)",
		"name": "कोंकणी (भारत)"
	},
	{
		"value": "ks",
		"label": "Kashmiri",
		"name": "کٲشُر"
	},
	{
		"value": "ks-IN",
		"label": "Kashmiri (India)",
		"name": "کٲشُر (ہِنٛدوستان)"
	},
	{
		"value": "ksb",
		"label": "Shambala",
		"name": "Kishambaa"
	},
	{
		"value": "ksb-TZ",
		"label": "Shambala (Tanzania)",
		"name": "Kishambaa (Tanzania)"
	},
	{
		"value": "ksf",
		"label": "Bafia",
		"name": "rikpa"
	},
	{
		"value": "ksf-CM",
		"label": "Bafia (Cameroon)",
		"name": "rikpa (kamɛrún)"
	},
	{
		"value": "ksh",
		"label": "Colognian",
		"name": "Kölsch"
	},
	{
		"value": "ksh-DE",
		"label": "Colognian (Germany)",
		"name": "Kölsch en Doütschland"
	},
	{
		"value": "kw",
		"label": "Cornish",
		"name": "kernewek"
	},
	{
		"value": "kw-GB",
		"label": "Cornish (United Kingdom)",
		"name": "kernewek (Rywvaneth Unys)"
	},
	{
		"value": "ky",
		"label": "Kyrgyz",
		"name": "кыргызча"
	},
	{
		"value": "ky-KG",
		"label": "Kyrgyz (Kyrgyzstan)",
		"name": "кыргызча (Кыргызстан)"
	},
	{
		"value": "lag",
		"label": "Langi",
		"name": "Kɨlaangi"
	},
	{
		"value": "lag-TZ",
		"label": "Langi (Tanzania)",
		"name": "Kɨlaangi (Taansanía)"
	},
	{
		"value": "lb",
		"label": "Luxembourgish",
		"name": "Lëtzebuergesch"
	},
	{
		"value": "lb-LU",
		"label": "Luxembourgish (Luxembourg)",
		"name": "Lëtzebuergesch (Lëtzebuerg)"
	},
	{
		"value": "lg",
		"label": "Ganda",
		"name": "Luganda"
	},
	{
		"value": "lg-UG",
		"label": "Ganda (Uganda)",
		"name": "Luganda (Yuganda)"
	},
	{
		"value": "lkt",
		"label": "Lakota",
		"name": "Lakȟólʼiyapi"
	},
	{
		"value": "lkt-US",
		"label": "Lakota (United States)",
		"name": "Lakȟólʼiyapi (Mílahaŋska Tȟamákȟočhe)"
	},
	{
		"value": "ln",
		"label": "Lingala",
		"name": "lingála"
	},
	{
		"value": "ln-AO",
		"label": "Lingala (Angola)",
		"name": "lingála (Angóla)"
	},
	{
		"value": "ln-CD",
		"label": "Lingala (Congo - Kinshasa)",
		"name": "lingála (Republíki ya Kongó Demokratíki)"
	},
	{
		"value": "ln-CF",
		"label": "Lingala (Central African Republic)",
		"name": "lingála (Repibiki ya Afríka ya Káti)"
	},
	{
		"value": "ln-CG",
		"label": "Lingala (Congo - Brazzaville)",
		"name": "lingála (Kongo)"
	},
	{
		"value": "lo",
		"label": "Lao",
		"name": "ລາວ"
	},
	{
		"value": "lo-LA",
		"label": "Lao (Laos)",
		"name": "ລາວ (ລາວ)"
	},
	{
		"value": "lrc",
		"label": "Northern Luri",
		"name": "لۊری شومالی"
	},
	{
		"value": "lrc-IQ",
		"label": "Northern Luri (Iraq)",
		"name": "لۊری شومالی (IQ)"
	},
	{
		"value": "lrc-IR",
		"label": "Northern Luri (Iran)",
		"name": "لۊری شومالی (IR)"
	},
	{
		"value": "lt",
		"label": "Lithuanian",
		"name": "lietuvių"
	},
	{
		"value": "lt-LT",
		"label": "Lithuanian (Lithuania)",
		"name": "lietuvių (Lietuva)"
	},
	{
		"value": "lu",
		"label": "Luba-Katanga",
		"name": "Tshiluba"
	},
	{
		"value": "lu-CD",
		"label": "Luba-Katanga (Congo - Kinshasa)",
		"name": "Tshiluba (Ditunga wa Kongu)"
	},
	{
		"value": "luo",
		"label": "Luo",
		"name": "Dholuo"
	},
	{
		"value": "luo-KE",
		"label": "Luo (Kenya)",
		"name": "Dholuo (Kenya)"
	},
	{
		"value": "luy",
		"label": "Luyia",
		"name": "Luluhia"
	},
	{
		"value": "luy-KE",
		"label": "Luyia (Kenya)",
		"name": "Luluhia (Kenya)"
	},
	{
		"value": "lv",
		"label": "Latvian",
		"name": "latviešu"
	},
	{
		"value": "lv-LV",
		"label": "Latvian (Latvia)",
		"name": "latviešu (Latvija)"
	},
	{
		"value": "mas",
		"label": "Masai",
		"name": "Maa"
	},
	{
		"value": "mas-KE",
		"label": "Masai (Kenya)",
		"name": "Maa (Kenya)"
	},
	{
		"value": "mas-TZ",
		"label": "Masai (Tanzania)",
		"name": "Maa (Tansania)"
	},
	{
		"value": "mer",
		"label": "Meru",
		"name": "Kĩmĩrũ"
	},
	{
		"value": "mer-KE",
		"label": "Meru (Kenya)",
		"name": "Kĩmĩrũ (Kenya)"
	},
	{
		"value": "mfe",
		"label": "Morisyen",
		"name": "kreol morisien"
	},
	{
		"value": "mfe-MU",
		"label": "Morisyen (Mauritius)",
		"name": "kreol morisien (Moris)"
	},
	{
		"value": "mg",
		"label": "Malagasy",
		"name": "Malagasy"
	},
	{
		"value": "mg-MG",
		"label": "Malagasy (Madagascar)",
		"name": "Malagasy (Madagasikara)"
	},
	{
		"value": "mgh",
		"label": "Makhuwa-Meetto",
		"name": "Makua"
	},
	{
		"value": "mgh-MZ",
		"label": "Makhuwa-Meetto (Mozambique)",
		"name": "Makua (Umozambiki)"
	},
	{
		"value": "mgo",
		"label": "Metaʼ",
		"name": "metaʼ"
	},
	{
		"value": "mgo-CM",
		"label": "Metaʼ (Cameroon)",
		"name": "metaʼ (Kamalun)"
	},
	{
		"value": "mk",
		"label": "Macedonian",
		"name": "македонски"
	},
	{
		"value": "mk-MK",
		"label": "Macedonian (Macedonia)",
		"name": "македонски (Македонија)"
	},
	{
		"value": "ml",
		"label": "Malayalam",
		"name": "മലയാളം"
	},
	{
		"value": "ml-IN",
		"label": "Malayalam (India)",
		"name": "മലയാളം (ഇന്ത്യ)"
	},
	{
		"value": "mn",
		"label": "Mongolian",
		"name": "монгол"
	},
	{
		"value": "mn-MN",
		"label": "Mongolian (Mongolia)",
		"name": "монгол (Монгол)"
	},
	{
		"value": "mr",
		"label": "Marathi",
		"name": "मराठी"
	},
	{
		"value": "mr-IN",
		"label": "Marathi (India)",
		"name": "मराठी (भारत)"
	},
	{
		"value": "ms",
		"label": "Malay",
		"name": "Melayu"
	},
	{
		"value": "ms-BN",
		"label": "Malay (Brunei)",
		"name": "Melayu (Brunei)"
	},
	{
		"value": "ms-MY",
		"label": "Malay (Malaysia)",
		"name": "Melayu (Malaysia)"
	},
	{
		"value": "ms-SG",
		"label": "Malay (Singapore)",
		"name": "Melayu (Singapura)"
	},
	{
		"value": "mt",
		"label": "Maltese",
		"name": "Malti"
	},
	{
		"value": "mt-MT",
		"label": "Maltese (Malta)",
		"name": "Malti (Malta)"
	},
	{
		"value": "mua",
		"label": "Mundang",
		"name": "MUNDAŊ"
	},
	{
		"value": "mua-CM",
		"label": "Mundang (Cameroon)",
		"name": "MUNDAŊ (kameruŋ)"
	},
	{
		"value": "my",
		"label": "Burmese",
		"name": "မြန်မာ"
	},
	{
		"value": "my-MM",
		"label": "Burmese (Myanmar [Burma])",
		"name": "မြန်မာ (မြန်မာ)"
	},
	{
		"value": "mzn",
		"label": "Mazanderani",
		"name": "مازرونی"
	},
	{
		"value": "mzn-IR",
		"label": "Mazanderani (Iran)",
		"name": "مازرونی (ایران)"
	},
	{
		"value": "naq",
		"label": "Nama",
		"name": "Khoekhoegowab"
	},
	{
		"value": "naq-NA",
		"label": "Nama (Namibia)",
		"name": "Khoekhoegowab (Namibiab)",
		"commonName": ""
	},
	{
		"value": "nb",
		"label": "Norwegian Bokmål",
		"name": "norsk bokmål",
		"commonName": ""
	},
	{
		"value": "nb-NO",
		"label": "Norwegian Bokmål (Norway)",
		"name": "norsk bokmål (Norge)",
		"commonName": ""
	},
	{
		"value": "nb-SJ",
		"label": "Norwegian Bokmål (Svalbard & Jan Mayen)",
		"name": "norsk bokmål (Svalbard og Jan Mayen)",
		"commonName": ""
	},
	{
		"value": "nd",
		"label": "North Ndebele",
		"name": "isiNdebele",
		"commonName": ""
	},
	{
		"value": "nd-ZW",
		"label": "North Ndebele (Zimbabwe)",
		"name": "isiNdebele (Zimbabwe)",
		"commonName": ""
	},
	{
		"value": "nds",
		"label": "Low German",
		"name": "nds",
		"commonName": ""
	},
	{
		"value": "nds-DE",
		"label": "Low German (Germany)",
		"name": "nds (DE)",
		"commonName": ""
	},
	{
		"value": "nds-NL",
		"label": "Low German (Netherlands)",
		"name": "nds (NL)",
		"commonName": "Low Saxon"
	},
	{
		"value": "ne",
		"label": "Nepali",
		"name": "नेपाली",
		"commonName": ""
	},
	{
		"value": "ne-IN",
		"label": "Nepali (India)",
		"name": "नेपाली (भारत)",
		"commonName": ""
	},
	{
		"value": "ne-NP",
		"label": "Nepali (Nepal)",
		"name": "नेपाली (नेपाल)",
		"commonName": ""
	},
	{
		"value": "nl",
		"label": "Dutch",
		"name": "Nederlands",
		"commonName": ""
	},
	{
		"value": "nl-AW",
		"label": "Dutch (Aruba)",
		"name": "Nederlands (Aruba)",
		"commonName": ""
	},
	{
		"value": "nl-BE",
		"label": "Dutch (Belgium)",
		"name": "Nederlands (België)",
		"commonName": "Flemish"
	},
	{
		"value": "nl-BQ",
		"label": "Dutch (Caribbean Netherlands)",
		"name": "Nederlands (Caribisch Nederland)",
		"commonName": ""
	},
	{
		"value": "nl-CW",
		"label": "Dutch (Curaçao)",
		"name": "Nederlands (Curaçao)"
	},
	{
		"value": "nl-NL",
		"label": "Dutch (Netherlands)",
		"name": "Nederlands (Nederland)"
	},
	{
		"value": "nl-SR",
		"label": "Dutch (Suriname)",
		"name": "Nederlands (Suriname)"
	},
	{
		"value": "nl-SX",
		"label": "Dutch (Sint Maarten)",
		"name": "Nederlands (Sint-Maarten)"
	},
	{
		"value": "nmg",
		"label": "Kwasio",
		"name": "nmg"
	},
	{
		"value": "nmg-CM",
		"label": "Kwasio (Cameroon)",
		"name": "nmg (Kamerun)"
	},
	{
		"value": "nn",
		"label": "Norwegian Nynorsk",
		"name": "nynorsk"
	},
	{
		"value": "nn-NO",
		"label": "Norwegian Nynorsk (Norway)",
		"name": "nynorsk (Noreg)"
	},
	{
		"value": "nnh",
		"label": "Ngiemboon",
		"name": "Shwóŋò ngiembɔɔn"
	},
	{
		"value": "nnh-CM",
		"label": "Ngiemboon (Cameroon)",
		"name": "Shwóŋò ngiembɔɔn (Kàmalûm)"
	},
	{
		"value": "nus",
		"label": "Nuer",
		"name": "Thok Nath"
	},
	{
		"value": "nus-SS",
		"label": "Nuer (South Sudan)",
		"name": "Thok Nath (SS)"
	},
	{
		"value": "nyn",
		"label": "Nyankole",
		"name": "Runyankore"
	},
	{
		"value": "nyn-UG",
		"label": "Nyankole (Uganda)",
		"name": "Runyankore (Uganda)"
	},
	{
		"value": "om",
		"label": "Oromo",
		"name": "Oromoo"
	},
	{
		"value": "om-ET",
		"label": "Oromo (Ethiopia)",
		"name": "Oromoo (Itoophiyaa)"
	},
	{
		"value": "om-KE",
		"label": "Oromo (Kenya)",
		"name": "Oromoo (Keeniyaa)"
	},
	{
		"value": "or",
		"label": "Odia",
		"name": "ଓଡ଼ିଆ"
	},
	{
		"value": "or-IN",
		"label": "Odia (India)",
		"name": "ଓଡ଼ିଆ (ଭାରତ)"
	},
	{
		"value": "os",
		"label": "Ossetic",
		"name": "ирон"
	},
	{
		"value": "os-GE",
		"label": "Ossetic (Georgia)",
		"name": "ирон (Гуырдзыстон)"
	},
	{
		"value": "os-RU",
		"label": "Ossetic (Russia)",
		"name": "ирон (Уӕрӕсе)"
	},
	{
		"value": "pa",
		"label": "Punjabi",
		"name": "ਪੰਜਾਬੀ"
	},
	{
		"value": "pa-Arab",
		"label": "Punjabi (Arabic)",
		"name": "پنجابی (عربی)"
	},
	{
		"value": "pa-Arab-PK",
		"label": "Punjabi (Arabic",
		"name": " Pakistan)",
		"commonName": "پنجابی (عربی"
	},
	{
		"value": "pa-Guru",
		"label": "Punjabi (Gurmukhi)",
		"name": "ਪੰਜਾਬੀ (ਗੁਰਮੁਖੀ)"
	},
	{
		"value": "pa-Guru-IN",
		"label": "Punjabi (Gurmukhi",
		"name": " India)",
		"commonName": "ਪੰਜਾਬੀ (ਗੁਰਮੁਖੀ"
	},
	{
		"value": "pl",
		"label": "Polish",
		"name": "polski"
	},
	{
		"value": "pl-PL",
		"label": "Polish (Poland)",
		"name": "polski (Polska)"
	},
	{
		"value": "ps",
		"label": "Pashto",
		"name": "پښتو"
	},
	{
		"value": "ps-AF",
		"label": "Pashto (Afghanistan)",
		"name": "پښتو (افغانستان)"
	},
	{
		"value": "pt",
		"label": "Portuguese",
		"name": "português"
	},
	{
		"value": "pt-AO",
		"label": "Portuguese (Angola)",
		"name": "português (Angola)",
		"commonName": ""
	},
	{
		"value": "pt-BR",
		"label": "Portuguese (Brazil)",
		"name": "português (Brasil)",
		"commonName": "Brazilian Portuguese"
	},
	{
		"value": "pt-CH",
		"label": "Portuguese (Switzerland)",
		"name": "português (Suíça)",
		"commonName": ""
	},
	{
		"value": "pt-CV",
		"label": "Portuguese (Cape Verde)",
		"name": "português (Cabo Verde)",
		"commonName": ""
	},
	{
		"value": "pt-GQ",
		"label": "Portuguese (Equatorial Guinea)",
		"name": "português (Guiné Equatorial)",
		"commonName": ""
	},
	{
		"value": "pt-GW",
		"label": "Portuguese (Guinea-Bissau)",
		"name": "português (Guiné-Bissau)",
		"commonName": ""
	},
	{
		"value": "pt-LU",
		"label": "Portuguese (Luxembourg)",
		"name": "português (Luxemburgo)",
		"commonName": ""
	},
	{
		"value": "pt-MO",
		"label": "Portuguese (Macau SAR China)",
		"name": "português (Macau",
		"commonName": " RAE da China)"
	},
	{
		"value": "pt-MZ",
		"label": "Portuguese (Mozambique)",
		"name": "português (Moçambique)",
		"commonName": ""
	},
	{
		"value": "pt-PT",
		"label": "Portuguese (Portugal)",
		"name": "português (Portugal)",
		"commonName": "European Portuguese"
	},
	{
		"value": "pt-ST",
		"label": "Portuguese (São Tomé & Príncipe)",
		"name": "português (São Tomé e Príncipe)",
		"commonName": ""
	},
	{
		"value": "pt-TL",
		"label": "Portuguese (Timor-Leste)",
		"name": "português (Timor-Leste)",
		"commonName": ""
	},
	{
		"value": "qu",
		"label": "Quechua",
		"name": "Runasimi",
		"commonName": ""
	},
	{
		"value": "qu-BO",
		"label": "Quechua (Bolivia)",
		"name": "Runasimi (Bolivia)",
		"commonName": ""
	},
	{
		"value": "qu-EC",
		"label": "Quechua (Ecuador)",
		"name": "Runasimi (Ecuador)",
		"commonName": ""
	},
	{
		"value": "qu-PE",
		"label": "Quechua (Peru)",
		"name": "Runasimi (Perú)",
		"commonName": ""
	},
	{
		"value": "rm",
		"label": "Romansh",
		"name": "rumantsch",
		"commonName": ""
	},
	{
		"value": "rm-CH",
		"label": "Romansh (Switzerland)",
		"name": "rumantsch (Svizra)",
		"commonName": ""
	},
	{
		"value": "rn",
		"label": "Rundi",
		"name": "Ikirundi",
		"commonName": ""
	},
	{
		"value": "rn-BI",
		"label": "Rundi (Burundi)",
		"name": "Ikirundi (Uburundi)",
		"commonName": ""
	},
	{
		"value": "ro",
		"label": "Romanian",
		"name": "română",
		"commonName": ""
	},
	{
		"value": "ro-MD",
		"label": "Romanian (Moldova)",
		"name": "română (Republica Moldova)",
		"commonName": "Moldavian"
	},
	{
		"value": "ro-RO",
		"label": "Romanian (Romania)",
		"name": "română (România)",
		"commonName": ""
	},
	{
		"value": "rof",
		"label": "Rombo",
		"name": "Kihorombo",
		"commonName": ""
	},
	{
		"value": "rof-TZ",
		"label": "Rombo (Tanzania)",
		"name": "Kihorombo (Tanzania)",
		"commonName": ""
	},
	{
		"value": "ru",
		"label": "Russian",
		"name": "русский",
		"commonName": ""
	},
	{
		"value": "ru-BY",
		"label": "Russian (Belarus)",
		"name": "русский (Беларусь)",
		"commonName": ""
	},
	{
		"value": "ru-KG",
		"label": "Russian (Kyrgyzstan)",
		"name": "русский (Киргизия)",
		"commonName": ""
	},
	{
		"value": "ru-KZ",
		"label": "Russian (Kazakhstan)",
		"name": "русский (Казахстан)",
		"commonName": ""
	},
	{
		"value": "ru-MD",
		"label": "Russian (Moldova)",
		"name": "русский (Молдова)",
		"commonName": ""
	},
	{
		"value": "ru-RU",
		"label": "Russian (Russia)",
		"name": "русский (Россия)",
		"commonName": ""
	},
	{
		"value": "ru-UA",
		"label": "Russian (Ukraine)",
		"name": "русский (Украина)",
		"commonName": ""
	},
	{
		"value": "rw",
		"label": "Kinyarwanda",
		"name": "Kinyarwanda"
	},
	{
		"value": "rw-RW",
		"label": "Kinyarwanda (Rwanda)",
		"name": "Kinyarwanda (U Rwanda)"
	},
	{
		"value": "rwk",
		"label": "Rwa",
		"name": "Kiruwa"
	},
	{
		"value": "rwk-TZ",
		"label": "Rwa (Tanzania)",
		"name": "Kiruwa (Tanzania)"
	},
	{
		"value": "sah",
		"label": "Sakha",
		"name": "саха тыла"
	},
	{
		"value": "sah-RU",
		"label": "Sakha (Russia)",
		"name": "саха тыла (Арассыыйа)"
	},
	{
		"value": "saq",
		"label": "Samburu",
		"name": "Kisampur"
	},
	{
		"value": "saq-KE",
		"label": "Samburu (Kenya)",
		"name": "Kisampur (Kenya)"
	},
	{
		"value": "sbp",
		"label": "Sangu",
		"name": "Ishisangu"
	},
	{
		"value": "sbp-TZ",
		"label": "Sangu (Tanzania)",
		"name": "Ishisangu (Tansaniya)"
	},
	{
		"value": "se",
		"label": "Northern Sami",
		"name": "davvisámegiella"
	},
	{
		"value": "se-FI",
		"label": "Northern Sami (Finland)",
		"name": "davvisámegiella (Suopma)"
	},
	{
		"value": "se-NO",
		"label": "Northern Sami (Norway)",
		"name": "davvisámegiella (Norga)"
	},
	{
		"value": "se-SE",
		"label": "Northern Sami (Sweden)",
		"name": "davvisámegiella (Ruoŧŧa)"
	},
	{
		"value": "seh",
		"label": "Sena",
		"name": "sena"
	},
	{
		"value": "seh-MZ",
		"label": "Sena (Mozambique)",
		"name": "sena (Moçambique)"
	},
	{
		"value": "ses",
		"label": "Koyraboro Senni",
		"name": "Koyraboro senni"
	},
	{
		"value": "ses-ML",
		"label": "Koyraboro Senni (Mali)",
		"name": "Koyraboro senni (Maali)"
	},
	{
		"value": "sg",
		"label": "Sango",
		"name": "Sängö"
	},
	{
		"value": "sg-CF",
		"label": "Sango (Central African Republic)",
		"name": "Sängö (Ködörösêse tî Bêafrîka)"
	},
	{
		"value": "shi",
		"label": "Tachelhit",
		"name": "ⵜⴰⵛⵍⵃⵉⵜ"
	},
	{
		"value": "shi-Latn",
		"label": "Tachelhit (Latin)",
		"name": "Tashelḥiyt (Latn)"
	},
	{
		"value": "shi-Latn-MA",
		"label": "Tachelhit (Latin",
		"name": " Morocco)",
		"commonName": "Tashelḥiyt (Latn"
	},
	{
		"value": "shi-Tfng",
		"label": "Tachelhit (Tifinagh)",
		"name": "ⵜⴰⵛⵍⵃⵉⵜ (Tfng)"
	},
	{
		"value": "shi-Tfng-MA",
		"label": "Tachelhit (Tifinagh",
		"name": " Morocco)",
		"commonName": "ⵜⴰⵛⵍⵃⵉⵜ (Tfng"
	},
	{
		"value": "si",
		"label": "Sinhala",
		"name": "සිංහල"
	},
	{
		"value": "si-LK",
		"label": "Sinhala (Sri Lanka)",
		"name": "සිංහල (ශ්‍රී ලංකාව)"
	},
	{
		"value": "sk",
		"label": "Slovak",
		"name": "slovenčina"
	},
	{
		"value": "sk-SK",
		"label": "Slovak (Slovakia)",
		"name": "slovenčina (Slovensko)"
	},
	{
		"value": "sl",
		"label": "Slovenian",
		"name": "slovenščina"
	},
	{
		"value": "sl-SI",
		"label": "Slovenian (Slovenia)",
		"name": "slovenščina (Slovenija)"
	},
	{
		"value": "smn",
		"label": "Inari Sami",
		"name": "anarâškielâ"
	},
	{
		"value": "smn-FI",
		"label": "Inari Sami (Finland)",
		"name": "anarâškielâ (Suomâ)"
	},
	{
		"value": "sn",
		"label": "Shona",
		"name": "chiShona"
	},
	{
		"value": "sn-ZW",
		"label": "Shona (Zimbabwe)",
		"name": "chiShona (Zimbabwe)"
	},
	{
		"value": "so",
		"label": "Somali",
		"name": "Soomaali"
	},
	{
		"value": "so-DJ",
		"label": "Somali (Djibouti)",
		"name": "Soomaali (Jabuuti)"
	},
	{
		"value": "so-ET",
		"label": "Somali (Ethiopia)",
		"name": "Soomaali (Itoobiya)"
	},
	{
		"value": "so-KE",
		"label": "Somali (Kenya)",
		"name": "Soomaali (Kiiniya)"
	},
	{
		"value": "so-SO",
		"label": "Somali (Somalia)",
		"name": "Soomaali (Soomaaliya)"
	},
	{
		"value": "sq",
		"label": "Albanian",
		"name": "shqip"
	},
	{
		"value": "sq-AL",
		"label": "Albanian (Albania)",
		"name": "shqip (Shqipëri)"
	},
	{
		"value": "sq-MK",
		"label": "Albanian (Macedonia)",
		"name": "shqip (Maqedoni)"
	},
	{
		"value": "sq-XK",
		"label": "Albanian (Kosovo)",
		"name": "shqip (Kosovë)"
	},
	{
		"value": "sr",
		"label": "Serbian",
		"name": "српски"
	},
	{
		"value": "sr-Cyrl",
		"label": "Serbian (Cyrillic)",
		"name": "српски (ћирилица)"
	},
	{
		"value": "sr-Cyrl-BA",
		"label": "Serbian (Cyrillic",
		"name": " Bosnia & Herzegovina)",
		"commonName": "српски (ћирилица"
	},
	{
		"value": "sr-Cyrl-ME",
		"label": "Serbian (Cyrillic",
		"name": " Montenegro)",
		"commonName": "српски (ћирилица"
	},
	{
		"value": "sr-Cyrl-RS",
		"label": "Serbian (Cyrillic",
		"name": " Serbia)",
		"commonName": "српски (ћирилица"
	},
	{
		"value": "sr-Cyrl-XK",
		"label": "Serbian (Cyrillic",
		"name": " Kosovo)",
		"commonName": "српски (ћирилица"
	},
	{
		"value": "sr-Latn",
		"label": "Serbian (Latin)",
		"name": "srpski (latinica)",
		"commonName": ""
	},
	{
		"value": "sr-Latn-BA",
		"label": "Serbian (Latin",
		"name": " Bosnia & Herzegovina)",
		"commonName": "srpski (latinica"
	},
	{
		"value": "sr-Latn-ME",
		"label": "Serbian (Latin",
		"name": " Montenegro)",
		"commonName": "srpski (latinica"
	},
	{
		"value": "sr-Latn-RS",
		"label": "Serbian (Latin",
		"name": " Serbia)",
		"commonName": "srpski (latinica"
	},
	{
		"value": "sr-Latn-XK",
		"label": "Serbian (Latin",
		"name": " Kosovo)",
		"commonName": "srpski (latinica"
	},
	{
		"value": "sv",
		"label": "Swedish",
		"name": "svenska",
		"commonName": ""
	},
	{
		"value": "sv-AX",
		"label": "Swedish (Åland Islands)",
		"name": "svenska (Åland)",
		"commonName": ""
	},
	{
		"value": "sv-FI",
		"label": "Swedish (Finland)",
		"name": "svenska (Finland)",
		"commonName": ""
	},
	{
		"value": "sv-SE",
		"label": "Swedish (Sweden)",
		"name": "svenska (Sverige)",
		"commonName": ""
	},
	{
		"value": "sw",
		"label": "Swahili",
		"name": "Kiswahili",
		"commonName": ""
	},
	{
		"value": "sw-CD",
		"label": "Swahili (Congo - Kinshasa)",
		"name": "Kiswahili (Jamhuri ya Kidemokrasia ya Kongo)",
		"commonName": "Congo Swahili"
	},
	{
		"value": "sw-KE",
		"label": "Swahili (Kenya)",
		"name": "Kiswahili (Kenya)",
		"commonName": ""
	},
	{
		"value": "sw-TZ",
		"label": "Swahili (Tanzania)",
		"name": "Kiswahili (Tanzania)",
		"commonName": ""
	},
	{
		"value": "sw-UG",
		"label": "Swahili (Uganda)",
		"name": "Kiswahili (Uganda)",
		"commonName": ""
	},
	{
		"value": "ta",
		"label": "Tamil",
		"name": "தமிழ்"
	},
	{
		"value": "ta-IN",
		"label": "Tamil (India)",
		"name": "தமிழ் (இந்தியா)"
	},
	{
		"value": "ta-LK",
		"label": "Tamil (Sri Lanka)",
		"name": "தமிழ் (இலங்கை)"
	},
	{
		"value": "ta-MY",
		"label": "Tamil (Malaysia)",
		"name": "தமிழ் (மலேசியா)"
	},
	{
		"value": "ta-SG",
		"label": "Tamil (Singapore)",
		"name": "தமிழ் (சிங்கப்பூர்)"
	},
	{
		"value": "te",
		"label": "Telugu",
		"name": "తెలుగు"
	},
	{
		"value": "te-IN",
		"label": "Telugu (India)",
		"name": "తెలుగు (భారతదేశం)"
	},
	{
		"value": "teo",
		"label": "Teso",
		"name": "Kiteso"
	},
	{
		"value": "teo-KE",
		"label": "Teso (Kenya)",
		"name": "Kiteso (Kenia)"
	},
	{
		"value": "teo-UG",
		"label": "Teso (Uganda)",
		"name": "Kiteso (Uganda)"
	},
	{
		"value": "tg",
		"label": "Tajik",
		"name": "тоҷикӣ"
	},
	{
		"value": "tg-TJ",
		"label": "Tajik (Tajikistan)",
		"name": "тоҷикӣ (Тоҷикистон)"
	},
	{
		"value": "th",
		"label": "Thai",
		"name": "ไทย"
	},
	{
		"value": "th-TH",
		"label": "Thai (Thailand)",
		"name": "ไทย (ไทย)"
	},
	{
		"value": "ti",
		"label": "Tigrinya",
		"name": "ትግርኛ"
	},
	{
		"value": "ti-ER",
		"label": "Tigrinya (Eritrea)",
		"name": "ትግርኛ (ኤርትራ)"
	},
	{
		"value": "ti-ET",
		"label": "Tigrinya (Ethiopia)",
		"name": "ትግርኛ (ኢትዮጵያ)"
	},
	{
		"value": "to",
		"label": "Tongan",
		"name": "lea fakatonga"
	},
	{
		"value": "to-TO",
		"label": "Tongan (Tonga)",
		"name": "lea fakatonga (Tonga)"
	},
	{
		"value": "tr",
		"label": "Turkish",
		"name": "Türkçe"
	},
	{
		"value": "tr-CY",
		"label": "Turkish (Cyprus)",
		"name": "Türkçe (Kıbrıs)"
	},
	{
		"value": "tr-TR",
		"label": "Turkish (Turkey)",
		"name": "Türkçe (Türkiye)"
	},
	{
		"value": "tt",
		"label": "Tatar",
		"name": "татар"
	},
	{
		"value": "tt-RU",
		"label": "Tatar (Russia)",
		"name": "татар (Россия)"
	},
	{
		"value": "twq",
		"label": "Tasawaq",
		"name": "Tasawaq senni"
	},
	{
		"value": "twq-NE",
		"label": "Tasawaq (Niger)",
		"name": "Tasawaq senni (Nižer)"
	},
	{
		"value": "tzm",
		"label": "Central Atlas Tamazight",
		"name": "Tamaziɣt n laṭlaṣ"
	},
	{
		"value": "tzm-MA",
		"label": "Central Atlas Tamazight (Morocco)",
		"name": "Tamaziɣt n laṭlaṣ (Meṛṛuk)"
	},
	{
		"value": "ug",
		"label": "Uyghur",
		"name": "ئۇيغۇرچە"
	},
	{
		"value": "ug-CN",
		"label": "Uyghur (China)",
		"name": "ئۇيغۇرچە (جۇڭگو)"
	},
	{
		"value": "uk",
		"label": "Ukrainian",
		"name": "українська"
	},
	{
		"value": "uk-UA",
		"label": "Ukrainian (Ukraine)",
		"name": "українська (Україна)"
	},
	{
		"value": "ur",
		"label": "Urdu",
		"name": "اردو"
	},
	{
		"value": "ur-IN",
		"label": "Urdu (India)",
		"name": "اردو (بھارت)"
	},
	{
		"value": "ur-PK",
		"label": "Urdu (Pakistan)",
		"name": "اردو (پاکستان)"
	},
	{
		"value": "uz",
		"label": "Uzbek",
		"name": "o‘zbek"
	},
	{
		"value": "uz-Arab",
		"label": "Uzbek (Arabic)",
		"name": "اوزبیک (عربی)"
	},
	{
		"value": "uz-Arab-AF",
		"label": "Uzbek (Arabic",
		"name": " Afghanistan)",
		"commonName": "اوزبیک (عربی"
	},
	{
		"value": "uz-Cyrl",
		"label": "Uzbek (Cyrillic)",
		"name": "ўзбекча (Кирил)"
	},
	{
		"value": "uz-Cyrl-UZ",
		"label": "Uzbek (Cyrillic",
		"name": " Uzbekistan)",
		"commonName": "ўзбекча (Кирил"
	},
	{
		"value": "uz-Latn",
		"label": "Uzbek (Latin)",
		"name": "o‘zbek (lotin)"
	},
	{
		"value": "uz-Latn-UZ",
		"label": "Uzbek (Latin",
		"name": " Uzbekistan)",
		"commonName": "o‘zbek (lotin"
	},
	{
		"value": "vai",
		"label": "Vai",
		"name": "ꕙꔤ"
	},
	{
		"value": "vai-Latn",
		"label": "Vai (Latin)",
		"name": "Vai (Latn)"
	},
	{
		"value": "vai-Latn-LR",
		"label": "Vai (Latin",
		"name": " Liberia)",
		"commonName": "Vai (Latn"
	},
	{
		"value": "vai-Vaii",
		"label": "Vai (Vai)",
		"name": "ꕙꔤ (Vaii)"
	},
	{
		"value": "vai-Vaii-LR",
		"label": "Vai (Vai",
		"name": " Liberia)",
		"commonName": "ꕙꔤ (Vaii"
	},
	{
		"value": "vi",
		"label": "Vietnamese",
		"name": "Tiếng Việt"
	},
	{
		"value": "vi-VN",
		"label": "Vietnamese (Vietnam)",
		"name": "Tiếng Việt (Việt Nam)"
	},
	{
		"value": "vun",
		"label": "Vunjo",
		"name": "Kyivunjo"
	},
	{
		"value": "vun-TZ",
		"label": "Vunjo (Tanzania)",
		"name": "Kyivunjo (Tanzania)"
	},
	{
		"value": "wae",
		"label": "Walser",
		"name": "Walser"
	},
	{
		"value": "wae-CH",
		"label": "Walser (Switzerland)",
		"name": "Walser (Schwiz)"
	},
	{
		"value": "wo",
		"label": "Wolof",
		"name": "Wolof"
	},
	{
		"value": "wo-SN",
		"label": "Wolof (Senegal)",
		"name": "Wolof (Senegaal)"
	},
	{
		"value": "xog",
		"label": "Soga",
		"name": "Olusoga"
	},
	{
		"value": "xog-UG",
		"label": "Soga (Uganda)",
		"name": "Olusoga (Yuganda)"
	},
	{
		"value": "yav",
		"label": "Yangben",
		"name": "nuasue"
	},
	{
		"value": "yav-CM",
		"label": "Yangben (Cameroon)",
		"name": "nuasue (Kemelún)"
	},
	{
		"value": "yi",
		"label": "Yiddish",
		"name": "ייִדיש"
	},
	{
		"value": "yi-001",
		"label": "Yiddish (World)",
		"name": "ייִדיש (וועלט)"
	},
	{
		"value": "yo",
		"label": "Yoruba",
		"name": "Èdè Yorùbá"
	},
	{
		"value": "yo-BJ",
		"label": "Yoruba (Benin)",
		"name": "Èdè Yorùbá (Orílɛ́ède Bɛ̀nɛ̀)"
	},
	{
		"value": "yo-NG",
		"label": "Yoruba (Nigeria)",
		"name": "Èdè Yorùbá (Orílẹ́ède Nàìjíríà)"
	},
	{
		"value": "yue",
		"label": "Cantonese",
		"name": "粵語",
		"commonName": ""
	},
	{
		"value": "yue-Hans",
		"label": "Cantonese (Simplified)",
		"name": "粤语 (简体)",
		"commonName": ""
	},
	{
		"value": "yue-Hans-CN",
		"label": "Cantonese (Simplified",
		"name": " China)",
		"commonName": "粤语 (简体，中华人民共和国)"
	},
	{
		"value": "yue-Hant",
		"label": "Cantonese (Traditional)",
		"name": "粵語 (繁體)",
		"commonName": ""
	},
	{
		"value": "yue-Hant-HK",
		"label": "Cantonese (Traditional",
		"name": " Hong Kong SAR China)",
		"commonName": "粵語 (繁體，中華人民共和國香港特別行政區)"
	},
	{
		"value": "zgh",
		"label": "Standard Moroccan Tamazight",
		"name": "ⵜⴰⵎⴰⵣⵉⵖⵜ",
		"commonName": ""
	},
	{
		"value": "zgh-MA",
		"label": "Standard Moroccan Tamazight (Morocco)",
		"name": "ⵜⴰⵎⴰⵣⵉⵖⵜ (ⵍⵎⵖⵔⵉⴱ)",
		"commonName": ""
	},
	{
		"value": "zh",
		"label": "Chinese",
		"name": "中文",
		"commonName": ""
	},
	{
		"value": "zh-Hans",
		"label": "Chinese (Simplified)",
		"name": "中文（简体）",
		"commonName": "Simplified Chinese"
	},
	{
		"value": "zh-Hans-CN",
		"label": "Chinese (Simplified",
		"name": " China)",
		"commonName": "中文（简体，中国）"
	},
	{
		"value": "zh-Hans-HK",
		"label": "Chinese (Simplified",
		"name": " Hong Kong SAR China)",
		"commonName": "中文（简体，中国香港特别行政区）"
	},
	{
		"value": "zh-Hans-MO",
		"label": "Chinese (Simplified",
		"name": " Macau SAR China)",
		"commonName": "中文（简体，中国澳门特别行政区）"
	},
	{
		"value": "zh-Hans-SG",
		"label": "Chinese (Simplified",
		"name": " Singapore)",
		"commonName": "中文（简体，新加坡）"
	},
	{
		"value": "zh-Hant",
		"label": "Chinese (Traditional)",
		"name": "中文（繁體）",
		"commonName": "Traditional Chinese"
	},
	{
		"value": "zh-Hant-HK",
		"label": "Chinese (Traditional",
		"name": " Hong Kong SAR China)",
		"commonName": "中文（繁體字，中國香港特別行政區）"
	},
	{
		"value": "zh-Hant-MO",
		"label": "Chinese (Traditional",
		"name": " Macau SAR China)",
		"commonName": "中文（繁體字，中國澳門特別行政區）"
	},
	{
		"value": "zh-Hant-TW",
		"label": "Chinese (Traditional",
		"name": " Taiwan)",
		"commonName": "中文（繁體，台灣）"
	},
	{
		"value": "zu",
		"label": "Zulu",
		"name": "isiZulu",
		"commonName": ""
	},
	{
		"value": "zu-ZA",
		"label": "Zulu (South Africa)",
		"name": "isiZulu (iNingizimu Afrika)",
		"commonName": ""
	},
];

export default Locales;

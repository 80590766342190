export const dotSeparateNumber = function (val, id){
  var prefix = '';
  var postfix = '';

  val = val.toString().replace('.', ',');

  if (!val) {
    return 0
  }

  if (val.length === 11 && id === 'id') {
    prefix = val.toString().slice(0, 2) + '-';;
    postfix = '-' + val[10];
    val = val.toString().slice(2, 10);
    return `${prefix}${val}${postfix}`
  }

  while (/(\d+)(\d{3})/.test(val.toString())){
    val = val.toString().replace(/(\d+)(\d{3})/, '$1.$2');
  }
  return val;
};
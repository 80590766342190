import React from 'react';
import { NavLink } from 'react-router-dom';
import BackgroundAnimation from './background-animation';
import Spinner from './SpinningLoader';
import { dotSeparateNumber } from '../../utils/tools';

const StatisticsBox = (props) => {
    return (
        <div className={`box-content ${props.background || 'bg-info'}`}>
            <BackgroundAnimation />
            {
                props.link
                &&
                <NavLink to={props.link} className='btn btn-circle floating-button'><i className='ico fas fa-plus' /></NavLink>
            }
            <div className="statistics-box with-icon">
                <i className={`ico text-white ${props.icon}`}></i>
                    <h2 className="counter text-white">
                        {
                            props.loading
                            ?
                            <Spinner className="text-white" />
                            :
                            dotSeparateNumber(props.value || 0)
                        }
                        {
                            props.valueSpan || props.valueSpan !== 0
                            ?
                            <span style={{ fontSize: '0.6em' }} className="ml-2">{props.valueSpan}</span>
                            :
                            ''
                        }
                    </h2>
                <p className="text text-white">{props.text}</p>
            </div>
        </div>
    );

};

export default StatisticsBox;
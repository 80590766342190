/* IMAGE DRAG AND DROP REORDER */
export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const grid = 8;

export const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,

  // styles we need to apply on draggables
  ...draggableStyle,
});

export const getListStyle = isDraggingOver => ({
    borderColor: isDraggingOver ? '#ccd1d9' : '#ffffff',
    display: 'flex',
    padding: grid,
    overflow: 'auto',
});
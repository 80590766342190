import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import swal from 'sweetalert';
import { addBoatToFleet, updateBoat, getFleetById, cancel } from '../services/fleet';
import { uploadImage } from '../services/images';
/* DROP */
import Dropzone from 'react-dropzone';

const FleetEdit = (props) => {
    const [data, setData] = useState({
        name: '',
        branch: '',
        active: true,
        checklist: null,
        category: '',
        image: '',
        instructions: '',
        rented: false,
        outboardMotor: false,
        stroke: 2,
    });
    const [error, setError] = useState('');
    const [isNew, setIsNew] = useState(false);
    const { id } = props.match.params;

    useEffect(() => {
        if (!id) {
            setIsNew(true);
        } else {
            getData();
        }

        return () => {
            if (id) {
                cancel();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const getData = async () => {
        props.show();
        try {
            const boat = await getFleetById(id);
            setData(boat);
            props.hide();
        } catch (err) {
            swal({   
                title: "Hubo un problema.",   
                text: "Por favor, intentelo nuevamente más tarde.",   
                icon: "error",
                confirmButtonColor: "#DD6B55",
                className: 'logout-alert'
            });
            props.hide();
        }
    };

    const deleteImage = () => {
        setData(value => ({ ...value, image: null }))
    };

    const deleteChecklist = () => {
        setData(value => ({ ...value, checklist: null }))
    };

    const submit = async () => {
        if (isNew) {
            CreateBoat();
        } else {
            UpdateBoat();
        }
    };

    const discard = () => {
        swal({   
            title: "Descartar cambios?",   
            text: "Esta acción es irreversible.",   
            icon: "warning",  
            buttons: ['Cancel', 'Descartar'], 
            confirmButtonColor: "#DD6B55",
            className: 'logout-alert'
        }).then(
            async (value) => {
                if (value) {
                    props.history.push('/fleet')
                }
            }
        );
    };

    const CreateBoat = async () => {
        try {
            const boat = await addBoatToFleet(data);
            console.log('created', boat);
            swal({   
                title: "Embarcación Creada",   
                text: "La embarcación fue agregada a la flota.",   
                icon: "success",
            }).then(value => props.history.push('/fleet'));
        } catch (err) {
            swal({   
                title: "Hubo un problema.",   
                text: "Por favor, intentelo nuevamente más tarde.",   
                icon: "error",
                confirmButtonColor: "#DD6B55",
                className: 'logout-alert'
            });
        }
    };

    const UpdateBoat = async () => {
        try {
            await updateBoat(id, data);
            // console.log('created', boat);
            swal({   
                title: "Cambios guardados",   
                text: "Los cambios se guardaron correctamente.",   
                icon: "success",
            }).then(value => props.history.push('/fleet'));
        } catch (err) {
            swal({   
                title: "Hubo un problema.",   
                text: "Por favor, intentelo nuevamente más tarde.",   
                icon: "error",
                confirmButtonColor: "#DD6B55",
                className: 'logout-alert'
            });
        }
    };

    return (
        <section className="row small-spacing">
            <div className="col-lg-12 col-xs-12">
                <div className="box-content card white">
                    <h4 className="box-title">{isNew ? 'Crear' : 'Editar'} Embarcación</h4>
                    <div className="card-content">
                            <div className="row">
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="name">Nombre *</label>
                                        <input type="text" className="form-control" id="name" placeholder="Name" value={data.name}
                                            onChange={(event) => {
                                                const v = event.target.value;
                                                setData(value => ({...value, name: v }))
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="branch">Sucursal *</label>
                                        <select id="branch" className="form-control" value={data.branch}
                                            onChange={(event) => {
                                                const v = event.target.value;
                                                setData(value => ({...value, branch: v }))
                                            }}
                                        >
                                            <option value='' disabled>Select...</option>
                                            {
                                                props.user.branch.map(branch => <option key={branch} value={branch}>{branch}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="category">Categoría *</label>
                                        <select id="category" className="form-control" defaultValue={data.category}
                                            onChange={(event) => {
                                                const v = event.target.value;
                                                setData(value => ({...value, category: v }))
                                            }}
                                        >
                                            <option value='' disabled>Select...</option>
                                            <option value='Adventure'>Adventure</option>
                                            <option value='Sport'>Sport</option>
                                            <option value='Day Cruise'>Day Cruise</option>
                                            <option value='Day Cruise Plus'>Day Cruise Plus</option>
                                            <option value='Cruise'>Cruise</option>
                                            <option value='Pontón'>Pontón</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="stroke">Tipo de Motor *</label>
                                        <select id="stroke" className="form-control" value={data.stroke}
                                            onChange={(event) => {
                                                const v = event.target.value;
                                                setData(value => ({...value, stroke: v }))
                                            }
                                        }>
                                            <option value='' disabled>Select...</option>
                                            <option value={2}>2 Tiempos</option>
                                            <option value={4}>4 Tiempos</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="rented">Es alquilada *</label>
                                        <select id="rented" className="form-control" value={data.rented}
                                            onChange={(event) => {
                                                const v = event.target.value;
                                                setData(value => ({...value, rented: v }))
                                            }
                                        }>
                                            <option value='' disabled>Select...</option>
                                            <option value={true}>Si</option>
                                            <option value={false}>No</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="outboardMotor">Motor fuera de borda *</label>
                                        <select id="outboardMotor" className="form-control" value={data.outboardMotor}
                                            onChange={(event) => {
                                                const v = event.target.value;
                                                setData(value => ({...value, outboardMotor: v }))
                                            }
                                        }>
                                            <option value='' disabled>Select...</option>
                                            <option value={true}>Si</option>
                                            <option value={false}>No</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="branch">Activa *</label>
                                        <select id="branch" className="form-control" value={data.active}
                                            onChange={(event) => {
                                                const v = event.target.value;
                                                setData(value => ({...value, active: v }))
                                            }
                                        }>
                                            <option value='' disabled>Select...</option>
                                            <option value={true}>Si</option>
                                            <option value={false}>No</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="images">Imagen</label>
                                        {
                                            data.image && data.image !== ''
                                            ?
                                            <div className='image-drag-container mb-3'>
                                                <button id={data.image} className='btn btn-circle btn-danger' onClick={deleteImage}><i className='fas fa-times' /></button>
                                                <img src={data.image} alt="boat" />
                                            </div>
                                            :
                                            <div className='form-control mb-5'>
                                                <Dropzone
                                                multiple={true}
                                                accept="image/*"
                                                onDrop={(acceptedFiles) => {
                                                    acceptedFiles.forEach(async (file) => {
                                                        if (file.size > 10485760) {
                                                            return setError('Image cannot be bigger than 10MB.');
                                                        }
                                                        if (!file) {
                                                            return setError('Please upload an image in PNG or JPEG format.');
                                                        }

                                                        const type = file.type;

                                                        if (type === 'image/jpeg' || type === 'image/png') {            
                                                            let options = {
                                                                file,
                                                                type: 'fleet',
                                                                name: isNew ? false : id,
                                                            }

                                                            try {
                                                                let uploaded = await uploadImage(options);
                                                                setData(value => ({ ...value, image: uploaded.file }));
                                                            } catch (e) {
                                                                setError("We cannot process your image at this time. Por favor, intentelo nuevamente más tarde.");
                                                            }
                                                        } else {
                                                            setError('Please upload an image in PNG or JPEG format.');
                                                        }

                                                    });
                                                }}>
                                                    {({getRootProps, getInputProps}) => (
                                                        <section>
                                                            <div {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                <p><i className="far fa-image mr-2" />Clickeá para subir una imagen</p>
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="checklist">Checklist</label>
                                        {
                                            data.checklist && data.checklist !== ''
                                            ?
                                            <div className='image-drag-container mb-3'>
                                                <button id={data.image} className='btn btn-circle btn-danger' onClick={deleteChecklist}><i className='fas fa-times' /></button>
                                                <i className="far fa-file-pdf checklist-pdf" />
                                            </div>
                                            :
                                            <div className='form-control mb-5'>
                                                <Dropzone
                                                multiple={true}
                                                accept="application/pdf"
                                                onDrop={(acceptedFiles) => {
                                                    acceptedFiles.forEach(async (file) => {
                                                        if (file.size > 10485760) {
                                                            return setError('Image cannot be bigger than 10MB.');
                                                        }
                                                        if (!file) {
                                                            return setError('Please upload an image in PDF format.');
                                                        }

                                                        const type = file.type;

                                                        if (type === 'application/pdf') {            
                                                            let options = {
                                                                file,
                                                                type: 'checklist',
                                                                name: isNew ? false : id,
                                                            }

                                                            try {
                                                                let uploaded = await uploadImage(options);
                                                                setData(value => ({ ...value, checklist: uploaded.file }));
                                                            } catch (e) {
                                                                setError("We cannot process your file at this time. Por favor, intentelo nuevamente más tarde.");
                                                            }
                                                        } else {
                                                            setError('Please upload an image in PDF format.');
                                                        }

                                                    });
                                                }}>
                                                    {({getRootProps, getInputProps}) => (
                                                        <section>
                                                            <div {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                <p><i className="far fa-image mr-2" />Clickeá para subir un PDF</p>
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-xs-12 margin-top-20">
                                    <p className="danger">{error}</p>
                                </div>
                                <div className="col-lg-6 col-xs-12 text-right margin-top-20">
                                    <button type="button" className="btn btn-secondary btn-md" style={ {marginRight: 20} } onClick={discard}>Descartar</button>
                                    <button onClick={submit} className="btn btn-primary btn-md">{ isNew ? 'Add Boat' : 'Guardar Cambios'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    )
};

const mapStateToProps = (state) => {
    return ({timeZone: state.auth.user.timezone, locale: state.auth.user.locale, user: state.auth.user});
};

const mapDispatchToProps = (dispatch) => ({
    show: () => dispatch({ type: 'LOADING' }),
    hide: () => dispatch({ type: 'LOADED' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(FleetEdit);
import React from 'react';
import Modal from 'react-modal';
import { modalStyles } from '../../utils/styles';

Modal.setAppElement(document.getElementById('root'));

const ModalImage = (props) => {
    const { modalIsOpen, closeModal, detailedContent } = props;

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={modalStyles}
            contentLabel="Example Modal"
            >
                <img src={detailedContent} alt="boat" className="modal-content-image" />
        </Modal>
    );
}

export default ModalImage;
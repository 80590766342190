import axios from 'axios';
import Store from '../reducers';
const expired = 'TokenExpiredError';

const CancelToken = axios.CancelToken;
export let cancel;

export const update = async (data) => {
    Store.dispatch({ type: 'LOADING' });
    const url = `${process.env.REACT_APP_API_URL}/admin/update`;

    try {
        const request = await axios.post(url, data,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
        Store.dispatch({ type: 'USER_DATA', user: request.data.admin });
        Store.dispatch({ type: 'LOADED' });
        return request.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            return err.response.data;
        }
        return err;
    }
};

export const setProfilePic = (data) => {
    Store.dispatch({ type: 'LOADING' });
    let formData = new FormData();

    for (let k in data) {
        formData.append(k, data[k]);
    }

    return new Promise((resolve, reject) => {
        axios.post(`${process.env.REACT_APP_API_URL}/admin/setProfilePic`, formData)
            .then(
                res => {
                    Store.dispatch({ type: 'USER_IMAGE', image: res.data.file });
                    Store.dispatch({ type: 'LOADED' });
                    resolve(res.data);
                },
                err => {
                    Store.dispatch({ type: 'LOADED' });
                    if (err.response.data.error === expired) {
                        Store.dispatch({ type: 'LOGOUT' });
                    }
                    reject(err.response.data);
                }
            );
    })
};

export const getAdminList = async (options) => {
    const { sort, direction, filters, results, offset } = options;

    const body = {
        sort,
        direction,
        filters,
    };

    const url = `${process.env.REACT_APP_API_URL}/admin/getAll?results=${results}&offset=${offset}`;

    try {
        let request = await axios.post(url, body,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
    
        return request.data;
    } catch (err) {
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            return err.response.data;
        }
        return err;
    }
};

export const getAdminById = async id => {
    const url = `${process.env.REACT_APP_API_URL}/admin/get/${id}`;

    try {
        let request = await axios.get(url,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
    
        return request.data;
    } catch (err) {
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            throw err.response.data;
        }
        throw err;
    }
};

export const createAdmin = async (data) => {
    Store.dispatch({ type: 'LOADING' });
    const url = `${process.env.REACT_APP_API_URL}/admin/create`;

    try {
        const request = await axios.post(url, data,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
        Store.dispatch({ type: 'LOADED' });
        return request.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            throw err.response.data;
        }
        throw err;
    }
};

export const changePassword = async (id, password) => {
    Store.dispatch({ type: 'LOADING' });
    const url = `${process.env.REACT_APP_API_URL}/admin/changePassword/${id}`;

    try {
        const request = await axios.post(url, { password },
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
        Store.dispatch({ type: 'LOADED' });
        return request.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            throw err.response.data;
        }
        throw err;
    }
};

export const updateAdminById = async (id, data) => {
    Store.dispatch({ type: 'LOADING' });
    const url = `${process.env.REACT_APP_API_URL}/admin/edit/${id}`;

    try {
        const request = await axios.post(url, data,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancel = c;
                })
            }
        );
        Store.dispatch({ type: 'LOADED' });
        return request.data;
    } catch (err) {
        Store.dispatch({ type: 'LOADED' });
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            throw err.response.data;
        }
        throw err;
    }
};
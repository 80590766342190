import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ReactTable from 'react-table';
import "react-table/react-table.css";
import { getUnreadDisputes, cancelUnread } from '../services/messages';

const UnreadMessages = (props) => {
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const [columns, setColumns] = useState([]);
    const table = useRef();

    useEffect(() => { 
        function generateColumns() {
            const columns = [{
                Header: "Cliente",
                id: 'user.name',
                accessor: (d) => d.user.name,
            },
            {
                id: 'dispute.reason',
                Header: 'Motivo del Ticket',
                accessor: (d) => (
                    <span>
                        {d.dispute.reason}
                        <NavLink to={"/tickets/" + d.dispute._id }> <i className="fas fa-link" /></NavLink>
                    </span>
                ),
            },
            {
                Header: 'Sucursal',
                accessor: 'user.branch',
            },
            {
                id: 'departure.date',
                Header: 'Fecha de Salida',
                accessor: (d) => (
                    <span>
                        {new Date(d.departure.date).toLocaleDateString('es-ar')}
                        <NavLink to={"/departures/" + d.departure._id }> <i className="fas fa-link" /></NavLink>
                    </span>),
                filterable: false,
            },
            {
                Header: 'Mensajes sin leer',
                accessor: 'unread',
            },
            {
                Header: '',
                accessor: '_id',
                filterable: false,
                className: 'actions-cell',
                Cell: property => (
                    <NavLink to={"/tickets/" + property.value } className="btn btn-xs btn-circle btn-default">
                        <i className="ico fas fa-info text-dark" />
                    </NavLink>
                ),
                width: 50
            }
            ];
        
            setColumns(columns);
        };
        generateColumns();

        fetchData();

        return function cleanup() {
            cancelUnread();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = async (state, instance) => {
        setLoading(true);
        try {
            const data = await getUnreadDisputes();
            if (data.length > -1) {
                setMessages(data);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
        }
    };
    
    return (
        <section>
            <div className='row'>
                <div className='col-md-6 col-xs-12 align-self-center'>
                    <h3>Mensajes No Leídos</h3>
                </div>
            </div>
            <hr />
            <ReactTable
                ref={table}
                data={messages}
                columns={columns}
                loading={loading}
                showPagination={true}
                showPaginationTop={false}
                showPaginationBottom={true}
                showPageJump={true}
                resizable={true}
                sortable={true}
                filterable={true}
                className="-striped -highlight"
            />
        </section>
    );
};

const mapStateToProps = (state) => {
    return ({timeZone: state.auth.user.timezone, locale: state.auth.user.locale});
};

export default connect(mapStateToProps)(UnreadMessages);

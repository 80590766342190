import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { login } from '../services/auth';
import BackgroundAnimation from './assets/background-animation';

const Login = (props) => {
    const [auth, setAuth] = useState(props.auth);
    const [error, setError] = useState(false);
    const email = useRef('');
    const password = useRef('');
    const path = props.location.search.substr(6);

    useEffect(() => {
        if (auth) {
            if (path) {
                props.history.push(path);
            } else {
                props.history.push('/');
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth])

    const submit = async (event) => {
        event.preventDefault();

        if (!email.current.value || !password.current.value) {
            return errorHandler(0);
        }

        try {
            let loginResult = await login(email.current.value, password.current.value);
            if (loginResult.token) {
                return setAuth(true);
            }
            
            if (loginResult.status !== 200) {
                return errorHandler(loginResult.status);
            }
        } catch (e) {
            return errorHandler(e.status);
        }
    };

    const errorHandler = (status) => {
        let text = '';
        switch (status) {
            case 0:
                text = 'Please, enter your credentials';
                break;
            case 401:
                text = 'Wrong Credentials.';
                break;
            case 404:
                text = 'No user found for that email.';
                break;
            case 500:
                text = 'System down. Por favor, intentelo nuevamente más tarde.';
                break;
            default:
                break;
        }
        return setError(text);
    };

    return (
        <div id="single-wrapper" className="bg-black">
            <BackgroundAnimation />
            <form onSubmit={submit} className="frm-single">
                <div className="inside">
                    <div className="title"><strong>Boat</strong>Share</div>
                    <div className="frm-title">Login</div>
                    <div className="frm-input">
                        <input type="email" placeholder="Email" className="frm-inp" ref={email} />
                        <i className="fa fa-user frm-ico" />
                    </div>
                    <div className="frm-input">
                        <input type="password" placeholder="Password" className="frm-inp" ref={password} />
                        <i className="fa fa-lock frm-ico" />
                    </div>
                    <div className="clearfix margin-bottom-20"></div>
                    { error && <p className="danger">{error}</p> }
                    <button type="submit" className="frm-submit">Ingresar<i className="fa fa-arrow-circle-right" /></button>
                    <div className="frm-footer">BoatShare © 2020. Version {process.env.REACT_APP_RELEASE}</div>
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = state => ({
    auth: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(Login);
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import $ from 'jquery';
import swal from 'sweetalert';
import { getUnread, cancelUnread } from '../services/messages';

const NavBar = (props) => {
    const [unread, setUnread] = useState([]);

    useEffect(() => {
        getMessages();
        const intervalId = setInterval(getMessages, 8000);

        return () => {
            clearInterval(intervalId);
            cancelUnread();
        }
    }, []);

    const getMessages = async () => {
        try {
            const messages = await getUnread();
            setUnread(messages);
        } catch (err) {
            console.error(err);
        }
    }

    const signout = () => {
        swal({   
            title: "Logout?",   
            text: "Are you sure you want to logout?",   
            icon: "warning",  
            buttons: ['No, stay plx!', 'Yes, I\'m out!'], 
            confirmButtonColor: "#DD6B55",
            className: 'logout-alert'
        }).then(
            (value) => {
                if (value) {
                    props.logout();
                }
            }
        );
    };

    const toggleDropdown = (e) => {
        e.preventDefault();
        if ($(".js__toggle").hasClass("active")) {
            $(".js__toggle").removeClass("active");
        } else {
            $(".js__toggle").addClass("active");
        }
    };

    const handleMessageClick = (id) => {
        $(".js__toggle").removeClass("active");
        props.history.push(`/tickets/${id}`)
    };

    return (
            <div className="fixed-navbar">
                <div className="float-left">
                    <button type="button" className="menu-mobile-button fas fa-bars js__menu_mobile"></button>
                    <h1 className="page-title">App Admin</h1>
                </div>
                <div className="float-right">
                    <NavLink to="/profile" className="ico-item notice-alarm">
                        <i className="fas fa-cog" />
                    </NavLink>
                    <a href="mailto:fn.nelli@gmail.com" className="ico-item notice-alarm"><i className="fas fa-envelope" /></a>
                    <span className={`ico-item ${unread.length > 0 ? 'pulse' : ''}`} onClick={toggleDropdown}><span className="ico-item fa fa-bell notice-alarm js__toggle_open noticon" data-target="#notification-popup"></span></span>
                    <span className="ico-item fas fa-power-off js__logout" onClick={signout}></span>
                </div>
                <div id="notification-popup" className="notice-popup js__toggle" data-space="75" style={{maxHeight: '50vh', overflowY: 'scroll'}}>
                    <h2 className="popup-title">Mensajes de Tickets</h2>
                    <div className="content">
                        <ul className="notice-list">
                            {
                                unread.map((m) => (
                                    <li key={m._id}>
                                        <span className="dropdown-item" onClick={() => handleMessageClick(m._id)}>
                                            <span className="d-flex justify-content-between">
                                                <span>
                                                    <span className="name">{m.user.name}</span>
                                                    <span className="desc">{m.dispute.reason}</span>
                                                </span>
                                                <span className="badge badge-pill badge-primary align-self-center">{m.count}</span>
                                            </span>
                                        </span>
                                    </li>
                                ))
                            }
                            
                        </ul>
                    </div>
                </div>
            </div>
    );
};

const mapStateToProps = state => ({
    token: state.auth.token,
});

const mapDispatchToProps = dispatch => ({
   logout: () => dispatch({ type: 'LOGOUT' })
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavBar));
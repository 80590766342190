import axios from 'axios';
import Store from '../reducers';
const expired = 'TokenExpiredError';

export const uploadImage = (data) => {
    Store.dispatch({ type: 'LOADING' });
    let formData = new FormData();

    for (let k in data) {
        formData.append(k, data[k]);
    }

    return new Promise((resolve, reject) => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/images/upload`, formData)
            .then(
                res => {
                    Store.dispatch({ type: 'LOADED' });
                    resolve(res.data);
                },
                err => {
                    Store.dispatch({ type: 'LOADED' });
                    if (err.response.data.error === expired) {
                        Store.dispatch({ type: 'LOGOUT' });
                    }
                    reject(err.response.data);
                }
            );
    })
};
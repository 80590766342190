import axios from 'axios';
import Store from '../reducers';
const expired = 'TokenExpiredError';

const CancelToken = axios.CancelToken;
export let cancelPaymentsGet;

export const getPayments = async (id) => {

    const url = `${process.env.REACT_APP_API_URL}/admin/payments/${id}`;

    try {
        let request = await axios.get(url,
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancelPaymentsGet = c;
                })
            }
        );
    
        return request.data;
    } catch (err) {
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            return err.response.data;
        }
        return err;
    }
};

export const addPayment = async (departure, amount) => {

    const url = `${process.env.REACT_APP_API_URL}/admin/payments/${departure}`;

    try {
        let request = await axios.post(url,
            { amount },
            {
                cancelToken: new CancelToken(function executor(c) {
                    // An executor function receives a cancel function as a parameter
                    cancelPaymentsGet = c;
                })
            }
        );
    
        return request.data;
    } catch (err) {
        if (err.response) {
            if (err.response.data.error === expired) {
                Store.dispatch({ type: 'LOGOUT' });
            }
            return err.response.data;
        }
        return err;
    }
};
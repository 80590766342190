import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';
import swal from 'sweetalert';
/* DRAG N DROP */
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { reorder, getItemStyle, getListStyle } from '../utils/drag-n-drop';
import { createContent, updateContent, getContentById, cancel } from '../services/contents';
import { uploadImage } from '../services/images';
/* DROP */
import Dropzone from 'react-dropzone';

const ContentEdit = (props) => {
    const [data, setData] = useState({
        name: '',
        branches: [],
        active: true,
        images: [],
        description: '',
        title: '',
    });
    const [error, setError] = useState('');
    const [isNew, setIsNew] = useState(false);
    const { id } = props.match.params;

    useEffect(() => {
        if (!id) {
            setIsNew(true);
        } else {
            getData();
        }

        return () => {
            if (id) {
                cancel();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const getData = async () => {
        props.show();
        try {
            const boat = await getContentById(id);
            setData(boat);
            props.hide();
        } catch (err) {
            swal({   
                title: "Hubo un problema.",   
                text: "Por favor, intentelo nuevamente más tarde.",   
                icon: "error",
                confirmButtonColor: "#DD6B55",
                className: 'logout-alert'
            });
            props.hide();
        }
    };

    const submit = async () => {
        if (isNew) {
            CreateContent();
        } else {
            UpdateContent();
        }
    };

    const discard = () => {
        swal({   
            title: "Descartar cambios?",   
            text: "Esta acción es irreversible.",   
            icon: "warning",  
            buttons: ['Cancel', 'Descartar'], 
            confirmButtonColor: "#DD6B55",
            className: 'logout-alert'
        }).then(
            async (value) => {
                if (value) {
                    props.history.push('/contents')
                }
            }
        );
    };

    const CreateContent = async () => {
        try {
            const ct = await createContent(data);
            console.log('created', ct);
            swal({   
                title: "Content Created",   
                text: "The Content has been created.",   
                icon: "success",
            }).then(value => props.history.push('/contents'));
        } catch (err) {
            swal({   
                title: "Hubo un problema.",   
                text: "Por favor, intentelo nuevamente más tarde.",   
                icon: "error",
                confirmButtonColor: "#DD6B55",
                className: 'logout-alert'
            });
        }
    };

    const UpdateContent = async () => {
        try {
            const ct = await updateContent(id, data);
            console.log('updated', ct);
            swal({   
                title: "Cambios guardados",   
                text: "Your content has been updated.",   
                icon: "success",
            }).then(value => props.history.push('/contents'));
        } catch (err) {
            swal({   
                title: "Hubo un problema.",   
                text: "Por favor, intentelo nuevamente más tarde.",   
                icon: "error",
                confirmButtonColor: "#DD6B55",
                className: 'logout-alert'
            });
        }
    };

    const onBranchChange = (value, { action, removedValue }) => {
        let values = data.branches || [];
        switch (action) {
            case 'select-option': 
                values = value;
            break; 
            case 'remove-value':
                values = values.filter(val => removedValue.value !== val);
                break;
            case 'pop-value':
                break;
            case 'clear':
                values = [];
                break;
            default:
                break;
        }
        const d = values.map(a => a.value);
        setData(v => ({ ...v, branches: d }));
    };
    
    const onImageDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
    
        const images = reorder(
            data.images,
            result.source.index,
            result.destination.index
        );
    
        setData(value => ({
            ...value,
            images,
        }));
    };

    const deleteImage = (event) => {
        event.preventDefault();
        const { id } = event.target;

        const imageToRemove = data.images.filter((img) => {
            if (img === id) {
                return img;
            } else {
                return false;
            }
        })[0];
        
        swal({
            title: "Eliminar Imagen",   
            text: "Estás seguro?",   
            icon: "warning",
            buttons: ['Cancelar', 'Eliminar'], 
            confirmButtonColor: "#DD6B55",
            className: 'logout-alert'
        }).then(
            (value) => {
                if (value) {
                    let i = data.images.indexOf(imageToRemove);
                    let images = data.images.map(item => item);
                    images.splice(i, 1);
                    setData(value => ({
                        ...value,
                        images
                    }));
                }
            }
        );
    };

    return (
        <section className="row small-spacing">
            <div className="col-lg-12 col-xs-12">
                <div className="box-content card white">
                    <h4 className="box-title">{isNew ? 'Crear' : 'Editar'} Contenido</h4>
                    <div className="card-content">
                            <div className="row">
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="name">Nombre *</label>
                                        <input type="text" className="form-control" id="name" placeholder="Name" value={data.name}
                                            onChange={(event) => {
                                                const v = event.target.value;
                                                setData(value => ({...value, name: v }))
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="title">Título *</label>
                                        <input type="text" className="form-control" id="name" placeholder="Title" value={data.title}
                                            onChange={(event) => {
                                                const v = event.target.value;
                                                setData(value => ({...value, title: v }))
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="branch">Sucursales *</label>
                                        <Select
                                            closeMenuOnSelect={false}
                                            value={data.branches.map(a => ({ label: a, value: a}))}
                                            isMulti
                                            name="branch"
                                            onChange={onBranchChange}
                                            options={props.user.branch.map(a => ({ label: a, value: a}))}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xs-12">
                                    <div className='form-group'>
                                        <label htmlFor="branch">Activo *</label>
                                        <select id="branch" className="form-control" value={data.active}
                                            onChange={(event) => {
                                                const v = event.target.value;
                                                setData(value => ({...value, active: v }))
                                            }
                                        }>
                                            <option value='' disabled>Select...</option>
                                            <option value={true}>Si</option>
                                            <option value={false}>No</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className='form-group'>
                                        <label htmlFor="images">Imágenes</label>
                                        <DragDropContext onDragEnd={onImageDragEnd}>
                                        <Droppable style={{ transform: "none" }} droppableId="droppable" direction="horizontal">
                                        {(provided, snapshot) => (
                                            <div
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                            {...provided.droppableProps}
                                            >
                                            {data.images.map((item, index) => (
                                                <Draggable key={item} draggableId={item} index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                    className='image-drag-container'
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                    >
                                                        <button id={item} className='btn btn-circle btn-danger' onClick={deleteImage}><i className='fas fa-times' /></button>
                                                        <img src={item} alt="Content" />
                                                    </div>
                                                )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                            </div>
                                        )}
                                        </Droppable>
                                    </DragDropContext>
                                        <div className='form-control mb-5'>
                                            <Dropzone
                                            multiple={true}
                                            accept="image/*"
                                            onDrop={(acceptedFiles) => {
                                                acceptedFiles.forEach(async (file) => {
                                                    if (file.size > 10485760) {
                                                        return setError('Image cannot be bigger than 10MB.');
                                                    }
                                                    if (!file) {
                                                        return setError('Please upload an image in PNG or JPEG format.');
                                                    }

                                                    const type = file.type;

                                                    if (type === 'image/jpeg' || type === 'image/png') {            
                                                        let options = {
                                                            file,
                                                            type: 'content',
                                                            name: isNew ? false : id,
                                                        }

                                                        try {
                                                            let uploaded = await uploadImage(options);
                                                            setData(value => ({ ...value, images: [...value.images, uploaded.file] }));
                                                        } catch (e) {
                                                            setError("We cannot process your image at this time. Por favor, intentelo nuevamente más tarde.");
                                                        }
                                                    } else {
                                                        setError('Please upload an image in PNG or JPEG format.');
                                                    }

                                                });
                                            }}>
                                                {({getRootProps, getInputProps}) => (
                                                    <section>
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            <p><i className="far fa-image mr-2" />Clickeá para subir una imagen</p>
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className='form-group'>
                                        <label htmlFor="instructions">Descripción</label>
                                        <textarea type="text" className="form-control" id="instructions" placeholder="Description" value={data.description}
                                            onChange={(event) => {
                                                const v = event.target.value;
                                                setData(value => ({...value, description: v }))
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-xs-12 margin-top-20">
                                    <p className="danger">{error}</p>
                                </div>
                                <div className="col-lg-6 col-xs-12 text-right margin-top-20">
                                    <button type="button" className="btn btn-secondary btn-md" style={ {marginRight: 20} } onClick={discard}>Descartar</button>
                                    <button onClick={submit} className="btn btn-primary btn-md">{ isNew ? 'Crear Contenido' : 'Guardar Cambios'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    )
};

const mapStateToProps = (state) => {
    return ({timeZone: state.auth.user.timezone, locale: state.auth.user.locale, user: state.auth.user});
};

const mapDispatchToProps = (dispatch) => ({
    show: () => dispatch({ type: 'LOADING' }),
    hide: () => dispatch({ type: 'LOADED' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentEdit);
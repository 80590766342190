import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ReactTable from 'react-table';
import "react-table/react-table.css";
import { getContentsList, cancel } from '../services/contents';

const Contents = (props) => {
    const [loading, setLoading] = useState(false);
    const [contents, setContents] = useState([]);
    const [pages, setPages] = useState(1);
    const [show, setShow] = useState(10);
    const [columns, setColumns] = useState([]);
    const table = useRef();

    useEffect(() => { 
        function generateColumns() {
            let columns = [{
                Header: 'Nombre',
                accessor: 'name',
            },
            {
                Header: 'Título',
                accessor: 'title',
            },
            {
                id: 'branches',
                Header: 'Sucursal',
                accessor: (d) => d.branches.map(b => <span className="badge badge-primary">{b}</span>),
                filterable: false,
            },
            {
                id: 'createdAt',
                Header: 'Fecha',
                accessor: (d) => new Date(d.createdAt).toLocaleString(props.locale, {timeZone: props.timeZone}),
                filterable: false,
            },
            {
                id: 'active',
                Header: 'Activo',
                accessor: (d) => d.active ? <i className="fas fa-check text-success" /> : <i className="fas fa-x text-danger" />,
                filterable: false,
            },
            {
                Header: '',
                accessor: '_id',
                filterable: false,
                className: 'actions-cell',
                Cell: property => (
                    <NavLink to={"/contents/edit/" + property.value } className="btn btn-xs btn-circle btn-default">
                        <i className="ico fas fa-pen text-dark" />
                    </NavLink>
                ),
                width: 50
            }
            ];
        
            setColumns(columns);
        };
        generateColumns();

        return function cleanup() {
            cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = async (state, instance) => {
        setLoading(true);
        
        let options = {
            sort: state.sorted.length > 0 ? state.sorted[0].id : 'createdAt',
            direction: state.sorted.length > 0 ? state.sorted[0].desc ? 'desc' : 'asc' : 'desc',
            filters: state.filtered.length > 0 ? state.filtered : [],
            results: state.pageSize,
            offset: (state.page +1) * state.pageSize - state.pageSize
        };

        try {
            let data = await getContentsList(options);
            if (data.contents.length > -1) {
                setContents(data.contents);
                setLoading(false);
                setPages(data.pages);
                setShow(data.results);
            } else {
                setLoading(false);
            }
        } catch (e) {
            setLoading(false);
        }
    };
    
    return (
        <section>
            <div className='row'>
                <div className='col-md-6 col-xs-12 align-self-center'>
                    <h3>Contents</h3>
                </div>
                <div className='col-md-6 col-xs-12 text-right'>
                    <NavLink to='/contents/new' className="btn btn-icon btn-icon-left btn-success">
                        <i className="ico fas fa-plus" /> New Content
                    </NavLink>
                </div>
            </div>
            <hr />
            <ReactTable
                ref={table}
                data={contents}
                columns={columns}
                pages={pages}
                defaultPageSize={show}
                loading={loading}
                showPagination={true}
                showPaginationTop={false}
                showPaginationBottom={true}
                showPageJump={true}
                resizable={true}
                sortable={true}
                filterable={true}
                manual
                onFetchData={fetchData}
                onSortedChange={cancel}
                onFilteredChange={cancel}
                className="-striped -highlight"
            />
        </section>
    );
};

const mapStateToProps = (state) => {
    return ({user: state.auth.user, timeZone: state.auth.user.timezone, locale: state.auth.user.locale});
};

export default connect(mapStateToProps)(Contents);

/* eslint-disable react/jsx-no-target-blank */

import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {connect} from 'react-redux';
import Gallery from 'react-grid-gallery';
import Modal from 'react-modal';
import swal from 'sweetalert';
import { getDepartureById, addFuelDataToDeparture, addCustomAddonToDeparture } from '../services/departures';
import { createDispute } from '../services/disputes';
import { getPayments, addPayment } from '../services/payments';
import { dotSeparateNumber } from '../utils/tools';
import BackgroundAnimation from './assets/background-animation';

const cleanAddon = { name: '', description: '', cost: 0 };

const DepartureDetails = props => {
    const { id } = props.match.params;
    const [departure, setDeparture] = useState(false);
    // PAYMENTS
    const [payments, setPayments] = useState([]);
    const [addPaymentModal, setAddPaymentModal] = useState(false);
    const [newPayment, setNewPayment] = useState(0);

    const [addAddonModal, setAddAddonModal] = useState(false);
    const [customAddon, setCustomAddon] = useState(cleanAddon);

    // FUEL
    const [addFuelModal, setAddFuelModal] = useState(false);
    const [fuelLitres, setFuelLitres] = useState(0);
    const [fuelPrice, setFuelPrice] = useState(0);
    // DISPUTE
    const [disputeModal, setDisputeModal] = useState(false);
    const [disputeReason, setDisputeReason] = useState('');
    const [disputeDescription, setDisputeDescription] = useState('');

    useEffect(() => {
        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const getData = async () => {
        const data = await getDepartureById(id);
        console.log(data);
        setDeparture(data);

        const pdata = await getPayments(id);
        console.log(pdata);
        setPayments(pdata);
    };

    const saveCustomAddon = async () => {
        try {
            await addCustomAddonToDeparture(id, customAddon);
            // console.log(data);
            setCustomAddon(cleanAddon);
            setAddAddonModal(false);
            setTimeout(() => getData(), 500);
        } catch (err) {
            swal({   
                title: "Hubo un problema.",   
                text: "Por favor, intentelo nuevamente más tarde.",   
                icon: "error",
                confirmButtonColor: "#DD6B55",
                className: 'logout-alert'
            });
        }

    }

    const savePayment = async () => {
        try {
            await addPayment(id, newPayment);

            setNewPayment(0);
            setAddPaymentModal(false);
            setTimeout(() => getData(), 500);
        } catch (err) {
            
        }
    };

    const saveFuelData = async () => {
        try {
            const data = await addFuelDataToDeparture(id, {
                refueledLitres: fuelLitres,
                fuelPrice,
            });
            setAddFuelModal(false);
            setDeparture(value => ({
                ...value,
                expenses: data.expenses,
                fuelPrice: data.fuelPrice,
                oilPrice: data.oilPrice,
                refueledLitres: data.refueledLitres,
            }));
            setTimeout(() => getData(), 500);
        } catch (err) {
            swal({   
                title: "Hubo un problema.",   
                text: "Por favor, intentelo nuevamente más tarde.",   
                icon: "error",
                confirmButtonColor: "#DD6B55",
                className: 'logout-alert'
            });
        }
    };

    const saveDispute = async () => {
        props.show();
        try {
            setDisputeModal(false);
            const params = {
                reason: disputeReason,
                description: disputeDescription,
                user: departure.user._id,
            };

            const dispute = await createDispute(id, params);
            //console.log('dispute', dispute);
            props.hide();
            swal({   
                title: "Listo",   
                text: "Ticket Creado.",   
                icon: "success",  
                buttons: ['OK', 'Ver Ticket'],
            }).then((value) => {
                if (value) {
                    props.history.push('../tickets/' + dispute._id);
                }
            });

        } catch (err) {
            props.hide();
            swal({   
                title: "Hubo un problema.",   
                text: "Por favor, intentelo nuevamente más tarde.",   
                icon: "error",
                confirmButtonColor: "#DD6B55",
                className: 'logout-alert'
            });
        }
    };

    return (
        departure
        ? (
        <section>
            <div className='row'>
                <div className='col-md-6 col-xs-12 align-self-center'>
                    <h3>Detalles de Salida</h3>
                </div>
                <div className='col-md-6 col-xs-12 text-right'>
                    <button onClick={() => setDisputeModal(true)} className="btn btn-icon btn-icon-left btn-danger mr-3">
                        <i className="ico fas fa-bullhorn" /> Abrir Ticket
                    </button>
                    {
                        departure.prev && <NavLink to={'../departures/' + departure.prev} className="btn btn-black mr-3">&lt;</NavLink>
                    }
                    {
                        departure.next && <NavLink to={'../departures/' + departure.next} className="btn btn-black">&gt;</NavLink>
                    }
                    {/*
                        departure.status !== "Finalizada"
                        &&
                        <NavLink to='/fleet/add' className="btn btn-icon btn-icon-left btn-success">
                            <i className="ico fas fa-check-double" /> Mark as Ended
                        </NavLink>
                    */}
                </div>
            </div>
            <hr />
            <div className="row small-spacing">
                <div className="col-lg-4 col-md-12 col-xs-12">
                    <div className="box-content bordered primary margin-bottom-20">
                        <div className="profile-avatar">
                            {
                                departure.boat.image
                                ?
                                <img src={departure.boat.image} alt={departure.boat.name} />
                                :
                                <div className="user-pic-placeholder bg-danger d-flex">
                                    <i className="fas fa-user-astronaut justify-content-centter align-self-center" />
                                    <BackgroundAnimation />
                                </div>
                            }
                            <NavLink to={"/fleet/log/" + departure.boat._id } className="btn btn-block btn-primary btn-inbox">
                                <i className="fas fa-anchor" /> Boat Details
                            </NavLink>
                            <h3 className="mt-3"><strong className="capitalize">{departure.boat.name}</strong></h3>
                            <h4 className="mb-3">{departure.user.name}</h4>
                        </div>
                        <table className="table table-hover no-margin">
                            <tbody>
                                <tr>
                                    <td>Estado de Salida</td>
                                    <td>
                                        <span className="notice notice-blue">{departure.status}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Fecha</td>
                                    <td>{new Date(departure.date).toLocaleDateString(props.locale)}</td>
                                </tr>
                                <tr>
                                    <td>Sucursal</td>
                                    <td>{departure.branch}</td>
                                </tr>
                                <tr>
                                    <td>Expensas Totales:</td>
                                    <td>$ {dotSeparateNumber(departure.expenses)}</td>
                                </tr>
                                <tr>
                                    <td>Total Abonado:</td>
                                    <td>$ {dotSeparateNumber(departure.payed)}</td>
                                </tr>
                                <tr>
                                    <td><b>Pendiente de pago:</b></td>
                                    <td><b>$ {dotSeparateNumber(departure.debt || Math.round(((departure.expenses - departure.payed) + Number.EPSILON) * 100) / 100)}</b></td>
                                </tr>
                                <tr className={departure.user.balance && departure.user.balance < 0 ? 'text-danger' : 'text-success'}>
                                    <td><b>Balance total del cliente:</b></td>
                                    <td><b>$ {dotSeparateNumber(departure.user.balance)}</b></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-lg-8 col-md-12 col-xs-12">
                    <div className="col-md-12 col-xs-12">
                        <div className="box-content card">
                            <h4 className="box-title"><i className="fas fa-sign-in-alt ico"></i> Check-In</h4>
                            <div className="card-content container">
                                {
                                    departure.checkInAt
                                    ?
                                    <ul className="profile-list">
                                        <li>
                                            <b>Fecha y Hora:</b> {new Date(departure.checkInAt).toLocaleString(props.locale, {timeZone: props.timeZone})}
                                        </li>
                                        <li>
                                            <b>Operador:</b> {departure.checkInBy.name}
                                        </li>
                                        <li>
                                            <b>Coordenadas: </b>{
                                                departure.checkInPosition && departure.checkInPosition.lat
                                                ?
                                                // eslint-disable-next-line react/jsx-no-target-blank
                                                <a href={`http://www.google.com/maps/place/${departure.checkInPosition.lat},${departure.checkInPosition.lng}`} target="_blank">Ver en mapa &rarr;</a>
                                                :
                                                <span>No disponible</span>
                                            }
                                        </li>
                                    </ul>
                                    :
                                    <ul className="profile-list">
                                        <li>
                                            <b>No disponible</b>
                                        </li>
                                    </ul>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-xs-12">
                        <div className="box-content card">
                            <h4 className="box-title"><i className="fas fa-sign-out-alt ico"></i> Check-Out</h4>
                            <div className="card-content container">

                                {
                                    departure.checkOutAt
                                    ?
                                    <ul className="profile-list">
                                        <li>
                                            <b>Fecha y Hora:</b> {new Date(departure.checkOutAt).toLocaleString(props.locale, {timeZone: props.timeZone})}
                                        </li>
                                        {
                                            departure.checkOutByAdmin &&
                                            <li>
                                                <b>Realizado por Operador:</b> {departure.checkOutByAdmin.name}
                                            </li>
                                        }
                                        
                                        <li>
                                            <b>Coordenadas: </b>{
                                                departure.checkOutPosition && departure.checkOutPosition.lat
                                                ?
                                                // eslint-disable-next-line react/jsx-no-target-blank
                                                <a href={`http://www.google.com/maps/place/${departure.checkOutPosition.lat},${departure.checkOutPosition.lng}`} target="_blank">Ver en mapa &rarr;</a>
                                                :
                                                <span>No disponible</span>
                                            }
                                        </li>
                                        <li>
                                            <b>Comentarios del usuario: </b>{departure.comments ? departure.comments : 'Sin comentarios.'}
                                        </li>
                                    </ul>
                                    :
                                    <ul className="profile-list">
                                        <li>
                                            <b>No disponible</b>
                                        </li>
                                    </ul>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-xs-12">
                        <div className="box-content card">
                            <h4 className="box-title"><i className="fas fa-gas-pump ico"></i> Combustible</h4>
                            <div className="card-content container">
                                {
                                    departure.checkOutAt
                                    ?
                                    <div>
                                        <ul className="profile-list">
                                            <li>
                                                <b>Recargado por el cliente:</b> {departure.refueledByUser ? 'Si' : 'No'}
                                            </li>
                                            <li>
                                                <b>Litros cargados:</b> {departure.refueledLitres} L.
                                            </li>
                                            <li>
                                                <b>Ticket:</b> {departure.refuelTicket ? <a href={departure.refuelTicket} target="_blank"><i className="fas fa-image text-success" /></a> : 'No disponible.'}
                                            </li>
                                            <li>
                                                <b>Costo del Combustible:</b> $ {dotSeparateNumber(departure.fuelPrice)}
                                            </li>
                                            <li>
                                                <b>Costo del Aceite:</b> $ {dotSeparateNumber(departure.oilPrice)}
                                            </li>
                                        </ul>
                                        {
                                            !departure.refueledByUser && departure.refueledLitres === 0
                                            ?
                                            <button className="btn btn-bordered btn-primary" onClick={() => setAddFuelModal(true)}>Agregar cargos</button>
                                            :
                                            <button className="btn btn-bordered btn-primary" onClick={() => setAddFuelModal(true)}>Editar cargos</button>
                                        }
                                    </div>
                                    :
                                    <ul className="profile-list">
                                        <li>
                                            <b>No disponible</b>
                                        </li>
                                    </ul>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-12 mb-5">
                    <h4>Imágenes de Check-In</h4>
                    <hr />
                    {
                        departure.checkInImages && departure.checkInImages.images.length > 0
                        ?
                        <Gallery
                            images={departure.checkInImages.images.map(img => (
                                {
                                    src: img.url,
                                    caption: img.type,
                                    tags: [{value: img.type, title: img.type}],
                                    thumbnail: img.url,
                                    thumbnailWidth: 320,
                                    thumbnailHeight: 174,
                                }
                            ))}
                            enableImageSelection={false}
                        />
                        :
                        <span>El operador no cargó imágenes de Check-In.</span>
                    }
                    
                </div>
                <div className="col-md-12 mb-5">
                    <h4>Imágenes de Check-Out</h4>
                    <hr />
                    {
                        departure.checkOutImages.length > 0
                        ?
                        <Gallery
                            images={departure.checkOutImages.map(img => (
                                {
                                    src: img.url,
                                    caption: img.type,
                                    tags: [{value: img.type, title: img.type}],
                                    thumbnail: img.url,
                                    thumbnailWidth: 320,
                                    thumbnailHeight: 174,
                                }
                            ))}
                            enableImageSelection={false}
                        />
                        :
                        <span>El cliente no cargó imágenes de Check-Out.</span>
                    }
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 col-xs-12">
                    <div className="box-content">
                        <h4 className="box-title">Adicionales y otros cargos</h4>
                        <div className="dropdown js__drop_down">
                            <i className="dropdown-icon ico fas fa-plus-circle pointer" role="button" onClick={() => setAddAddonModal(true)} />
                        </div>
                        <div className="table-responsive table-purchases">
                            <table className="table table-striped margin-bottom-10">
                                <thead>
                                    <tr>
                                        <th>Concepto</th>
                                        <th>Costo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        departure.addons.length > 0
                                        ?
                                        departure.addons.map((addon) => (
                                            <tr key={addon._id}>
                                                <td>{addon.name}</td>
                                                <td>${dotSeparateNumber(addon.cost)}</td>
                                            </tr>
                                        ))
                                        :
                                        <tr>
                                            <td>Sin cargos adicionales.</td>
                                            <td>-</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-xs-12">
                    <div className="box-content">
                        <h4 className="box-title">Pagos</h4>
                        <div className="dropdown js__drop_down">
                            <i className="dropdown-icon ico fas fa-plus-circle pointer" role="button" onClick={() => setAddPaymentModal(true)} />
                        </div>
                        <div className="table-responsive table-purchases">
                            <table className="table table-striped margin-bottom-10">
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Monto</th>
                                        <th>Recibido por</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        payments.length > 0
                                        ?
                                        payments.map((payment) => (
                                            <tr key={payment._id}>
                                                <td>{new Date(payment.timestamp).toLocaleString(props.locale, {timeZone: props.timeZone})}</td>
                                                <td>${dotSeparateNumber(payment.amount)}</td>
                                                <td>{payment.receivedBy.name}</td>
                                            </tr>
                                        ))
                                        :
                                        <tr>
                                            <td>Sin pagos.</td>
                                            <td>-</td>
                                            <td>-</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            { /* MODAL */ }
            <Modal
                isOpen={addAddonModal}
                onRequestClose={() => {
                    setCustomAddon(cleanAddon);
                    return setAddAddonModal(false)
                }}
                contentLabel="Addon Modal"
                style={{content : {
                    top                   : '50%',
                    left                  : '50%',
                    right                 : 'auto',
                    bottom                : 'auto',
                    marginRight           : '-50%',
                    padding               : '0',
                    transform             : 'translate(-50%, -50%)'
                  }}}
                >
                <div className="modal-header">
                    <h5 className="modal-title">Agregar Cargo</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setAddAddonModal(false)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className='form-group'>
                        <label htmlFor="name">Concepto *</label>
                        <input type="text" className="form-control" id="name" placeholder="Nombre" defaultValue={customAddon.name} onChange={(event) => {
                            const { value } = event.target;
                            return setCustomAddon(v => ({ ...v, name: value }));
                        }} />
                    </div>
                    <div className='form-group'>
                        <label htmlFor="description">Descripción</label>
                        <input type="text" className="form-control" id="description" placeholder="Descripción" value={customAddon.description} onChange={(event) => {
                            const { value } = event.target;
                            return setCustomAddon(v => ({ ...v, description: value }));
                        }} />
                    </div>
                    <div className='form-group'>
                        <label htmlFor="amount">Monto *</label>
                        <input type="number" className="form-control" id="amount" placeholder="$" value={customAddon.cost} onChange={(event) => {
                            const { value } = event.target;
                            return setCustomAddon(v => ({ ...v, cost: value }));
                        }} />
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={() => saveCustomAddon()} disabled={customAddon.cost === 0}>Agregar Cargo</button>
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setAddAddonModal(false)}>Descartar</button>
                </div>
            </Modal>
            <Modal
                isOpen={addPaymentModal}
                onRequestClose={() => setAddPaymentModal(false)}
                contentLabel="Payment Modal"
                style={{content : {
                    top                   : '50%',
                    left                  : '50%',
                    right                 : 'auto',
                    bottom                : 'auto',
                    marginRight           : '-50%',
                    padding               : '0',
                    transform             : 'translate(-50%, -50%)'
                  }}}
                >
                <div className="modal-header">
                    <h5 className="modal-title">Informar Pago</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setAddPaymentModal(false)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className='form-group'>
                        <label htmlFor="amount">Monto *</label>
                        <input type="number" className="form-control" id="amount" placeholder="Amount" value={newPayment} onChange={(event) => setNewPayment(event.target.value)} />
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={() => savePayment()} disabled={newPayment === 0}>Informar Pago</button>
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setAddPaymentModal(false)}>Descartar</button>
                </div>
            </Modal>
            { /* MODAL */ }
            <Modal
                isOpen={addFuelModal}
                onRequestClose={() => setAddFuelModal(false)}
                contentLabel="Montos de Combustible"
                style={{content : {
                    top                   : '50%',
                    left                  : '50%',
                    right                 : 'auto',
                    bottom                : 'auto',
                    marginRight           : '-50%',
                    padding               : '0',
                    transform             : 'translate(-50%, -50%)'
                  }}}
                >
                <div className="modal-header">
                    <h5 className="modal-title">Combustible</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setAddFuelModal(false)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className='form-group'>
                        <label htmlFor="litres">Litros *</label>
                        <input type="number" className="form-control" id="litres" placeholder="Fuel Litres" value={fuelLitres} onChange={(event) => setFuelLitres(event.target.value)} />
                    </div>
                    <div className='form-group'>
                        <label htmlFor="price">Precio *</label>
                        <input type="number" className="form-control" id="price" placeholder="Fuel Price" value={fuelPrice} onChange={(event) => setFuelPrice(event.target.value)} />
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={saveFuelData} disabled={fuelPrice === 0}>Guardar</button>
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setAddFuelModal(false)}>Descartar</button>
                </div>
            </Modal>
            <Modal
                isOpen={disputeModal}
                onRequestClose={() => setDisputeModal(false)}
                contentLabel="Dispute Modal"
                style={{content : {
                    top                   : '50%',
                    left                  : '50%',
                    right                 : 'auto',
                    bottom                : 'auto',
                    marginRight           : '-50%',
                    padding               : '0',
                    transform             : 'translate(-50%, -50%)'
                  }}}
                >
                <div className="modal-header">
                    <h5 className="modal-title">Abrir Ticket</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setDisputeModal(false)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className='form-group'>
                        <label htmlFor="reason">Motivo *</label>
                        <input type="text" className="form-control" id="reason" placeholder="Reason" value={disputeReason} onChange={(event) => setDisputeReason(event.target.value)} />
                    </div>
                    <div className='form-group'>
                        <label htmlFor="description">Descripción *</label>
                        <textarea type="text" className="form-control" id="description" placeholder="Description" value={disputeDescription} onChange={(event) => setDisputeDescription(event.target.value)} />
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-danger" onClick={saveDispute} disabled={disputeReason === '' || disputeDescription === ''}>Abrir Ticket</button>
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setDisputeModal(false)}>Descartar</button>
                </div>
            </Modal>
        </section>
        ) : null
    );
};

const mapStateToProps = (state) => {
    return ({timeZone: state.auth.user.timezone, locale: state.auth.user.locale});
};

const mapDispatchToProps = (dispatch) => ({
    show: () => dispatch({ type: 'LOADING' }),
    hide: () => dispatch({ type: 'LOADED' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(DepartureDetails);
